export default {
  wrapper: {
    margin: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& .MuiCircularProgress-colorPrimary": {
      color: "#2489F5"
    }
  }
};
