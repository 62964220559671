const screenType = {
  DEMO: "DEMO",
  MAIN: "MAIN"
};

const ERROR_TYPES = {
  structure: {
    id: "structure",
    generalColor: "#34C759",
    // color: "#ddf3e0"
    // color: "#34c7594d",
    color: "#d6f4dd"
  },
  flow: {
    id: "flow",
    generalColor: "#FF9500",
    // color: "#fcebd0"
    // color: "#ff95004d"
    color: "#ffeacc"
  },
  syntax: {
    id: "syntax",
    generalColor: "#AF52DE",
    // color: "#eddef6"
    color: "#efdcf8"
  },
  grammar: {
    id: "grammar",
    generalColor: "#5AC8FA",
    // color: "#e3f3fd"
    color: "#def4fe"
  }
};

const LOADING_STATUSES = {
  DONE: "DONE",
  LOADING: "LOADING",
  WAITING: "WAITING"
};

const STATE_TABS = {
  UPLOAD_MODE: "UPLOAD_MODE",
  SUCCESSFUL_MODE: "SUCCESSFUL_MODE"
};

const availableData = {
  type: "flow",
  errorId: "percentage_of_simple_0"
};

export { screenType, ERROR_TYPES, LOADING_STATUSES, STATE_TABS, availableData };
