export default {
  wrapper: {
    marginBottom: 10,
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    color: "#333333",
    whiteSpace: "nowrap"
  }
};
