import React from "react";
import injectStyles from "react-jss";

import styles from "./styles";
import CopyIcon from "../../assets/icons/copy";

const CopyButton = ({ onClick, classes, ...props }) => {
  return (
    <button className={classes.basic} onClick={onClick} {...props}>
      <CopyIcon className={classes.icon} />
      Copy
    </button>
  );
};

export default injectStyles(styles)(CopyButton);
