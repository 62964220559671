import React, { Fragment, useState } from 'react'
import injectStyles from 'react-jss'
import { styles } from './styles'
import StickyHeadTable from '../../LendosComponents/Table'
import classNames from 'classnames'
import APIClient from '../../modules/API/Client'
import Loader from '../../components/Loader/Loader'
import Button from '../../LendosComponents/Button'
import ModalCancelSubscribtion from '../../components/ModalCancelSubscribtion/ModalCancelSubscribtion'
import { dateFormat } from '../../utils'

const columns = [
  { id: 'Date', label: 'Date' },
  { id: 'Time', label: 'Time' },
  { id: 'Amount', label: 'Amount' },
  {
    id: 'card',
    label: 'Method',
    align: 'right',
  },
]

const SubscribeTab = ({ classes, data, isLoading }) => {
  const [isOpenModal, setIsOpenModal] = useState(false)

  const handleSubscribe = async () => {
    await APIClient.getSubscribe()
  }

  const { is_promocode, promocode_end } = data

  return (
    <div className={classes.content}>
      {isLoading ? (
        <Loader />
      ) : (
        <Fragment>
          <ModalCancelSubscribtion
            isOpenModal={isOpenModal}
            onCloseModal={() => setIsOpenModal(false)}
          />
          {is_promocode ? (
            <div>Your promo code access ends on {promocode_end}</div>
          ) : (
            <Fragment>
              <div className={classes.simpleText}>
                {data.active_subscribe
                  ? `Your membership is active`
                  : 'One-time fee of $9.99'}
              </div>
              <div className={classes.buttonWrapper}>
                {data.active_subscribe ? (
                  /*data.next_pay ? (
                    <Button
                      onClick={() => setIsOpenModal(true)}
                      color="blueOutlined"
                      filled={true}
                      label="Cancel my subscription"
                    />
                  ) : null */
                  data.next_pay ? null : null
                ) : (
                  <Button
                    onClick={handleSubscribe}
                    color="blueOutlined"
                    filled={true}
                    label="Membership"
                  />
                )}
              </div>
            </Fragment>
          )}
          <div className={classes.subtitle}>Payment history</div>
          <StickyHeadTable columns={columns} rows={data.history} />
        </Fragment>
      )}
    </div>
  )
}

export default injectStyles(styles)(SubscribeTab)

// payment data
/* <div className={classes.subtitle}>Payment data</div>
          <div className={classes.wrapperPayment}>
            <div className={classNames(classes.simpleText)}>
              {data.pay_data ? data.pay_data.card : null}
            </div>
            <div className={classNames(classes.datePaid, classes.simpleText)}>
              {data.pay_data ? data.pay_data.expired : null}
            </div>
          </div> 
          */