import React from 'react';

const ArrowColored = props => (

    <svg {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.97585 3.39599L13.5278 6.7405C13.6993 6.80062 13.8188 6.95669 13.8317 7.13794C13.8447 7.31919 13.7487 7.49078 13.5876 7.57466L9.62452 9.63176L7.63987 13.602H7.63974C7.56272 13.7563 7.40499 13.8538 7.23251 13.8539H7.20467H7.2048C7.02267 13.8428 6.86482 13.7241 6.80342 13.5523L3.39416 3.97947L3.39429 3.9796C3.33544 3.81424 3.37675 3.62982 3.50054 3.5054C3.62434 3.3811 3.8085 3.3389 3.97412 3.39699L3.97585 3.39599Z"
            fill="#0C84BB"/>
        <path
            d="M3.82512 0.188141C4.07665 0.188141 4.28064 0.392134 4.28064 0.643663V2.01023C4.28064 2.26176 4.07665 2.46575 3.82512 2.46575C3.57359 2.46575 3.36959 2.26176 3.36959 2.01023V0.643663C3.36959 0.522794 3.41764 0.407006 3.50305 0.321596C3.58846 0.236185 3.70425 0.188141 3.82512 0.188141Z"
            fill="#0C84BB"/>
        <path
            d="M2.45862 2.92123C2.33787 2.92123 2.22196 2.87318 2.13655 2.78777L1.17035 1.82158C1.08253 1.73667 1.03245 1.62012 1.03131 1.49786C1.03029 1.37571 1.07833 1.25827 1.16476 1.17183C1.25119 1.08539 1.36863 1.03736 1.49079 1.03838C1.61306 1.03952 1.7296 1.0896 1.81451 1.17742L2.7807 2.14362C2.91098 2.27389 2.94987 2.46974 2.87933 2.63994C2.80892 2.81013 2.64279 2.92123 2.45862 2.92123Z"
            fill="#0C84BB"/>
        <path
            d="M2.19176 4.82174C2.27666 4.73391 2.39321 4.68384 2.51548 4.68269C2.63762 4.68168 2.75506 4.72972 2.8415 4.81615C2.92795 4.90258 2.97597 5.02001 2.97496 5.14217C2.97382 5.26444 2.92374 5.38099 2.83592 5.46589L1.86972 6.43209C1.69102 6.60469 1.40681 6.60228 1.23118 6.4265C1.0554 6.25085 1.05298 5.96665 1.22558 5.78795L2.19176 4.82174Z"
            fill="#0C84BB"/>
        <path
            d="M5.45847 2.84289C5.37356 2.93071 5.25701 2.98079 5.13474 2.98193C5.0126 2.98295 4.89516 2.9349 4.80872 2.84848C4.72228 2.76205 4.67425 2.64461 4.67527 2.52245C4.67641 2.40018 4.72649 2.28363 4.81431 2.19873L5.78051 1.23254C5.86541 1.14471 5.98208 1.09451 6.10423 1.09349C6.22637 1.09247 6.34394 1.14052 6.43025 1.22695C6.51668 1.31324 6.56472 1.43081 6.5637 1.55297C6.56269 1.67511 6.51248 1.79179 6.42466 1.87669L5.45847 2.84289Z"
            fill="#0C84BB"/>
        <path
            d="M0.636528 3.37675H2.0031C2.25463 3.37675 2.45862 3.58075 2.45862 3.83228C2.45862 4.08381 2.25463 4.2878 2.0031 4.2878H0.636528C0.384999 4.2878 0.181006 4.08381 0.181006 3.83228C0.181006 3.58075 0.384999 3.37675 0.636528 3.37675Z"
            fill="#0C84BB"/>
    </svg>
);

export default ArrowColored;

