export default {
  wrapper: {
    margin: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 14,
    color: "#B9BED1",
    whiteSpace: "nowrap"
  }
};
