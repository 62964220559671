import React from "react";

const CheckIcon = props => {
  return (
    <svg
      {...props}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3.79541C2.34315 3.79541 1 5.1404 1 6.79953V18.816C1 20.4751 2.34315 21.8201 4 21.8201H20C21.6569 21.8201 23 20.4751 23 18.816V6.79953C23 5.1404 21.6569 3.79541 20 3.79541H4ZM6.64021 8.03303C6.21593 7.67898 5.58537 7.73638 5.2318 8.16124C4.87824 8.5861 4.93556 9.21753 5.35984 9.57158L10.0795 13.51C11.192 14.4384 12.808 14.4384 13.9206 13.51L18.6402 9.57158C19.0645 9.21753 19.1218 8.5861 18.7682 8.16124C18.4147 7.73638 17.7841 7.67898 17.3598 8.03303L12.6402 11.9715C12.2694 12.2809 11.7307 12.2809 11.3598 11.9715L6.64021 8.03303Z"
        fill="#2489F5"
      />
    </svg>
  );
};

export default CheckIcon;
