export default {
  wrapper: {
    // padding: "3px 28px 3px",
    fontSize: 14,
    color: "#80869D",
    background: "#FFFFFF",
    width: "100%",
    border: "none",
    borderRadius: 7,
    textAlign: "center",
    boxShadow:
      "0px 0px 4px rgba(117, 131, 142, 0.04), 0px 3px 5px rgba(52, 60, 68, 0.08)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:first-child": {
      marginRight: 10
    }
  },
  isActive: {
    background: "#F2F5F9",
    color: "#333333",
    border: "1px solid #2489F5"
  },
  disabled: {
    background: "#F8F9FA",
    color: "#80869D",
    border: "1px solid #CAD2DB",
    boxShadow: "none"
  },
  "@media (max-width: 800px)": {
    wrapper: {
      fontSize: 12
    }
  }
};
