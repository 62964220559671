import React from "react";
import injectStyles from "react-jss";

import styles from "./styles";

const TextCount = ({ words, classes, characters }) => {
  return (
    <div className={classes.wrapper}>
      {words} words / {characters} characters
    </div>
  );
};

export default injectStyles(styles)(TextCount);
