import React from "react";

const LikeIcon = props => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 9.99902C20 8.31654 18.8696 6.99902 17 6.99902H13.927L13.9548 6.9233C13.9826 6.84813 14.0609 6.64165 14.137 6.44114L14.1372 6.44068C14.2044 6.26339 14.2699 6.09085 14.2971 6.01793C14.4872 5.50758 14.626 5.09638 14.7382 4.68915C14.9079 4.07366 15 3.51914 15 2.99902C15 1.52702 14.0919 0.612742 12.7483 0.22151C12.1423 0.0450519 11.6148 -0.00106806 11 -0.000976562H10.5205L10.2203 0.372908C9.88113 0.795256 9.21501 1.55643 8.44705 2.43398L8.44701 2.43403C7.03026 4.05294 5.2669 6.06793 4.57035 7.08242C4.38961 7.02874 4.19817 6.99992 4 6.99992H2C0.89543 6.99992 0 7.89535 0 8.99992V17.9999C0 19.1045 0.89543 19.9999 2 19.9999H4C4.68712 19.9999 5.29331 19.6534 5.65337 19.1256C5.95674 19.3218 6.29522 19.4856 6.66312 19.6172C7.50544 19.9186 8.34085 20.0111 9.0344 19.9984L16 19.999C18.7551 19.999 20 15.9426 20 9.99902ZM7.33688 17.7341C6.45197 17.4175 6 16.9013 6 15.999V8.99992V8.99902C6 8.75883 6.04443 8.54613 6.14171 8.31901C6.31645 7.91102 7.90193 6.10216 9.40467 4.3877L9.40467 4.3877C10.1802 3.50285 10.9338 2.64315 11.46 2.01415C11.7114 2.03301 11.9484 2.07165 12.1891 2.14176C12.7622 2.30862 13 2.54805 13 2.99902C13 3.31561 12.9359 3.70134 12.8101 4.15763C12.7161 4.4986 12.5944 4.85939 12.4229 5.31969C12.3986 5.38502 12.3378 5.54512 12.2733 5.71534L12.2731 5.7158C12.1944 5.92306 12.1102 6.14522 12.079 6.22957C11.9489 6.58119 11.8633 6.84009 11.8047 7.07534C11.5555 8.07538 11.8055 8.99902 13 8.99902H17C17.6912 8.99902 18 9.35891 18 9.99902C18 14.7898 17.0151 17.999 16 17.999H9L8.96307 17.9996C8.52538 18.0075 7.91062 17.9394 7.33688 17.7341ZM2 17.9999V8.99992H4V17.9999H2Z"
      fill="#CAD2DB"
    />
  </svg>
);

export default LikeIcon;
