import React from 'react'
import injectStyles from 'react-jss'
import {styles} from './styles'
import ReactPlayer from 'react-player'

import BlockWithTitle from '../BlockWithTitle'
import SimpleBlock from '../SimpleBlock'
import graphIcon from '../../Images/graph.png'
import Bg2Icon from '../../Images/bg-2'
import Bg3Icon from '../../Images/bg-3'
import TabsIcon from '../../Images/tabsIcon'
import punkt8Icon from '../../Images/punkt8.png'
import Bg4Icon from '../../Images/bg-4'
import EditModeIcon from '../../Images/editModeIcon'
import Bg5Icon from '../../Images/bg-5'
import SummaryIcon from '../../Images/summaryIcon'
import Bg6Icon from '../../Images/bg-6'
import tabsIcon from '../../Images/tabs-icon.png'
import Bg7Icon from '../../Images/bg-7'
import ChipBlock from '../ChipBlock'
import SubscribeBlock from '../SubscribeBlock'
import AboutUsBlock from '../AboutUsBlock'
import VideoBlock from "../VideoBlock";

const GeneralBlock = ({
                          classes,
                          isOpenMobileAccordion,
                          refFeatures,
                          refPricing,
                          refAbout,
                      }) => {
    return (
        <div
            className={classes.wrapper}
            style={isOpenMobileAccordion ? {background: 'rgba(0,0,0,0.5'} : {}}
        >
            <BlockWithTitle/>
            <VideoBlock
                title="An essay score out of a 100 and a corresponding percentile calculated in seconds, comparing your essay to others"
                text="Scans essay draft in under 5 seconds and calculates a score and a percentile to tell you how you are doing, how your current draft compares to others, and how far away are you from producing an exceptional application essay"

                // bgIcon={<Bg2Icon className={classes.bg1} />}
            />
            <SimpleBlock
                title="Multiple writing dimensions assessed, including essay structure, flow, syntax, and grammar"
                text="Takes a funnel approach and first assesses what you write and then how well you write it. Trained to catch topic jumping, conflicting plots, “show, don’t tell” opportunities, inefficient sentence structures, poor word choice, etc."
                icon={<TabsIcon/>}
                bgIcon={<Bg3Icon className={classes.bg2}/>}
                reverse={true}
            />
            <SimpleBlock
                title="Instant feedback with actionable recommendations, a detailed rationale explanation, and illustrative good & bad examples"
                text="Tells you not just what to change in your essay but also why to change it and illustrates with good and bad examples. Offers essay coaching to help you become a better writer and understand what it takes to write a winning application essay"
                icon={<img src={punkt8Icon} alt="img" className={classes.img3}/>}
                bgIcon={<Bg4Icon className={classes.bg3}/>}
            />
            <SimpleBlock
                title="Dynamic feedback tailored to where your biggest gaps are and prioritizing the essay aspects you need to tackle next"
                text="Adjusts feedback to reflect essay maturity and focus your attention on fixing the errors most critical for where you are in your essay writing lifecycle. Guides you to work on big ticket items before spending calories of polishing and cosmetics"
                icon={<img className={classes.img1} src={tabsIcon} alt="img"/>}
                bgIcon={<Bg7Icon className={classes.bg6}/>}
                reverse={true}
            />
            <ChipBlock refFeatures={refFeatures}/>
            <SimpleBlock
                title="Live editing and real-time refresh functionality to iterate and improve rapidly"
                text="Enables to make live edits and re-run the tool to see how your score and percentile rank change. Gives you a sense if you’re on the right track to improving your overall application essay quality"
                icon={<EditModeIcon/>}
                bgIcon={<Bg5Icon className={classes.bg4}/>}
                reverse={true}
            />
            <SimpleBlock
                title="Feedback synthesized into a succinct summary report"
                text="Distills your application essay down to key strengths and weaknesses. Helps understand what’s working well and where are the key areas to focus more"
                icon={<SummaryIcon/>}
                bgIcon={<Bg6Icon className={classes.bg5}/>}
            />
            <SubscribeBlock refPricing={refPricing}/>
            <AboutUsBlock refAbout={refAbout}/>
        </div>
    )
}

export default injectStyles(styles)(GeneralBlock)
