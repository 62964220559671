import React, { useState } from 'react'
import injectStyles from 'react-jss'
import { Fade, Paper, Popper } from '@material-ui/core'
import UploadThermometer from './UploadThermometer'
import SuccessfulThermometer from './SuccessfulThermometer'
import { STATE_TABS } from '../../../constants'

import styles from '../styles'

const Thermometer = ({
  classes,
  progress,
  hoveredTab,
  stateTab,
  tabs,
  isEssay,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)

  const handleHover = event => {
    if (event) {
      setAnchorEl(event.target.parentNode)
      setOpen(true)
    } else {
      setAnchorEl(null)
      setOpen(false)
    }
  }

  return (
    <div className={classes.wrapper}>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        transition
        disablePortal={true}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} tismeout={350}>
            <Paper>
              <div className={classes.popover}>
                Your current draft in the essay lifecycle
              </div>
            </Paper>
          </Fade>
        )}
      </Popper>
      {stateTab === STATE_TABS.UPLOAD_MODE ? (
        <UploadThermometer progress={progress} classes={classes} />
      ) : (
        <SuccessfulThermometer
          handleHover={handleHover}
          hoveredTab={hoveredTab}
          classes={classes}
          tabs={tabs}
          isEssay={isEssay}
        />
      )}
    </div>
  )
}

export default injectStyles(styles)(Thermometer)
