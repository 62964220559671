import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import CancelIcon from '../../assets/icons/Cancel'
import Shared from '../Shared/Shared'
import GenericButton from '../GenericButton/GenericButton'
import { downloadEssay } from '../../utils'
import OverallScorePercentile from '../OverallScorePercentile/component/OverallScorePercentile'
import mockSummary from '../../Images/mockSummary.png'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#FFFFFF',
    boxShadow: theme.shadows[5],
    padding: '16px 5px 25px 30px',
    height: '100%',
    maxHeight: 520,
    width: '100%',
    maxWidth: 490,
    outline: 'none',
    borderRadius: 15,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 24,
    fontWeight: 700,
    color: '#333333',
    margin: '10px 0',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon: {
    paddingRight: 10,
    '& svg': {
      cursor: 'pointer',
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 30,
  },
  buttonBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  shared: {
    marginLeft: 10,
  },
  overallScore: {
    paddingRight: 25,
  },
  overallScoreHidden: {
    paddingRight: 25,
    opacity: 0,
  },
  content: {
    overflowY: 'scroll',
    paddingRight: 30,
  },
}))

const ModalSummary = ({
  isOpenModal,
  onCloseModal,
  summary,
  overall,
  hashedName,
  isDemo = false,
  percentile,
  isEssay,
  summaryImgLink,
}) => {
  const classes = useStyles()
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpenModal}
      // onClose={handleClose}
      closeAfterTransitions
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpenModal}>
        <div className={classes.paper}>
          <div className={classes.header}>
            <div className={classes.title}>
              {isEssay
                ? 'Feedback summary'
                : 'Oops seems like this is not an essay…'}
            </div>
            <div className={classes.icon}>
              <CancelIcon onClick={onCloseModal} />
            </div>
          </div>
          <div className={classes.content}>
            {summaryImgLink ? (
              <>
                <img
                  src={summaryImgLink}
                  alt="img"
                  style={{ width: '100%', marginBottom: 10 }}
                />{' '}
                <br />
              </>
            ) : isDemo ? (
              <>
                <img
                  src={mockSummary}
                  alt="img"
                  style={{ width: '100%', marginBottom: 10 }}
                />{' '}
                <br />
              </>
            ) : null}
            {summary && summary.length
              ? summary.map(item =>
                  item ? (
                    <>
                      <div>{item}</div>
                    </>
                  ) : (
                    <br />
                  ),
                )
              : 'Summary is missing'}
          </div>
          <div className={classes.footer}>
            <div className={classes.buttonBlock}>
              <GenericButton
                label="Review essay"
                onClick={onCloseModal}
                disabled={false}
                type="filled"
              />
              {!isDemo ? (
                <Shared
                  onClick={() => downloadEssay(hashedName)}
                  className={classes.shared}
                />
              ) : null}
            </div>
            <OverallScorePercentile
              className={
                isEssay ? classes.overallScore : classes.overallScoreHidden
              }
              score={overall || 0}
              percentile={percentile}
            />
          </div>
        </div>
      </Fade>
    </Modal>
  )
}

export default ModalSummary
