import React from 'react'
import injectStyles from 'react-jss'
import styles from '../styles'
import ButtonTab from '../../ButtonTab/component/ButtonTab'
import { STATE_TABS } from '../../../constants'
import classNames from 'classnames'

const ButtonsAboveTab = ({
  setAllCategory,
  isAllCategory,
  handleChangeWorkOnNext,
  workOnNext,
  stateTab,
  classes,
  isPaid,
  setIsOpenModalSummary,
}) => {
  return (
    <div className={classes.wrapper}>
      <ButtonTab
        className={classNames(classes.button, classes.bigButton)}
        title="See feedback summary"
        onClick={() => setIsOpenModalSummary(true)}
        isActive={false}
        disabled={stateTab === STATE_TABS.UPLOAD_MODE}
      />
      <div className={classes.wrapperButton}>
        <ButtonTab
          className={classes.button}
          title="See all suggestions"
          onClick={() => {
            setAllCategory(true)
          }}
          isActive={isAllCategory}
          disabled={stateTab === STATE_TABS.UPLOAD_MODE}
        />
        <ButtonTab
          title="Need to work on next"
          onClick={handleChangeWorkOnNext}
          isActive={workOnNext}
          disabled={!isPaid || stateTab === STATE_TABS.UPLOAD_MODE}
        />
      </div>
    </div>
  )
}
export default injectStyles(styles)(ButtonsAboveTab)
