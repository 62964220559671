import React from "react";

import { Box, Divider, Popover, Stack, Typography } from "@mui/material";

const SelectAlternativesMenu = ({ children, anchorEl, setAnchorEl }) => {

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    return (
        <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: '8px',
                    boxShadow: '0px 4px 8px rgba(117, 131, 142, 0.15), 3px 3px 20px rgba(52, 60, 68, 0.2)'
                },
            }}
        >

            <Stack divider={<Divider sx={{ margin: '12px 0' }}/>} sx={{ padding: '16px' }}>
                <Typography variant={'subtitle1'} sx={{ fontWeight: 500 }}>
                    Replace the word with synonyms
                </Typography>
                <Box>
                    <Typography variant={'subtitle2'} sx={{ color: '#80869D', fontWeight: 400, mb: '4px' }}>Recommended:</Typography>
                    {children[0]}
                </Box>
                <Box sx={{ display: 'flex', gap: '8px' }}>
                    {children.slice(1)}
                </Box>

            </Stack>
        </Popover>
    );
};

export default SelectAlternativesMenu;
