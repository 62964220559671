import React, { useContext } from 'react'
import injectStyles from 'react-jss'
import { styles } from './styles'
// import ChipIcon from "../../Images/chipIcon";
import Button from '../Button'
import PopupsContext from '../../modules/contexts/popupsContext'
// import PeopleIcon from '../../Images/peopleIcon'
// import PeopleIconMobil from '../../Images/peopleIconMobile'

const SubscribeBlock = ({ classes, refPricing }) => {
  const { showSignUp } = useContext(PopupsContext)

  const handleClick = () => {
    showSignUp(true)
  }
  return (
    <div ref={refPricing} className={classes.wrapper}>
      <div className={classes.title}>
        <span className={classes.blue}>Subscribe</span> and iterate as much as
        necessary for a one-time payment of $9.99.
        <br className={classes.br} />
        <br className={classes.br} /> If you are an institution interested in
        group membership, please reach out to us at
        <span className={classes.blue}> info@chckr.io</span> for custom pricing
      </div>
      {/* <div className={classes.text}> */}
      {/*   Get an AI pair of eyes on your application essays and receive immediate */}
      {/*   feedback. Improve the quality of your writing to tell a well-structured */}
      {/*   and insightful narrative to admissions */}
      {/* </div> */}
      <div className={classes.buttonsWrap}>
        <Button
          color="blueOutlined"
          label="Subscribe"
          margin={10}
          onClick={handleClick}
        />
        {/*<Button color="blue" label="Invite" />*/}
      </div>
      {/*<PeopleIcon className={classes.icon} />*/}
      {/*<PeopleIconMobil className={classes.iconMobil} />*/}
      {/*<div className={classes.textMobil}>*/}
      {/*  The number of students who thanked us for helping them successfully go*/}
      {/*  to a higher education institution*/}
      {/*</div>*/}
    </div>
  )
}

export default injectStyles(styles)(SubscribeBlock)
