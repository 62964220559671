import React from "react";
import EditorContainerView from "../component/EditorContainerView";
import EditorResult from "../../EditorResult/container/EditorResult";
import EditorForm from "../../EditorForm/container/EditorForm";

const EditorContainer = ({
							 activeCategory,
							 receivedResult,
							 activeErrorData,
							 setActiveErrorData,
							 onSubmit,
							 loading,
							 rawInput,
							 setRawInput,
							 updateRawInput,
							 header,
							 editButton,
							 isLoaded,
							 isAllCategory,
							 hoveredTab,
							 usefulHires,
							 hoveredCategoryId,
							 onHoverCategory
						 }) => {
	return (
		<EditorContainerView header={ header }>
			{ !loading && receivedResult.length > 0 ? (
				<EditorResult
					loading={ loading }
					receivedResult={ receivedResult }
					activeErrorData={ activeErrorData }
					setActiveErrorData={ setActiveErrorData }
					activeCategory={ activeCategory }
					editButton={ editButton }
					isAllCategory={ isAllCategory }
					hoveredTab={ hoveredTab }
					usefulHires={ usefulHires }
					hoveredCategoryId={ hoveredCategoryId }
					onHoverCategory={ onHoverCategory }
					updateRawInput={ updateRawInput }
				/>
			) : (
				<EditorForm
					loading={ loading }
					value={ rawInput }
					onChange={ setRawInput }
					onSubmit={ onSubmit }
					isLoaded={ isLoaded }
				/>
			) }
		</EditorContainerView>
	);
};

export default EditorContainer;
