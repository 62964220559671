import React, {useState} from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import injectStyles from 'react-jss'
import {PATHS} from './paths'
import history from './history'
import AppMain from '../scenes/AppMain/container/AppMain'
import Demo from '../scenes/Demo/container/Demo'
import Lending from '../scenes/Lending'
import UserCabinet from '../scenes/UserCabinet'
import {PrivateRoute} from './routes'
import APIClient from '../modules/API/Client'

const styles = {
    wrapper: {},
}

const MainRouter = ({classes}) => {
    const [dataSingleEssay, setDataSingleEssay] = useState(null)
    const onClickSingleEssay = async hashedName => {
        const result = await APIClient.getSingleEssay(hashedName)
        setDataSingleEssay(result)
    }

    const onClickNewEssay = () => {
        setDataSingleEssay(undefined)
    }

    return (
        <div className={classes.wrapper}>
            <BrowserRouter history={history}>
                <Routes>
                    <Route path={PATHS.demo} element={<Demo/>}/>
                    <Route
                        path={PATHS.checking}
                        element={
                            <AppMain
                                exact={true}
                                dataSingleEssay={dataSingleEssay}
                                setDataSingleEssay={setDataSingleEssay}
                                onClickSingleEssay={onClickSingleEssay}
                            />
                        }
                    />
                    <Route
                        path={PATHS.profile}
                        element={
                            <PrivateRoute>
                                <UserCabinet
                                onClickSingleEssay={onClickSingleEssay}
                                onClickNewEssay={onClickNewEssay}
                            />
                            </PrivateRoute>

                        }
                    />
                    <Route path={PATHS.home} element={<Lending/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    )
}
//
export default injectStyles(styles)(MainRouter)
