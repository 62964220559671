import React from "react";

const ChipIcon = props => (
  <svg
    {...props}
    width="185"
    height="191"
    viewBox="0 0 185 191"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_57:2879)">
      <path
        d="M104.988 62.0733C104.506 62.0733 104.127 61.6937 104.127 61.2105V23.9355C104.127 23.6594 104.248 23.4178 104.471 23.2452L129.173 4.29705C129.552 4.00368 130.086 4.07271 130.378 4.45236C130.671 4.83201 130.602 5.36698 130.223 5.66035L105.849 24.3669V61.2105C105.849 61.6937 105.471 62.0733 104.988 62.0733Z"
        fill="#403D57"
      />
      <path
        d="M85.7133 52.444C85.231 52.444 84.8521 52.0643 84.8521 51.5811V31.2007C84.8521 30.7175 85.231 30.3378 85.7133 30.3378C86.1956 30.3378 86.5746 30.7175 86.5746 31.2007V51.5811C86.5746 52.0643 86.1956 52.444 85.7133 52.444Z"
        fill="#403D57"
      />
      <path
        d="M47.5766 60.0542C47.0943 60.0542 46.7153 59.6745 46.7153 59.1913V16.3596C46.7153 15.8764 47.0943 15.4967 47.5766 15.4967C48.0589 15.4967 48.4379 15.8764 48.4379 16.3596V59.1913C48.4379 59.6573 48.0589 60.0542 47.5766 60.0542Z"
        fill="#403D57"
      />
      <path
        d="M55.4484 79.1403C55.2072 79.1403 54.9488 79.0368 54.7766 78.8297L28.6802 46.9733C28.5596 46.818 28.4907 46.6282 28.4907 46.4211V14.4612C28.4907 13.978 28.8697 13.5984 29.352 13.5984C29.8343 13.5984 30.2133 13.978 30.2133 14.4612V46.1277L56.1202 77.7252C56.4302 78.0876 56.3613 78.6398 55.9996 78.9332C55.8445 79.0713 55.6378 79.1403 55.4484 79.1403Z"
        fill="#403D57"
      />
      <path
        d="M108.554 77.967C108.313 77.967 108.072 77.8634 107.899 77.6736C107.589 77.3112 107.624 76.7763 107.985 76.4484L143.211 45.662L147.276 13.4432C147.345 12.9773 147.776 12.6494 148.241 12.7012C148.706 12.7529 149.051 13.2016 148.982 13.6676L144.865 46.2142C144.83 46.4213 144.727 46.6111 144.572 46.7492L109.088 77.7426C108.95 77.898 108.761 77.967 108.554 77.967Z"
        fill="#403D57"
      />
      <path
        d="M145.365 66.4911H120.302C119.819 66.4911 119.44 66.1115 119.44 65.6283C119.44 65.1451 119.819 64.7654 120.302 64.7654H145.003L156.027 53.1687C156.354 52.8236 156.905 52.8063 157.25 53.1342C157.595 53.4621 157.612 54.0143 157.284 54.3595L146.002 66.2323C145.83 66.3876 145.606 66.4911 145.365 66.4911Z"
        fill="#403D57"
      />
      <path
        d="M120.302 83.3684C119.837 83.3684 119.44 82.9888 119.44 82.5056C119.44 82.0224 119.819 81.6427 120.302 81.6427L165.484 81.2113C165.983 81.2285 166.345 81.5909 166.345 82.0741C166.345 82.5573 165.966 82.937 165.484 82.937L120.302 83.3684Z"
        fill="#403D57"
      />
      <path
        d="M178.73 107.839C178.575 107.839 178.42 107.804 178.265 107.701L152.237 91.3409H115.031C114.548 91.3409 114.169 90.9613 114.169 90.4781C114.169 89.9949 114.548 89.6152 115.031 89.6152H152.479C152.634 89.6152 152.806 89.667 152.944 89.7533L179.178 106.251C179.574 106.51 179.695 107.045 179.453 107.442C179.298 107.701 179.023 107.839 178.73 107.839Z"
        fill="#403D57"
      />
      <path
        d="M157.767 116.105H122.627C122.145 116.105 121.766 115.725 121.766 115.242C121.766 114.759 122.145 114.379 122.627 114.379H157.767C158.249 114.379 158.628 114.759 158.628 115.242C158.628 115.725 158.249 116.105 157.767 116.105Z"
        fill="#403D57"
      />
      <path
        d="M181.417 112.188C180.797 112.188 180.194 112.032 179.643 111.704C178.816 111.221 178.23 110.462 177.989 109.53C177.748 108.615 177.869 107.649 178.351 106.821C178.833 105.992 179.591 105.406 180.521 105.164C181.434 104.922 182.399 105.043 183.226 105.526C184.053 106.01 184.638 106.769 184.879 107.701C185.12 108.615 185 109.582 184.518 110.41C184.035 111.238 183.277 111.825 182.347 112.067C182.054 112.136 181.727 112.188 181.417 112.188ZM181.434 106.769C181.279 106.769 181.107 106.786 180.952 106.838C180.47 106.959 180.073 107.269 179.832 107.701C179.591 108.132 179.522 108.633 179.643 109.099C179.763 109.582 180.073 109.979 180.504 110.22C180.935 110.462 181.434 110.531 181.899 110.41C182.382 110.289 182.778 109.979 183.019 109.547C183.26 109.116 183.329 108.615 183.208 108.149C183.088 107.666 182.778 107.269 182.347 107.028C182.072 106.838 181.762 106.769 181.434 106.769Z"
        fill="#403D57"
      />
      <path
        d="M137.492 188.291C136.89 188.291 136.269 188.135 135.718 187.807C134.013 186.824 133.427 184.632 134.409 182.924C135.391 181.215 137.579 180.629 139.284 181.612C140.989 182.596 141.575 184.787 140.593 186.496C139.921 187.652 138.715 188.291 137.492 188.291ZM137.492 182.872C136.855 182.872 136.235 183.2 135.89 183.787C135.391 184.667 135.684 185.806 136.562 186.306C137.441 186.824 138.578 186.513 139.077 185.633C139.577 184.753 139.284 183.614 138.405 183.114C138.13 182.958 137.802 182.872 137.492 182.872Z"
        fill="#403D57"
      />
      <path
        d="M150.274 168.135C149.653 168.135 149.051 167.979 148.499 167.651C147.672 167.168 147.087 166.409 146.846 165.477C146.605 164.562 146.725 163.596 147.207 162.768C147.69 161.939 148.448 161.353 149.378 161.111C150.291 160.869 151.255 160.99 152.082 161.473C152.909 161.957 153.495 162.716 153.736 163.648C153.977 164.562 153.856 165.529 153.374 166.357C152.892 167.185 152.134 167.772 151.204 168.014C150.894 168.1 150.584 168.135 150.274 168.135ZM150.274 162.716C150.118 162.716 149.946 162.733 149.791 162.785C149.309 162.906 148.913 163.216 148.672 163.648C148.43 164.079 148.361 164.58 148.482 165.046C148.603 165.529 148.913 165.926 149.343 166.167C149.774 166.409 150.274 166.478 150.739 166.357C151.221 166.236 151.617 165.926 151.858 165.494C152.099 165.063 152.168 164.562 152.048 164.096C151.927 163.613 151.617 163.216 151.186 162.975C150.911 162.802 150.601 162.716 150.274 162.716Z"
        fill="#403D57"
      />
      <path
        d="M100.854 191C100.251 191 99.6313 190.845 99.0801 190.517C97.3748 189.533 96.7891 187.342 97.7709 185.633C98.7528 183.925 100.94 183.338 102.646 184.322C104.351 185.305 104.937 187.497 103.955 189.205C103.283 190.362 102.077 191 100.854 191ZM100.854 185.581C100.217 185.581 99.5968 185.909 99.2523 186.496C98.7528 187.376 99.0456 188.515 99.9241 189.016C100.803 189.533 101.939 189.223 102.439 188.342C102.939 187.462 102.646 186.323 101.767 185.823C101.492 185.668 101.164 185.581 100.854 185.581Z"
        fill="#403D57"
      />
      <path
        d="M111.172 183.131C110.57 183.131 109.949 182.975 109.398 182.648C108.571 182.164 107.986 181.405 107.745 180.473C107.503 179.559 107.624 178.592 108.106 177.764C109.088 176.055 111.276 175.469 112.981 176.452C114.686 177.436 115.272 179.628 114.29 181.336C113.601 182.475 112.413 183.131 111.172 183.131ZM111.19 177.712C110.552 177.712 109.932 178.04 109.588 178.627C109.347 179.058 109.278 179.559 109.398 180.025C109.519 180.508 109.829 180.905 110.26 181.146C111.138 181.664 112.275 181.353 112.774 180.473C113.274 179.593 112.981 178.454 112.103 177.954C111.81 177.781 111.5 177.712 111.19 177.712Z"
        fill="#403D57"
      />
      <path
        d="M30.1961 187.876C29.576 187.876 28.9731 187.721 28.4219 187.393C26.7166 186.41 26.1309 184.218 27.1127 182.51C28.0946 180.801 30.2822 180.214 31.9875 181.198C33.6928 182.182 34.2785 184.373 33.2966 186.082C32.8143 186.91 32.0564 187.497 31.1262 187.738C30.8162 187.842 30.5061 187.876 30.1961 187.876ZM30.2133 182.458C29.576 182.458 28.9559 182.786 28.6113 183.372C28.1118 184.253 28.4046 185.391 29.2831 185.892C29.7138 186.134 30.2133 186.203 30.6784 186.082C31.1607 185.961 31.5569 185.65 31.798 185.219C32.0392 184.787 32.1081 184.287 31.9875 183.821C31.8669 183.338 31.5569 182.941 31.1262 182.699C30.8334 182.544 30.5234 182.458 30.2133 182.458Z"
        fill="#403D57"
      />
      <path
        d="M15.4339 160.835C14.831 160.835 14.2109 160.68 13.6597 160.352C11.9544 159.368 11.3687 157.176 12.3505 155.468C13.3324 153.76 15.52 153.173 17.2253 154.156C18.9306 155.14 19.5163 157.332 18.5344 159.04C17.8626 160.179 16.6741 160.835 15.4339 160.835ZM15.4511 155.416C14.8138 155.416 14.1936 155.744 13.8491 156.331C13.3496 157.211 13.6424 158.35 14.5209 158.85C15.3994 159.351 16.5363 159.057 17.0358 158.177C17.5354 157.297 17.2425 156.158 16.364 155.658C16.0712 155.485 15.7612 155.416 15.4511 155.416Z"
        fill="#403D57"
      />
      <path
        d="M3.54862 112.17C2.94573 112.17 2.32562 112.015 1.77441 111.687C0.0690995 110.703 -0.516562 108.512 0.465282 106.803C1.44713 105.095 3.63474 104.508 5.34005 105.492C7.04536 106.476 7.63102 108.667 6.64918 110.376C5.99461 111.532 4.78884 112.17 3.54862 112.17ZM3.56584 106.769C2.9285 106.769 2.30839 107.097 1.96389 107.684C1.46435 108.564 1.75718 109.703 2.63567 110.203C3.51417 110.703 4.63381 110.41 5.15057 109.53C5.65011 108.65 5.35728 107.511 4.47878 107.01C4.18595 106.838 3.8759 106.769 3.56584 106.769Z"
        fill="#403D57"
      />
      <path
        d="M17.4146 104.111C16.8117 104.111 16.1916 103.956 15.6404 103.628C14.8136 103.145 14.2279 102.385 13.9868 101.454C13.7456 100.539 13.8662 99.5726 14.3485 98.7443C14.8308 97.9159 15.5887 97.3292 16.5189 97.0876C17.4319 96.846 18.3965 96.9668 19.2233 97.45C20.9286 98.4336 21.5143 100.625 20.5324 102.334C19.8434 103.473 18.6549 104.111 17.4146 104.111ZM17.4319 98.6925C17.2768 98.6925 17.1046 98.7098 16.9495 98.7615C16.4672 98.8823 16.0711 99.1929 15.8299 99.6244C15.5887 100.056 15.5198 100.556 15.6404 101.022C15.761 101.505 16.0711 101.902 16.5017 102.144C17.3802 102.644 18.4998 102.351 19.0166 101.471C19.5161 100.591 19.2233 99.4518 18.3448 98.9514C18.0692 98.7788 17.7419 98.6925 17.4319 98.6925Z"
        fill="#403D57"
      />
      <path
        d="M73.3972 180.421C72.7944 180.421 72.1743 180.266 71.623 179.938C69.9177 178.955 69.3321 176.763 70.3139 175.054C71.2958 173.346 73.4834 172.759 75.1887 173.743C76.894 174.727 77.4796 176.918 76.4978 178.627C75.826 179.766 74.6375 180.421 73.3972 180.421ZM73.4145 175.003C72.7771 175.003 72.157 175.331 71.8125 175.917C71.313 176.797 71.6058 177.936 72.4843 178.437C73.3628 178.955 74.4997 178.644 74.9992 177.764C75.4987 176.884 75.2059 175.745 74.3274 175.244C74.0346 175.072 73.7245 175.003 73.4145 175.003Z"
        fill="#403D57"
      />
      <path
        d="M174.889 152.948C174.286 152.948 173.666 152.793 173.115 152.465C172.288 151.982 171.702 151.223 171.461 150.291C171.22 149.376 171.34 148.41 171.823 147.581C172.804 145.873 174.992 145.286 176.697 146.27C178.403 147.254 178.988 149.445 178.007 151.154C177.318 152.31 176.129 152.948 174.889 152.948ZM174.906 147.547C174.269 147.547 173.649 147.875 173.304 148.462C173.063 148.893 172.994 149.393 173.115 149.859C173.235 150.343 173.545 150.739 173.976 150.981C174.854 151.499 175.991 151.188 176.491 150.308C176.99 149.428 176.697 148.289 175.819 147.789C175.526 147.616 175.216 147.547 174.906 147.547Z"
        fill="#403D57"
      />
      <path
        d="M148.137 13.357C147.535 13.357 146.914 13.2017 146.363 12.8738C144.658 11.8901 144.072 9.69851 145.054 7.99007C146.036 6.28163 148.224 5.6949 149.929 6.67854C151.634 7.66219 152.22 9.85382 151.238 11.5623C150.566 12.7185 149.378 13.357 148.137 13.357ZM148.155 7.95556C147.517 7.95556 146.897 8.28344 146.553 8.87018C146.053 9.75028 146.346 10.872 147.225 11.3897C148.103 11.9074 149.24 11.5968 149.739 10.7167C150.239 9.83657 149.946 8.71486 149.068 8.19716C148.775 8.02459 148.465 7.95556 148.155 7.95556Z"
        fill="#403D57"
      />
      <path
        d="M117.339 32.8745C116.736 32.8745 116.116 32.7191 115.565 32.3913C113.86 31.4076 113.274 29.216 114.256 27.5075C115.238 25.7991 117.425 25.2124 119.131 26.196C120.836 27.1797 121.422 29.3713 120.44 31.0797C119.768 32.2359 118.579 32.8745 117.339 32.8745ZM117.356 27.473C116.719 27.473 116.099 27.8009 115.754 28.3876C115.255 29.2678 115.548 30.4067 116.426 30.9072C117.305 31.4249 118.442 31.1142 118.941 30.2341C119.441 29.354 119.148 28.2151 118.269 27.7146C117.976 27.5421 117.666 27.473 117.356 27.473Z"
        fill="#403D57"
      />
      <path
        d="M59.0828 24.108C58.4627 24.108 57.8598 23.9527 57.3086 23.6248C55.6033 22.6412 55.0176 20.4496 55.9995 18.7411C56.9813 17.0327 59.1689 16.4459 60.8742 17.4296C61.701 17.9128 62.2867 18.6721 62.5279 19.604C62.769 20.5186 62.6484 21.485 62.1661 22.3133C61.6838 23.1417 60.9259 23.7284 59.9957 23.97C59.7201 24.0735 59.3929 24.108 59.0828 24.108ZM59.1 18.7066C58.4627 18.7066 57.8426 19.0345 57.4981 19.6212C56.9985 20.5013 57.2914 21.6403 58.1699 22.1407C58.6005 22.3823 59.1 22.4514 59.5651 22.3306C60.0474 22.2098 60.4436 21.8991 60.6848 21.4677C60.9259 21.0363 60.9948 20.5358 60.8742 20.0699C60.7537 19.5867 60.4436 19.1898 60.013 18.9482C59.7201 18.7756 59.4101 18.7066 59.1 18.7066Z"
        fill="#403D57"
      />
      <path
        d="M29.3518 15.3242C28.749 15.3242 28.1288 15.1688 27.5776 14.841C25.8723 13.8573 25.2867 11.6657 26.2685 9.95724C26.7508 9.12891 27.5087 8.54218 28.4389 8.30058C29.3518 8.05898 30.3165 8.17978 31.1433 8.66297C32.8486 9.64662 33.4342 11.8383 32.4524 13.5467C31.7806 14.6856 30.5748 15.3242 29.3518 15.3242ZM29.3518 9.90547C29.1968 9.90547 29.0246 9.92273 28.8695 9.9745C28.3872 10.0953 27.991 10.4059 27.7499 10.8373C27.2504 11.7175 27.5432 12.8564 28.4217 13.3569C29.3002 13.8573 30.437 13.5639 30.9366 12.6838C31.1777 12.2524 31.2466 11.752 31.126 11.286C31.0055 10.8028 30.6954 10.4059 30.2648 10.1643C29.9892 9.99176 29.6791 9.90547 29.3518 9.90547Z"
        fill="#403D57"
      />
      <path
        d="M7.32042 69.2004C6.70031 69.2004 6.09742 69.0451 5.54621 68.7172C4.7194 68.234 4.13374 67.4747 3.89258 66.5428C3.6342 65.6282 3.75478 64.6791 4.23709 63.8507C5.21893 62.1423 7.40655 61.5556 9.11186 62.5392C10.8172 63.5228 11.4028 65.7145 10.421 67.4229C9.93867 68.2513 9.18076 68.838 8.25059 69.0796C7.94053 69.1659 7.63048 69.2004 7.32042 69.2004ZM7.32042 63.7817C7.16539 63.7817 6.99314 63.799 6.83811 63.8507C6.3558 63.9715 5.95962 64.2822 5.71847 64.7136C5.47731 65.145 5.40841 65.6455 5.52899 66.1114C5.64957 66.5946 5.95962 66.9915 6.39025 67.2331C6.82089 67.4747 7.3032 67.5437 7.78551 67.4229C8.26781 67.3021 8.664 66.9915 8.90515 66.5601C9.40469 65.68 9.11186 64.541 8.23336 64.0406C7.95776 63.868 7.6477 63.7817 7.32042 63.7817Z"
        fill="#403D57"
      />
      <path
        d="M5.42606 95.9487C4.80595 95.9487 4.20306 95.7934 3.65185 95.4655C1.94654 94.4818 1.36088 92.2902 2.34272 90.5818C3.32457 88.8733 5.51218 88.2866 7.21749 89.2702C8.04431 89.7534 8.62997 90.5127 8.87112 91.4446C9.11228 92.3592 8.9917 93.3256 8.50939 94.154C8.02708 94.9823 7.26917 95.569 6.339 95.8106C6.04617 95.9142 5.73611 95.9487 5.42606 95.9487ZM5.42606 90.53C4.78872 90.53 4.16861 90.8579 3.8241 91.4446C3.32457 92.3247 3.6174 93.4637 4.49589 93.9641C4.92652 94.2057 5.42606 94.2748 5.89114 94.154C6.37345 94.0332 6.76963 93.7225 7.01079 93.2911C7.25194 92.8597 7.32084 92.3592 7.20026 91.8933C7.07969 91.4101 6.76963 91.0132 6.339 90.7716C6.06339 90.6163 5.75334 90.53 5.42606 90.53Z"
        fill="#403D57"
      />
      <path
        d="M47.5764 15.9283C46.9563 15.9283 46.3534 15.773 45.8022 15.4451C44.0969 14.4614 43.5113 12.2698 44.4931 10.5614C45.475 8.85292 47.6626 8.26619 49.3679 9.24983C51.0732 10.2335 51.6588 12.4251 50.677 14.1335C50.1947 14.9619 49.4368 15.5486 48.5066 15.7902C48.1966 15.8938 47.8865 15.9283 47.5764 15.9283ZM47.5937 10.5096C46.9563 10.5096 46.3362 10.8375 45.9917 11.4242C45.4922 12.3043 45.785 13.4433 46.6635 13.9437C47.542 14.4442 48.6789 14.1508 49.1784 13.2707C49.6779 12.3906 49.3851 11.2516 48.5066 10.7512C48.2138 10.5959 47.9037 10.5096 47.5937 10.5096Z"
        fill="#403D57"
      />
      <path
        d="M168.102 85.6463C167.499 85.6463 166.879 85.4909 166.328 85.1631C164.622 84.1794 164.037 81.9878 165.019 80.2793C166 78.5709 168.188 77.9842 169.893 78.9678C171.599 79.9515 172.184 82.1431 171.202 83.8515C170.548 85.0078 169.342 85.6463 168.102 85.6463ZM168.119 80.2276C167.482 80.2276 166.862 80.5555 166.517 81.1422C166.276 81.5736 166.207 82.0741 166.328 82.54C166.448 83.0232 166.758 83.4201 167.189 83.6617C168.067 84.1794 169.204 83.8688 169.704 82.9887C170.203 82.1086 169.91 80.9869 169.032 80.4692C168.739 80.3139 168.429 80.2276 168.119 80.2276Z"
        fill="#403D57"
      />
      <path
        d="M159.351 55.1533C158.748 55.1533 158.128 54.998 157.577 54.6701C155.872 53.6865 155.286 51.4949 156.268 49.7864C157.25 48.078 159.437 47.4913 161.143 48.4749C162.848 49.4586 163.434 51.6502 162.452 53.3586C161.78 54.5148 160.592 55.1533 159.351 55.1533ZM159.369 49.7347C158.731 49.7347 158.111 50.0626 157.767 50.6493C157.525 51.0807 157.457 51.5812 157.577 52.0471C157.698 52.5303 158.008 52.9272 158.438 53.1688C159.317 53.6865 160.454 53.3759 160.953 52.4958C161.453 51.6157 161.16 50.4767 160.282 49.9763C159.989 49.821 159.679 49.7347 159.369 49.7347Z"
        fill="#403D57"
      />
      <path
        d="M85.7991 32.0633C85.179 32.0633 84.5761 31.908 84.0249 31.5801C82.3196 30.5965 81.7339 28.4048 82.7158 26.6964C83.6976 24.9879 85.8852 24.4012 87.5905 25.3849C88.4173 25.8681 89.003 26.6274 89.2442 27.5592C89.4853 28.4739 89.3647 29.4402 88.8824 30.2686C88.4001 31.0969 87.6422 31.6837 86.712 31.9252C86.4192 32.0288 86.1092 32.0633 85.7991 32.0633ZM85.8163 26.6446C85.179 26.6446 84.5589 26.9725 84.2144 27.5592C83.9732 27.9907 83.9043 28.4911 84.0249 28.9571C84.1455 29.4402 84.4555 29.8372 84.8862 30.0788C85.3168 30.3204 85.8163 30.3894 86.2814 30.2686C86.7637 30.1478 87.1599 29.8372 87.4011 29.4057C87.6422 28.9743 87.7111 28.4739 87.5905 28.0079C87.47 27.5247 87.1599 27.1278 86.7293 26.8862C86.4364 26.7309 86.1264 26.6446 85.8163 26.6446Z"
        fill="#403D57"
      />
      <path
        d="M132.17 7.14438C131.567 7.14438 130.947 6.98907 130.395 6.66119C128.69 5.67754 128.105 3.48591 129.086 1.77747C129.569 0.949133 130.327 0.362396 131.257 0.120799C132.187 -0.120799 133.134 0 133.961 0.483195C134.788 0.96639 135.374 1.7257 135.615 2.65757C135.856 3.57219 135.735 4.53858 135.253 5.36691C134.598 6.50587 133.41 7.14438 132.17 7.14438ZM132.187 1.7257C132.032 1.7257 131.86 1.74295 131.705 1.79472C131.222 1.91552 130.826 2.22615 130.585 2.65757C130.068 3.53768 130.378 4.65938 131.257 5.17709C132.135 5.67754 133.272 5.38417 133.772 4.50407C134.013 4.07264 134.082 3.57219 133.961 3.10625C133.841 2.62306 133.531 2.22615 133.1 1.98455C132.807 1.81198 132.497 1.7257 132.187 1.7257Z"
        fill="#403D57"
      />
      <path
        d="M160.471 118.814C159.868 118.814 159.248 118.659 158.697 118.331C157.87 117.848 157.285 117.088 157.043 116.157C156.802 115.242 156.923 114.276 157.405 113.447C157.887 112.619 158.645 112.032 159.576 111.791C160.488 111.532 161.453 111.67 162.28 112.153C163.985 113.137 164.571 115.328 163.589 117.037C162.9 118.176 161.694 118.814 160.471 118.814ZM160.471 113.395C160.316 113.395 160.144 113.413 159.989 113.465C159.507 113.585 159.11 113.896 158.869 114.327C158.628 114.759 158.559 115.259 158.68 115.725C158.8 116.208 159.11 116.605 159.541 116.847C160.42 117.365 161.556 117.054 162.056 116.174C162.556 115.294 162.263 114.155 161.384 113.654C161.109 113.482 160.799 113.395 160.471 113.395Z"
        fill="#403D57"
      />
      <path
        d="M172.201 148.168C171.978 148.168 171.754 148.082 171.599 147.909L152.134 128.426H125.779C125.297 128.426 124.918 128.047 124.918 127.563C124.918 127.08 125.297 126.701 125.779 126.701H152.478C152.702 126.701 152.926 126.787 153.081 126.959L172.787 146.701C173.132 147.046 173.132 147.581 172.787 147.927C172.632 148.082 172.425 148.168 172.201 148.168Z"
        fill="#403D57"
      />
      <path
        d="M135.288 182.872C135.064 182.872 134.84 182.786 134.685 182.613L123.954 171.862C123.799 171.707 123.695 171.482 123.695 171.258V148.41C123.695 147.927 124.074 147.547 124.557 147.547C125.039 147.547 125.418 147.927 125.418 148.41V170.896L135.908 181.405C136.253 181.75 136.253 182.285 135.908 182.63C135.736 182.786 135.512 182.872 135.288 182.872Z"
        fill="#403D57"
      />
      <path
        d="M150.274 162.595C149.791 162.595 149.412 162.216 149.412 161.732V141.697H132.17C131.688 141.697 131.309 141.317 131.309 140.834C131.309 140.351 131.688 139.971 132.17 139.971H150.274C150.756 139.971 151.135 140.351 151.135 140.834V161.732C151.135 162.216 150.756 162.595 150.274 162.595Z"
        fill="#403D57"
      />
      <path
        d="M72.2433 54.3421C71.761 54.3421 71.3821 53.9625 71.3821 53.4793V34.0652L61.1846 23.8491C60.8401 23.504 60.8401 22.969 61.1846 22.6239C61.5292 22.2787 62.0631 22.2787 62.4076 22.6239L72.8462 33.0816C73.0012 33.2369 73.1046 33.4612 73.1046 33.6856V53.4448C73.1046 53.9625 72.7256 54.3421 72.2433 54.3421Z"
        fill="#403D57"
      />
      <path
        d="M8.13032 90.0986C7.94084 90.0986 7.75136 90.0296 7.59633 89.9088C7.21738 89.6154 7.1657 89.0632 7.45853 88.7008L17.2253 76.3276C17.3803 76.1205 17.6387 75.9997 17.8971 75.9997H41.5475C42.0298 75.9997 42.4087 76.3793 42.4087 76.8625C42.4087 77.3457 42.0298 77.7254 41.5475 77.7254H18.3277L8.80211 89.788C8.64708 89.9951 8.3887 90.0986 8.13032 90.0986Z"
        fill="#403D57"
      />
      <path
        d="M41.5649 66.2493H10.0253C9.54302 66.2493 9.16406 65.8697 9.16406 65.3865C9.16406 64.9033 9.54302 64.5236 10.0253 64.5236H41.5649C42.0472 64.5236 42.4262 64.9033 42.4262 65.3865C42.4262 65.8524 42.03 66.2493 41.5649 66.2493Z"
        fill="#403D57"
      />
      <path
        d="M117.339 62.9017C116.857 62.9017 116.478 62.522 116.478 62.0389V32.9436C116.478 32.4604 116.857 32.0808 117.339 32.0808C117.822 32.0808 118.201 32.4604 118.201 32.9436V62.0389C118.201 62.522 117.822 62.9017 117.339 62.9017Z"
        fill="#403D57"
      />
      <path
        d="M111.172 177.712C110.69 177.712 110.311 177.333 110.311 176.849V144.613C110.311 144.13 110.69 143.75 111.172 143.75C111.655 143.75 112.034 144.13 112.034 144.613V176.832C112.034 177.315 111.655 177.712 111.172 177.712Z"
        fill="#403D57"
      />
      <path
        d="M40.2899 101.402H20.1191C19.6368 101.402 19.2578 101.022 19.2578 100.539C19.2578 100.056 19.6368 99.6763 20.1191 99.6763H40.3072C40.7895 99.6763 41.1684 100.056 41.1684 100.539C41.1512 101.022 40.7723 101.402 40.2899 101.402Z"
        fill="#403D57"
      />
      <path
        d="M40.2902 127.166H20.1193C19.8782 127.166 19.6542 127.063 19.482 126.89L4.78878 111.1C4.4615 110.755 4.47872 110.203 4.82323 109.875C5.16774 109.547 5.71895 109.564 6.04623 109.909L20.481 125.423H40.2902C40.7725 125.423 41.1514 125.803 41.1514 126.286C41.1514 126.769 40.7725 127.166 40.2902 127.166Z"
        fill="#403D57"
      />
      <path
        d="M33.3654 182.872C33.107 182.872 32.8486 182.751 32.6936 182.544C32.4008 182.164 32.4697 181.629 32.8486 181.336L47.9725 169.481V144.613C47.9725 144.13 48.3514 143.75 48.8337 143.75C49.316 143.75 49.695 144.13 49.695 144.613V169.895C49.695 170.154 49.5744 170.412 49.3677 170.568L33.9166 182.682C33.7271 182.82 33.5549 182.872 33.3654 182.872Z"
        fill="#403D57"
      />
      <path
        d="M73.4145 174.226C72.9322 174.226 72.5532 173.847 72.5532 173.363V148.099C72.5532 147.616 72.9322 147.236 73.4145 147.236C73.8968 147.236 74.2758 147.616 74.2758 148.099V173.363C74.2758 173.847 73.8796 174.226 73.4145 174.226Z"
        fill="#403D57"
      />
      <path
        d="M98.15 186.392C97.9261 186.392 97.7021 186.306 97.5471 186.133L85.4205 173.984C85.2655 173.829 85.1621 173.605 85.1621 173.38V153C85.1621 152.517 85.5411 152.137 86.0234 152.137C86.5057 152.137 86.8846 152.517 86.8846 153V173.018L98.7701 184.925C99.1146 185.271 99.1146 185.805 98.7701 186.151C98.5979 186.306 98.3739 186.392 98.15 186.392Z"
        fill="#403D57"
      />
      <path
        d="M33.3655 158.126H18.8618C18.3794 158.126 18.0005 157.746 18.0005 157.263C18.0005 156.78 18.3794 156.4 18.8618 156.4H32.8659L40.8068 142.698C41.048 142.284 41.5647 142.146 41.9781 142.387C42.3916 142.629 42.5294 143.147 42.2882 143.561L34.0889 157.694C33.9511 157.953 33.6755 158.126 33.3655 158.126Z"
        fill="#403D57"
      />
      <path
        d="M140.197 68.4928V60.7616H134.874C134.736 56.7925 131.498 53.6173 127.519 53.6173H127.416V46.0587H119.699V53.6173H115.048V46.0587H107.314V53.6173H102.249V46.0587H94.5324V53.6173H89.8816V46.0587H82.1646V53.6173H77.1004V46.0587H69.3834V53.6173H64.7326V46.0587H57.0156V53.6173H51.9514V46.0587H44.2345V53.6173H43.7521C39.7559 53.6173 36.5347 56.7925 36.3969 60.7616H31.436V68.4928H36.3797V73.1521H31.436V80.8833H36.3797V85.9568H31.436V93.6879H36.3797V98.3473H31.436V106.078H36.3797V111.152H31.436V118.883H36.3797V123.542H31.436V131.274H36.3797V136.347H31.436V144.078H37.0859C38.2573 146.598 40.8066 148.358 43.7694 148.358H44.2172V154.985H51.9342V148.358H56.9984V154.985H64.7154V148.358H69.3662V154.985H77.0832V148.358H82.1474V154.985H89.8644V148.358H94.5152V154.985H102.232V148.358H107.296V154.985H115.013V148.358H119.664V154.985H127.381V148.358H127.484C130.447 148.358 132.979 146.598 134.168 144.078H140.162V136.347H134.874V131.274H140.162V123.56H134.874V118.9H140.162V111.169H134.874V106.096H140.162V98.3645H134.874V93.7052H140.162V85.9741H134.874V80.9005H140.162V73.1694H134.874V68.51H140.197V68.4928Z"
        fill="#89BAEE"
      />
      <path
        opacity="0.27"
        d="M55.1898 53.6173H51.9342V46.0587H44.2172V53.6173H43.7521C39.7559 53.6173 36.5347 56.7925 36.3969 60.7616H31.436V68.4928H36.3797V73.1521H31.436V80.8833H36.3797V85.9568H31.436V93.6879H36.3797V98.3473H31.436V106.078H36.3797V111.152H31.436V118.883H36.3797V123.542H31.436V131.274H36.3797V136.347H31.436V144.078H37.0859C38.2573 146.598 40.8066 148.358 43.7694 148.358H44.2172V154.985H51.9342V148.358H56.9984V154.985H64.7154V148.358H69.3662V154.985H77.0832V148.358H82.1474V154.985H89.8643V148.358H94.5152V154.985H102.232V148.358H107.004C70.9854 130.67 53.4156 92.4282 55.1898 53.6173Z"
        fill="#D8DFE9"
      />
      <path
        opacity="0.44"
        d="M114.049 151.533C111.724 150.619 109.502 149.618 107.314 148.548V155.037H115.031V151.896C114.721 151.758 114.376 151.654 114.049 151.533Z"
        fill="#D8DFE9"
      />
      <path
        d="M62.5796 119.746C62.5796 119.625 62.6313 119.401 62.7002 119.176L73.6555 83.4027C74.1722 81.6943 76.2737 80.9005 78.3752 80.9005C80.5284 80.9005 82.6299 81.6943 83.1466 83.4027L94.1019 119.176C94.1536 119.401 94.2225 119.573 94.2225 119.746C94.2225 121.506 91.5526 122.818 89.5717 122.818C88.3315 122.818 87.3496 122.421 87.074 121.333L84.9208 113.775H71.9157L69.7625 121.333C69.4697 122.421 68.5051 122.818 67.2649 122.818C65.2495 122.818 62.5796 121.575 62.5796 119.746ZM83.3017 107.977L78.358 90.5126L73.4143 107.977H83.3017Z"
        fill="white"
      />
      <path
        d="M98.1152 83.5235C98.1152 81.9358 99.9239 81.2455 101.801 81.2455C103.61 81.2455 105.488 81.9358 105.488 83.5235V120.488C105.488 122.024 103.61 122.818 101.801 122.818C99.9239 122.818 98.1152 122.024 98.1152 120.488V83.5235Z"
        fill="white"
      />
      <path
        opacity="0.53"
        d="M119.613 64.5411C120.508 64.6791 120.956 64.8517 121.525 65.5937C121.852 66.0252 121.99 66.7154 121.938 67.3712C121.835 68.769 123.195 69.9597 124.522 69.9597C126.003 69.9597 127.002 68.769 127.106 67.3712C127.364 63.7472 124.591 60.1233 120.973 59.5365C119.595 59.3122 118.2 59.8989 117.787 61.3485C117.477 62.591 118.235 64.3167 119.613 64.5411Z"
        fill="#EDF2F7"
        fillOpacity="0.49"
      />
      <path
        opacity="0.53"
        d="M122.438 77.1041C123.023 87.2857 123.282 97.4673 123.178 107.666C123.144 110.997 128.312 110.997 128.346 107.666C128.449 97.4673 128.191 87.2857 127.605 77.1041C127.399 73.7907 122.231 73.7562 122.438 77.1041Z"
        fill="#EDF2F7"
        fillOpacity="0.49"
      />
    </g>
    <defs>
      <clipPath id="clip0_57:2879">
        <rect width="185" height="191" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ChipIcon;
