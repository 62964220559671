import React from "react";

const Bg1Icon = props => (
  <svg
    {...props}
    width="486"
    height="441"
    viewBox="0 0 486 441"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M178.907 383.415C140.78 367.092 96.109 365.441 64.9586 338.857C32.4671 311.128 10.2304 272.844 2.85153 233.164C-4.39024 194.222 8.84263 156.157 24.3634 120.559C39.0913 86.7799 58.926 54.6468 90.1133 33.0229C120.303 12.0907 158.137 -0.0750837 196.807 0.994535C232.697 1.9873 261.484 30.4404 296.702 38.7373C337.63 48.3792 391.684 23.0199 419.407 53.4357C447.328 84.0691 403.629 130.096 414.82 168.329C426.926 209.685 488.587 236.073 485.15 278.035C481.889 317.85 429.482 333.782 399.069 360.648C367.194 388.805 346.192 435.563 301.605 440.174C256.728 444.815 221.073 401.468 178.907 383.415Z"
      fill="#F8FBFF"
      fillOpacity="0.13"
    />
  </svg>
);

export default Bg1Icon;
