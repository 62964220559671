import React from 'react'

import TabCard from './TabCard'

const SuccessfulTabs = ({
  tabs,
  activeTab,
  workOnNext,
  handleChange,
  onHoverTab,
  stateTab,
  classes,
  errors,
  isPaid,
  errorIfUserNotRegister,
  score,
}) =>
  tabs.map((tab, indexTab) => (
    <TabCard
      activeTab={activeTab}
      workOnNext={workOnNext}
      handleChange={handleChange}
      onHoverTab={onHoverTab}
      stateTab={stateTab}
      classes={classes}
      errors={errors}
      tab={tab}
      indexTab={indexTab}
      key={tab.id}
      isPaid={isPaid}
      errorIfUserNotRegister={errorIfUserNotRegister}
      score={score}
    />
  ))

export default SuccessfulTabs
