import React, { useContext, useState, useEffect, Fragment } from 'react'
import injectStyles from 'react-jss'
import classNames from 'classnames'
import { styles } from './styles'
import ProfileIcon from '../../Images/profileIcon'
import LogOutIcon from '../../Images/logOut'
import { useNavigate } from 'react-router-dom'
import MyEssayTab from './MyEssayTab'
import SubscribeTab from './SubscribeTab'
import ProfileTab from './ProfileTab'
import { authorization } from '../../modules/auth'
import { PATHS } from '../../routing/paths'
import AppMainContext from '../../modules/contexts/appMainContext'
import APIClient from '../../modules/API/Client'
import SmallDevice from '../../components/SmallDevice/SmallDevice'
import { dateFormat, useWindowDimensions } from '../../utils'

const TABS = {
  REVIEW_MY_ESSAY: { id: 'REVIEW_MY_ESSAY', text: 'Review my essay' },
  MY_ESSAY: { id: 'MY_ESSAY', text: 'My essays' },
  SUBSCRIBE: { id: 'SUBSCRIBE', text: 'Membership' },
  PROFILE: { id: 'PROFILE', text: 'Profile' },
}

const UserCabinet = ({ classes, onClickSingleEssay, onClickNewEssay }) => {
  const { width } = useWindowDimensions()

  const { setUserData, resetUserData } = useContext(AppMainContext)
  const [currentTab, setCurrentTab] = useState(TABS.MY_ESSAY.id)
  const [userEmail, setUserEmail] = useState('Loading...')
  const [data, setData] = useState({ history: [], pay_data: {} })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const token = authorization.getAPIKey()
    const userId = authorization.getUserId()

    if (token && userId) {
      getUser(userId)
      getData()
    }
  }, [])

  const getData = async () => {
    setIsLoading(true)
    const res = await APIClient.getUserSubscribe()
    setData(res)
    setIsLoading(false)
  }

  const getUser = async userId => {
    const user = await APIClient.getUser({ user_id: userId })
    if (user.Success) {
      const { mail, name, surname, subscription } = user.data
      setUserData({
        id: userId,
        name: name,
        surname: surname,
        email: mail,
      })
      setUserEmail(mail)
      authorization.login({
        apiKey: user.token,
        userId: userId,
        isPaid: subscription,
      })
    } else {
      authorization.logout()
      resetUserData()
    }
  }

  const navigate = useNavigate()

  const onBack = () => {
    navigate(PATHS.checking)
  }

  const onLending = () => {
    navigate(PATHS.home)
  }

  const onLogout = () => {
    navigate(PATHS.home)
    authorization.logout()
  }

  const getContent = tab => {
    switch (tab) {
      case TABS.MY_ESSAY.id:
        return (
          <MyEssayTab
            classes={classes}
            onBack={onBack}
            onClickSingleEssay={onClickSingleEssay}
            onClickNewEssay={onClickNewEssay}
            loading={isLoading}
          />
        )
      case TABS.SUBSCRIBE.id:
        return (
          <SubscribeTab data={data} clasess={classes} isLoading={isLoading} />
        )
      case TABS.PROFILE.id:
        return <ProfileTab classes={classes} />
      default: {
        return <div>{tab}</div>
      }
    }
  }

  if (width <= 756) {
    return (
      <SmallDevice>
        <div className={classes.simpleText}>
        </div>
      </SmallDevice>
    )
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.leftBlock}>
        <div className={classes.title}>{TABS[currentTab].text}</div>
        {getContent(currentTab)}
      </div>
      <div className={classes.rightBlock}>
        <div className={classes.tabBlock}>
          <ProfileIcon style={{ marginRight: 10 }} /> {userEmail}
        </div>
        <div className={classes.line} />
        <div
          onClick={() => setCurrentTab(TABS.MY_ESSAY.id)}
          className={classNames(classes.tabBlock, classes.hover, {
            [classes.active]: currentTab === TABS.MY_ESSAY.id,
          })}
        >
          {TABS.MY_ESSAY.text}
        </div>
        <div
          onClick={() => setCurrentTab(TABS.SUBSCRIBE.id)}
          className={classNames(classes.tabBlock, classes.hover, {
            [classes.active]: currentTab === TABS.SUBSCRIBE.id,
          })}
        >
          {TABS.SUBSCRIBE.text}
        </div>
        <div
          onClick={() => setCurrentTab(TABS.PROFILE.id)}
          className={classNames(classes.tabBlock, classes.hover, {
            [classes.active]: currentTab === TABS.PROFILE.id,
          })}
        >
          {TABS.PROFILE.text}
        </div>
        <div className={classes.line} />
        <div
          className={classNames(classes.tabBlock, classes.hover)}
          onClick={onLending}
        >
          Return home
        </div>
        <div
          className={classNames(classes.tabBlock, classes.hover)}
          onClick={onBack}
        >
          Back
        </div>
        <div
          onClick={onLogout}
          className={classNames(classes.tabBlock, classes.hover)}
        >
          Log out <LogOutIcon style={{ marginLeft: 10 }} />
        </div>
      </div>
    </div>
  )
}

export default injectStyles(styles)(UserCabinet)
