import React, { useEffect, useState } from 'react'
import MainRouter from './routing/MainRouter'
import { PopupsContextProvider } from './modules/contexts/popupsContext'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import ModalCreateAccount from './components/ModalCreateAccount/ModalCreateAccount'
import ModalLogin from './components/ModalLogin/ModalLogin'
import { AppMainContextProvider } from './modules/contexts/appMainContext'

const theme = createTheme({
  palette: {
    secondary: {
      main: '#2489F5',
    },

    primary: {
      main: '#2489F5',
    },
  },
})

let userData = {}

function App() {
  const [activePopupId, setActivePopupId] = useState(0)
  const [isLend, setIsLend] = useState(false)

  useEffect(() => {
    if (activePopupId === 0) {
      setIsLend(false)
    }
  }, [activePopupId])

  return (
    <MuiThemeProvider theme={theme}>
      <AppMainContextProvider
        value={{
          setUserData: data => (userData = { ...userData, ...data }),
          getUserData: () => userData,
          resetUserData: () => (userData = {}),
        }}
      >
        <PopupsContextProvider
          value={{
            showSignUp: value => {
              setIsLend(value)

              setActivePopupId(1)
            },
            showSignIn: () => {
              setActivePopupId(2)
            },
            hideAll: () => {
              setIsLend(false)
              setActivePopupId(0)
            },
          }}
        >
          <div className="App">
            <MainRouter />
          </div>
          <ModalLogin
            isOpenModal={activePopupId === 2}
            onCloseModal={() => setActivePopupId(0)}
          />
          <ModalCreateAccount
            isLend={isLend}
            isOpenModal={activePopupId === 1}
            onCloseModal={() => setActivePopupId(0)}
          />
        </PopupsContextProvider>
      </AppMainContextProvider>
    </MuiThemeProvider>
  )
}

export default App
