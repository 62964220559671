import React from 'react'
import injectStyles from 'react-jss'
import styles from '../styles'
import classNames from 'classnames'

const OverallScorePercentile = ({
  classes,
  score,
  className,
  percentile,
  ...props
}) => {
  return (
    <div className={classNames(classes.wrapper, className)} {...props}>
      <div className={classes.text}>
        Score is <span className={classes.score}>{score}</span> out of 100
        <br /> or <span className={classes.score}>{percentile}</span> percentile
      </div>
    </div>
  )
}

export default injectStyles(styles)(OverallScorePercentile)
