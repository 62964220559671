export default {
  wrapper: {
    margin: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 24,
    color: '#B9BED1',
    height: '50vh',
    textAlign: 'center',
    flexDirection: 'column',
  },
  button: {
    marginTop: 20,
  },
  text: {
    fontSize: 16,
  },
}
