import React from 'react'
import injectStyles from 'react-jss'
import { styles } from './styles'
import ChipIcon from '../../Images/chipIcon'
import Button from '../Button'
import { useNavigate } from 'react-router-dom'
import { PATHS } from '../../routing/paths'

const ChipBlock = ({ classes, refFeatures }) => {
  const navigate = useNavigate()
  return (
    <div className={classes.wrapper} ref={refFeatures}>
      <div className={classes.preTitle}>artificial intelligence</div>
      <div className={classes.title}>
        Runs on 50+ proprietary
        <br />
        <span className={classes.blue}>Machine Learning</span> and natural
        language processing algorithms
      </div>
      <div className={classes.text}>
        Leverages advanced models and algorithms as well as thousands of good
        and less good essays to make highly individualized suggestions on what
        you could improve, why, and how
      </div>
      <ChipIcon className={classes.icon} />

      <div className={classes.buttonsWrap}>
        <Button
          onClick={() => navigate(PATHS.demo)}
          color="blueOutlined"
          label="Live demo"
          icon={true}
          margin={10}
        />
        <Button
          onClick={() => navigate(PATHS.checking)}
          color="blue"
          label="Try now"
        />
      </div>
    </div>
  )
}

export default injectStyles(styles)(ChipBlock)
