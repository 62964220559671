import React from "react";

const LoadingIcon = props => (
  <svg width={40} height={40} viewBox="0 0 16 22" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 8V5C4.69 5 2 7.69 2 11C2 12.01 2.25 12.97 2.7 13.8L1.24 15.26C0.46 14.03 0 12.57 0 11C0 6.58 3.58 3 8 3V0L12 4L8 8ZM13.3 8.2L14.76 6.74C15.54 7.97 16 9.43 16 11C16 15.42 12.42 19 8 19V22L4 18L8 14V17C11.31 17 14 14.31 14 11C14 9.99 13.74 9.04 13.3 8.2Z"
      fill="#2489F5"
    />
  </svg>
);

export default LoadingIcon;
