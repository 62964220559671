import React from "react";
import classNames from "classnames";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";

const mockItems = [
  {
    id: "structure",
    title: "1. Structure",
    color: "#34C759",
    progress: 100,
    hire: 3
  },
  {
    id: "flow",
    title: "2. Flow",
    color: "#FF9500",
    progress: 100,
    hire: 2
  },
  {
    id: "syntax",
    title: "3. Syntax",
    color: "#AF52DE",
    progress: 89,
    hire: 5
  },
  {
    id: "grammar",
    title: "4. Grammar",
    color: "#5AC8FA",
    progress: 0,
    hire: 4
  }
];

const allHint = mockItems.reduce((acc, item) => {
  acc += item.hire;
  return acc;
}, 0);

const items = mockItems.reduce((acc, item) => {
  acc.push({ ...item, percentHint: (100 / allHint) * item.hire });
  return acc;
}, []);

const UploadTabs = ({ progress, classes }) =>
  items.map((item, index) => {
    let progressInTab = 0;
    if (index === 0) {
      progressInTab = progress <= 25 ? progress * 4 : 100;
    } else if (index === 1) {
      progressInTab =
        progress >= 25 && progress <= 50
          ? (progress - 25) * 4
          : progress >= 50
          ? 100
          : 0;
    } else if (index === 2) {
      progressInTab =
        progress >= 50 && progress <= 75
          ? (progress - 50) * 4
          : progress >= 75
          ? 100
          : 0;
    } else if (index === 3) {
      progressInTab =
        75 <= progress ? (progress <= 100 ? (progress - 75) * 4 : 100) : 0;
    }
    return (
      <Accordion expanded={false}>
        <AccordionSummary className={classes.accordionSummary}>
          <div
            className={classNames(
              classes.headingWrapper,
              classes[`arrow-${index + 1}`]
            )}
          >
            <Typography>{item.title}</Typography>
            <Typography>{progressInTab.toFixed()}%</Typography>
          </div>
          <div className={classes.progressWrapper}>
            <LinearProgress
              variant="determinate"
              value={progressInTab.toFixed()}
            />
          </div>
        </AccordionSummary>
      </Accordion>
    );
  });

export default UploadTabs;
