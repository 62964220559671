import React from 'react'
import ErrorCard from './ErrorCard'

const ErrorsTab = ({
  handleChangeUsefulIds,
  hoveredCategoryId,
  setChecked,
  onHoverCategory,
  filteredErrors,
  handleChangeErrorData,
  usefulHires,
  activeErrorData,
  indexTab,
  classes,
  disableEdit,
  setActiveErrorData,
  isPaid,
  errorIfUserNotRegister,
  onSubmitFeedback,
}) => {
  return filteredErrors.map((hire, index) => (
    <ErrorCard
      isPaid={isPaid}
      handleChangeUsefulIds={handleChangeUsefulIds}
      hoveredCategoryId={hoveredCategoryId}
      setChecked={setChecked}
      onHoverCategory={onHoverCategory}
      usefulHires={usefulHires}
      activeErrorData={activeErrorData}
      indexTab={indexTab}
      classes={classes}
      disableEdit={disableEdit}
      setActiveErrorData={setActiveErrorData}
      handleChangeErrorData={handleChangeErrorData}
      hire={hire}
      index={index}
      key={hire.title + index}
      errorIfUserNotRegister={errorIfUserNotRegister}
      onSubmitFeedback={onSubmitFeedback}
    />
  ))
}

export default ErrorsTab
