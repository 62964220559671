import React, { useState } from "react";
import injectStyles from "react-jss";

import styles from "../styles";
import GenericButton from "../../GenericButton/GenericButton";

const PositiveFeedback = ({ onSubmit, classes }) => {
  const [usersInput, setUsersInput] = useState("");
  const handleSubmit = () => {
    onSubmit({
      type: "Positive",
      reason: usersInput,
      isUsersInput: true,
      reasonId: 3
    });
  };
  return (
    <div className={classes.feedbackForm}>
      <span className={classes.feedbackFormTitle}>How could it be better?</span>
      <textarea
        value={usersInput}
        onChange={e => setUsersInput(e.target.value)}
        placeholder="(Optional)"
        className={classes.feedbackFormDescription}
      />
      <GenericButton
        style={{
          alignSelf: "flex-end"
        }}
        type="filled"
        label="SUBMIT"
        onClick={handleSubmit}
      />
    </div>
  );
};

export default injectStyles(styles)(PositiveFeedback);
