import React from 'react'
import './supportBlock.css'
export const SupportBlock = () => {
  return (
    <div className="supportBlock">
      <div className="supportBlock_text">
        CHCKR routes 30% of revenue to Ukrainian NGOs supporting the
        humanitarian effort. If you would like to make a direct donation, donate
        to 
        <a className="supportBlock_link" href="https://ukrainedefensefund.org">
          UkraineDefenseFund
        </a>
      </div>
    </div>
  )
}
