export default {
  wrapper: {
    fontFamily: "Roboto",
    fontSize: "18px",
    color: "rgba(0, 0, 0, 0.7)",
    padding: " 20px",
    background: "#FFFFFF",
    boxShadow:
      "0px 2px 4px rgb(117 131 142 / 4%), 0px 3px 11px rgb(52 60 68 / 8%)",
    borderRadius: 10,
    overflowY: "scroll",
    overflowX: "clip",
    height: "calc(100vh - 240px)",
    "@media (max-width: 600px)": {
      height: "calc(100vh - 240px)",
      overflowY: "scroll"
    }
  },
  childrenWrapper: {
    // padding: "24px",
    position: "relative",
    display: "inline",
    WebkitBoxDecorationBreak: "clone",
    MsBoxDecorationBreak: "clone",
    OBoxDecorationBreak: "clone",
    boxDecorationBreak: "clone",
    lineHeight: "27px",
    "@media (max-width: 600px)": {
      display: "block"
    },
    "& + *": {
      marginTop: "32px",
      "@media (max-width: 600px)": {
        marginTop: "12px"
      }
    },
    "& + button": {
      "@media (max-width: 600px)": {
        width: "100%"
      }
    },
    // "&::-webkit-scrollbar": {
    //   width: 6,
    //   backgroundColor: "hsla(0, 0%, 0%, .1)",
    //   borderRadius: "46.5px",
    //   border: "none"
    // },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0C38FF",
      borderRadius: "46.5px"
    },
    "&::-webkit-scrollbar-track-piece": {
      width: 6,
      backgroundColor: "hsla(0, 0%, 0%, .1)",
      borderRadius: "46.5px",
      border: "none"
    },
    "&::-webkit-scrollbar-button": {
      opacity: 0
    },
    "& .commonText": {
      position: "relative",
      display: "inline",
      WebkitBoxDecorationBreak: "clone",
      MsBoxDecorationBreak: "clone",
      OBoxDecorationBreak: "clone",
      boxDecorationBreak: "clone"
    }
  },
  highlightedText: {
    cursor: "pointer",
    position: "relative",
    padding: "3px 0"
    // borderRadius: 4
  },
  paragraph: {
    zIndex: 10,
    position: "relative",
    display: "inline",
    WebkitBoxDecorationBreak: "clone",
    MsBoxDecorationBreak: "clone",
    OBoxDecorationBreak: "clone",
    boxDecorationBreak: "clone"
    // backgroundColor: "hsl(224, 100%, 95%)",
    // opacity: 0.95
  },
  sentence: {
    zIndex: 20,
    position: "relative",
    display: "inline",
    WebkitBoxDecorationBreak: "clone",
    MsBoxDecorationBreak: "clone",
    OBoxDecorationBreak: "clone",
    boxDecorationBreak: "clone"
    // backgroundColor: "hsl(228, 100%, 90%)"
    // opacity: 0.9
  },
  word: {
    zIndex: 30,
    position: "relative"
    // backgroundColor: "hsl(228, 100%, 86%)"
    // opacity: 0.86
  }
  // active: {
  // backgroundColor: "hsl(225, 90%, 71%)"
  // opacity: 0.71
  // }
};
