import React from 'react'
import injectStyles from 'react-jss'
import classNames from 'classnames'
import { styles } from './styles'
import LogoIcon from '../../Images/logo'
import Button from '../Button'
import HamburgerButton from '../Nav/HamburgerButton'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { PATHS } from '../../routing/paths'
import { useNavigate } from 'react-router-dom'
import UkraineIcon from '../../assets/icons/ukraine'

const Header = ({
  classes,
  scrollInto,
  setIsOpenMobileAccordion,
  isOpenMobileAccordion,
}) => {
  const navigate = useNavigate()
  return (
    <div
      style={{
        position: 'initial',
        background: 'transparent',
        border: 'none',
        boxShadow: 'none',
      }}
    >
      <div className={classNames(classes.wrapper)}>
        <div>
          {/* <LogoIcon fill={null} /> */}
          <UkraineIcon color="#FFFFFF" />
        </div>
        <div className={classes.burgerMenu}>
          <HamburgerButton
            isOpen={isOpenMobileAccordion}
            setIsOpen={setIsOpenMobileAccordion}
          />
        </div>
        <div className={classes.secondWrap}>
          <div className={classes.textWrapper}>
            <div
              onClick={() => scrollInto('Features')}
              className={classNames(classes.link)}
            >
              Features
            </div>
            <div
              onClick={() => scrollInto('Pricing')}
              className={classNames(classes.link)}
            >
              Pricing
            </div>
            {/*<div*/}
            {/*  onClick={() => scrollInto('About')}*/}
            {/*  className={classNames(classes.link)}*/}
            {/*>*/}
            {/*  About*/}
            {/*</div>*/}
            <div
              onClick={() => navigate(PATHS.demo)}
              className={classNames(classes.link, classes.likeButtons)}
            >
              Live Demo
            </div>
            <div
              onClick={() => navigate(PATHS.checking)}
              className={classNames(classes.link, classes.likeButtons)}
            >
              Try Now
            </div>
          </div>

          <div className={classes.buttonWrapper}>
            <span className={classes.button} style={{ marginRight: 10 }}>
              <Button
                color="whiteOutlined"
                label="Live demo"
                icon={true}
                margin={10}
                onClick={() => navigate(PATHS.demo)}
              />
            </span>
            <span className={classes.button}>
              <Button
                onClick={() => navigate(PATHS.checking)}
                color="purple"
                filled={true}
                label="Try now"
              />
            </span>
          </div>
        </div>
      </div>
      <Accordion
        style={{
          position: 'initial',
          background: '#2489F5 !important',
          color: '#2489F5 !important',
          backgroundColor: '#2489F5 !important',
        }}
        className={classes.accordion}
        expanded={isOpenMobileAccordion}
      >
        <AccordionSummary
          style={{ position: 'initial' }}
          aria-controls="panel1d-content"
          id="panel1d-header"
        />
        <AccordionDetails
          style={{
            background: '#2489F5',
            color: '#2489F5',
            backgroundColor: '#2489F5',
          }}
        >
          <div
            onClick={() => {
              scrollInto('Features')
              setIsOpenMobileAccordion(false)
            }}
            className={classes.linkM}
          >
            Features
          </div>
          <div
            onClick={() => {
              scrollInto('Pricing')
              setIsOpenMobileAccordion(false)
            }}
            className={classes.linkM}
          >
            Pricing
          </div>
          {/*<div*/}
          {/*  onClick={() => {*/}
          {/*    scrollInto('About')*/}
          {/*    setIsOpenMobileAccordion(false)*/}
          {/*  }}*/}
          {/*  className={classes.linkM}*/}
          {/*>*/}
          {/*  About*/}
          {/*</div>*/}
          <div
            onClick={() => navigate(PATHS.demo)}
            className={classes.linkM}
          >
            Live Demo
          </div>
          <div
            onClick={() => navigate(PATHS.checking)}
            className={classes.linkM}
          >
            Try Now
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default injectStyles(styles)(Header)
