import React from "react";

const EditIcon = props => (
  <svg
    {...props}
    width="18"
    height="18"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.586 0.999635C12.9611 0.624693 13.4697 0.414062 14 0.414063C14.5303 0.414062 15.0389 0.624693 15.414 0.999635L18 3.58564C18.3749 3.96069 18.5856 4.46931 18.5856 4.99964C18.5856 5.52996 18.3749 6.03858 18 6.41364L6 18.4136C5.62501 18.7887 5.11639 18.9995 4.586 18.9996H2C1.46957 18.9996 0.960859 18.7889 0.585786 18.4138C0.210714 18.0388 0 17.5301 0 16.9996V14.4136C0.000113275 13.8832 0.210901 13.3746 0.586 12.9996L12.586 0.999635ZM12.414 3.99964L15 6.58564L16.586 4.99964L14 2.41364L12.414 3.99964ZM13.586 7.99964L11 5.41364L2 14.4136V16.9996H4.586L13.586 7.99964Z"
      fill="#CAD2DB"
    />
  </svg>
);

export default EditIcon;
