import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import Button from '../../LendosComponents/Button'
import APIClient from '../../modules/API/Client'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#FFFFFF',
    boxShadow: theme.shadows[5],
    padding: '30px 40px',
    width: '100%',
    maxWidth: 490,
    outline: 'none',
    borderRadius: 15,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 24,
    fontWeight: 700,
    color: '#333333',
    textAlign: 'center',
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonBlock: {
    display: 'flex',
  },

  lineText: {
    fontSize: 18,
    color: '#333333',
    fontWeight: 500,
    margin: '40px 15px',
    textAlign: 'center',
    width: '100%',
  },
  button: {
    width: '48%',
  },
  root: {
    '& > *': {
      width: '100%',
    },
  },
}))

const ModalCancelSubscribtion = ({ isOpenModal, onCloseModal }) => {
  const [error, setError] = useState(false)
  const classes = useStyles()

  const handleCancelSubscribe = async () => {
    const res = await APIClient.cancelSubscribe()

    if (res.Error) {
      setError(res.Error)
    }
    if (res.Success) {
      window.location.reload()
    }
  }
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpenModal}
      onClose={onCloseModal}
      closeAfterTransitions
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpenModal}>
        <div className={classes.paper}>
          <div className={classes.header}>
            <div className={classes.title}>Alert</div>
          </div>
          <div className={classes.content}>
            <div className={classes.lineText}>
              Are you sure you want to delete the subscription?
            </div>
          </div>
          <div className={classes.footer}>
            <div className={classes.button}>
              <Button
                onClick={onCloseModal}
                color="blueOutlined"
                filled={true}
                label="Cancel"
              />
            </div>
            <div className={classes.button}>
              <Button
                onClick={handleCancelSubscribe}
                color="blue"
                filled={true}
                label="Submit"
              />
            </div>
          </div>
          {error ? <div style={{ color: 'red' }}>{error}</div> : null}
        </div>
      </Fade>
    </Modal>
  )
}

export default ModalCancelSubscribtion
