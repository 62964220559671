export default {
  contentWrapper: {
    borderBottom: '1px solid #AEAFB1',
    backgroundColor: 'white',
    display: 'flex',
    padding: '15px 50px 13px 40px',
    justifyContent: 'space-between',
    maxHeight: 80,
  },
  buttonBlock: {
    alignSelf: 'center',
  },
  logo: {
    display: 'flex',
    alignSelf: 'center',
    cursor: 'pointer',
  },
  item: {
    alignSelf: 'center',
    padding: '0 5px',
  },
  '@media (max-width: 1000px)': {
    contentWrapper: {
      padding: '8px 20px',
    },
  },
  loginWrap: {
    display: 'flex',
    alignSelf: 'center',
  },
  link: {
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    marginRight: 15,
  },
  rightWrap: {
    display: 'flex',
  },
}
