import preview from '../../Images/preview.png'

const styles = {
	wrapper: {
		position: 'relative',
		backgroundImage: `url(${ preview })`,
		backgroundSize: '100% 100%',
		backgroundRepeat: 'no-repeat',
		width: '100%',
		objectFit: 'contain',
		marginBottom: 200,
		'@media (max-width: 1100px)': {
			marginBottom: 160,
		},
		'@media (max-width: 1000px)': {
			marginBottom: 120,
		},
		'@media (max-width: 900px)': {
			marginBottom: 60,
		},
		'@media (max-width: 560px)': {
			marginBottom: 30,
		},
	},
	video: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		top: '0',
		left: '0',
	},
	fullScreenButton: {
		color: 'white',
		position: 'relative',
		border: 'none',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		zIndex: '3',
		padding: '1.25em 1.875em',
		borderRadius: '0.5em',
		backgroundColor: 'rgba(22, 23, 24, 0.33)',
		backdropFilter: 'blur(2px)'
	},
	bgCover: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		top: '0',
		left: '0',
		backgroundColor: 'rgba(0, 0, 0, 0.2);'
	}
	// textBlock: {
	//   position: 'relative',
	//   maxWidth: 567,
	//   width: '100%',
	//   '@media (max-width: 900px)': {
	//     maxWidth: '100%',
	//     marginBottom: 100,
	//   },
	//   '@media (max-width: 800px)': {
	//     maxWidth: '100%',
	//     marginBottom: 50,
	//   },
	//   '@media (max-width: 560px)': {
	//     maxWidth: '100%',
	//     marginBottom: 25,
	//   },
	// },
	// title: {
	//   color: '#FFFFFF',
	//   fontWeight: 800,
	//   fontSize: 40,
	//   '@media (max-width: 560px)': {
	//     fontSize: 24,
	//   },
	// },
	// subTitle: {
	//   color: '#FFFFFF',
	//   marginTop: 15,
	//   fontSize: 20,
	//   lineHeight: '155%',
	//   maxWidth: 440,
	//   '@media (max-width: 900px)': {
	//     maxWidth: '100%',
	//   },
	//   '@media (max-width: 560px)': {
	//     fontSize: 18,
	//   },
	// },
	// iconWrapper: {
	//   position: 'relative',
	//   display: 'flex',
	//   justifyContent: 'center',
	//   // width: "min-content",
	//   width: '100%',
	//   '& svg': {
	//     width: '100% !important',
	//   },
	//   '& img': {
	//     width: '100% !important',
	//   },
	//   '@media (max-width: 460px)': {
	//     marginTop: -50,
	//     marginBottom: -25,
	//   },
	//   '@media (max-width: 400px)': {
	//     marginTop: -30,
	//     marginBottom: -35,
	//   },
	// },
	// icon: {},
	// bgIcon: {
	//   position: 'absolute',
	//   top: -100,
	//   left: -30,
	// },

}

export { styles }
