import { localStore } from './storage'

const API_KEY = 'API_KEY'
const USER_KEY = 'USER_KEY'
const IS_PAID = 'IS_PAID'
const LOGIN_SOCIAL = 'LOGIN_SOCIAL'

class Authorization {
  /**
   * Browser storage
   */
  storage

  /**
   *
   * @param storage
   */
  constructor(storage) {
    this.storage = storage
  }

  /**
   * Login user to app
   * @param user
   * @param apiKey
   */
  login({ userId, apiKey, isPaid, isSocial }) {
    this.storage.setData(API_KEY, apiKey)
    this.storage.setData(USER_KEY, userId)
    this.storage.setData(IS_PAID, isPaid)
    this.storage.setData(LOGIN_SOCIAL, isSocial)
  }

  /**
   * Logout user from app
   */
  logout() {
    try {
      this.storage.removeData(API_KEY)
      this.storage.removeData(USER_KEY)
      this.storage.removeData(IS_PAID)
      this.storage.removeData(LOGIN_SOCIAL)
    } catch (error) {
      console.error(error)
    }
  }

  isLogged() {
    const user = this.getUserId()
    const apiKey = this.getAPIKey()
    return user && apiKey
  }

  isPaid() {
    return this.storage.getData(IS_PAID)
  }

  getAPIKey() {
    return this.storage.getData(API_KEY)
  }

  setAPIKey(apiKey) {
    this.storage.setData(API_KEY, apiKey)
  }

  getUserId() {
    return this.storage.getData(USER_KEY)
  }

  getAuthorizationMethodIsSocial() {
    return this.storage.getData(LOGIN_SOCIAL) === 'true'
  }
}

export const authorization = new Authorization(localStore)
