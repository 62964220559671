import React from "react";
import injectStyles from "react-jss";
import classNames from "classnames";

import { styles } from "./styles";

const MainWrapper = ({ children, classes }) => {
  return (
    <div className={classNames(classes.mainWrapper)}>
      <div className={classes.wrapper}>{children}</div>
    </div>
  );
};

export default injectStyles(styles)(MainWrapper);
