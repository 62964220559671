import React from 'react'
import injectStyles from 'react-jss'
import styles from '../styles'
import classNames from 'classnames'

const OverallScoreSimple = ({ classes, score, className, ...props }) => {
  return (
    <div className={classNames(classes.wrapper, className)} {...props}>
      <div className={classes.text}>Overall Score</div>
      <div className={classes.score}>{score}/100</div>
    </div>
  )
}

export default injectStyles(styles)(OverallScoreSimple)
