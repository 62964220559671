import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  body: {
    width: '60%',

    position: 'absolute',
    top: 0,
    left: '20%',

    '& .MuiAlert-standardError': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .MuiAlert-root': {
      padding: '2px 0',
    },
    '& .MuiAlert-icon': {
      padding: 0,
    },
    '& .MuiAlert-message': {
      padding: 0,
    },
  },
}))

export default function TransitionAlerts({ isOpen = true }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <Collapse in={isOpen}>
          <Alert severity="error">Unlock all the CHCKR features</Alert>
        </Collapse>
      </div>
    </div>
  )
}
