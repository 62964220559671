import React from "react";
import injectStyles from "react-jss";
import classNames from "classnames";

import SharedIcon from "../../assets/icons/shared";
import styles from "./styles";

const Shared = ({ onClick, classes, className, ...props }) => {
  return (
    <div
      className={classNames(classes.wrapper, className)}
      onClick={onClick}
      {...props}
    >
      Download
      <SharedIcon className={classes.icon} />
    </div>
  );
};

export default injectStyles(styles)(Shared);
