import React from "react";

const LighterIcon = props => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M11 3C11 3.553 10.552 4 10 4C9.448 4 9 3.553 9 3V1C9 0.447 9.448 0 10 0C10.552 0 11 0.447 11 1V3ZM11.0001 13.4597C11.0001 13.1027 11.1911 12.7717 11.5011 12.5927C12.4261 12.0617 13.0001 11.0677 13.0001 9.99973C13.0001 8.34573 11.6541 6.99973 10.0001 6.99973C8.34613 6.99973 7.00013 8.34573 7.00013 9.99973C7.00013 11.0677 7.57413 12.0617 8.49913 12.5927C8.80913 12.7717 9.00013 13.1027 9.00013 13.4597V17.9997H11.0001V13.4597ZM5.00006 9.99967C5.00006 7.24267 7.24306 4.99967 10.0001 4.99967C12.7571 4.99967 15.0001 7.24267 15.0001 9.99967C15.0001 11.5787 14.2461 13.0617 13.0001 13.9957V17.9997C13.0001 19.1027 12.1031 19.9997 11.0001 19.9997H9.00006C7.89706 19.9997 7.00006 19.1027 7.00006 17.9997V13.9957C5.75306 13.0617 5.00006 11.5787 5.00006 9.99967ZM18.9999 9H16.9999C16.4469 9 15.9999 9.447 15.9999 10C15.9999 10.553 16.4469 11 16.9999 11H18.9999C19.5529 11 19.9999 10.553 19.9999 10C19.9999 9.447 19.5529 9 18.9999 9ZM1 9H3C3.552 9 4 9.447 4 10C4 10.553 3.552 11 3 11H1C0.448 11 0 10.553 0 10C0 9.447 0.448 9 1 9ZM5.6597 4.41792L4.2207 3.02792C3.8237 2.64492 3.1917 2.65692 2.8067 3.05392C2.4227 3.44992 2.4337 4.08392 2.8307 4.46792L4.2697 5.85692C4.4647 6.04492 4.7147 6.13792 4.9647 6.13792C5.2267 6.13792 5.4877 6.03492 5.6837 5.83192C6.0677 5.43592 6.0567 4.80192 5.6597 4.41792ZM15.7795 3.02965C16.1755 2.64665 16.8115 2.65765 17.1925 3.05365C17.5765 3.44965 17.5655 4.08365 17.1685 4.46665L15.7295 5.85665C15.5365 6.04365 15.2855 6.13765 15.0355 6.13765C14.7735 6.13765 14.5115 6.03465 14.3165 5.83165C13.9325 5.43565 13.9435 4.80165 14.3405 4.41865L15.7795 3.02965Z"
      fill="#333333"
    />
  </svg>
);

export default LighterIcon;
