import React from "react";
import injectStyles from "react-jss";
import styles from "../styles";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
};

const EditorFormView = ({ onTextChange, value, children, classes }) => {
  return (
    <div className={classes.wrapper}>
      <textarea
        value={value}
        className={classes.textarea}
        onChange={onTextChange}
        placeholder={"Paste your essay here..."}
      />
      <footer className={classes.footer}>{children}</footer>
    </div>
  );
};

export default injectStyles(styles)(EditorFormView);

