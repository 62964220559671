import React from "react";

const Bg4Icon = props => (
  <svg
    {...props}
    width="530"
    height="494"
    viewBox="0 0 530 494"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M244.778 4.93309C289.611 -5.31891 340.426 -0.0659291 378.88 25.1609C416.923 50.1173 424.696 101.099 449.506 139.238C475.947 179.887 531.313 208.969 529.976 257.443C528.632 306.167 473.779 334.076 443.086 371.94C417.26 403.8 400.673 445.8 363.408 462.924C326.853 479.721 284.784 458.84 244.778 463.064C198.701 467.929 153.729 506.901 110.747 489.6C68.4623 472.58 54.4137 419.812 34.9365 378.6C16.7064 340.026 -5.00345 299.679 1.02774 257.443C6.87679 216.482 41.229 187.189 66.9027 154.742C89.289 126.45 114.36 102.579 141.954 79.3392C174.874 51.6132 202.821 14.5274 244.778 4.93309Z"
      fill="white"
      fillOpacity="0.14"
    />
  </svg>
);

export default Bg4Icon;
