import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import GenericButton from '../GenericButton/GenericButton'
import Input from '../Input/Input'
import CheckBox from '../CheckBox/CheckBox'
import APIClient from '../../modules/API/Client'
import { authorization } from '../../modules/auth'
import PopupsContext from '../../modules/contexts/popupsContext'
import { hashPassword } from '../../utils'
import FacebookLogin from '../FacebookLogin/FacebookLoginComponent'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#FFFFFF',
    boxShadow: theme.shadows[5],
    padding: '30px 88px',
    width: '100%',
    maxWidth: 490,
    outline: 'none',
    borderRadius: 15,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '@media (max-width: 500px)': {
      padding: '20px 20px',
      margin: '0 10px',
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 700,
    color: '#333333',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon: {
    paddingRight: 10,
    '& svg': {
      cursor: 'pointer',
    },
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  buttonBlock: {
    display: 'flex',
    // alignItems: "center"
  },
  facebookBlock: {
    margin: '30px 0',
  },
  bold: {
    color: '#414141',
    fontWeight: 500,
    fontSize: 16,
  },
  wrapperFb: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  wrapperLine: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 30,
  },
  line: {
    borderBottom: '1px solid #D7D8DD',
    width: '100%',
  },
  lineText: {
    fontSize: 14,
    color: '#D7D8DD',
    fontWeight: 500,
    margin: '0 15px',
    whiteSpace: 'nowrap',
  },
  rememberMeWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 30,
  },
  forgetPass: {
    cursor: 'pointer',
    fontWeight: 500,
    fontSize: 16,
    color: '#2489F5',
    textDecoration: 'underline',
  },
  createAccountBlock: {
    marginTop: 30,
  },
  root: {
    '& > *': {
      width: '100%',
    },
  },
}))

const ModalLogin = ({ isOpenModal, onCloseModal }) => {
  const { showSignUp, hideAll } = useContext(PopupsContext)

  const [checked, setChecked] = useState(true)
  const [isError, setIsError] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [error, setError] = useState(false)

  const [values, setValues] = useState({ email: '', password: '' })

  useEffect(() => {
    if (!values.email || !values.password) {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }, [values.email, values.password, values.name])

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const onSubmitLogin = async ({ mail, password }) => {
    try {
      const hashedPassword = hashPassword(password.trim())
      const user = await APIClient.loginUser({
        mail: mail.trim(),
        password: hashedPassword,
      })

      if (user.Error) {
        setError(user.Error)
      }

      if (user.Success) {
        const { user_id, subscription } = user.data
        authorization.login({
          apiKey: user.token,
          userId: user_id,
          isPaid: subscription,
          isSocial: false,
        })
        hideAll()
      }
    } catch (error) {
      console.log({ error })
    }
  }

  const onSubmit = values => {
    onSubmitLogin({ mail: values.email, password: values.password })
  }

  const callbackError = error => {
    setIsError(error)
  }

  const onClickCreateAccount = () => {
    hideAll()

    setTimeout(() => {
      showSignUp()
    }, 300)
  }

  // const clickEnter = document.getElementById("loginInputId");
  // clickEnter.addEventListener("keyup", (event)=> {if(event.code.toLowerCase === "enter"){
  //   console.log("WORK")
  //   document.getElementById("loginButtonId").click()
  // }}
  // )
const onClickDown = e => {
  const clickEnter = document.getElementById("loginInputId");
  clickEnter.addEventListener("keyup", (event)=> {if(event.code.toLowerCase === "enter"){
    console.log("WORK")
    document.getElementById("loginButtonId").click()
  }}
  )
}
  

  

  const classes = useStyles()

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpenModal}
      onClose={onCloseModal}
      closeAfterTransitions
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpenModal}>
        <div className={classes.paper}>
          <div className={classes.header}>
            <div className={classes.title}>Login</div>
          </div>
          <div className={classes.content}>
            <div className={classes.facebookBlock}>
              <FacebookLogin setError={setError} />
            </div>
            <div className={classes.wrapperLine}>
              <div className={classes.line} />
              <div className={classes.lineText}>Or Sign up with Email</div>
              <div className={classes.line} />
            </div>
            <form className={classes.root}>
              <Input
                placeholder="mail@website.com"
                label="Email"
                isRequired={true}
                required={true}
                value={values.email}
                handleChange={handleChange}
                type="email"
                callbackError={callbackError}
              />
              <Input
                placeholder="Min. 8 charachter"
                label="Password"
                isRequired={true}
                required={true}
                value={values.password}
                handleChange={handleChange}
                type="password"
                callbackError={callbackError}
                id="loginInputId"
                onClickDown={onClickDown}
              />
            </form>
          </div>
          <div className={classes.rememberMeWrap}>
            <div className={classes.checkBox}>
              <div className={classes.border}>
                <CheckBox checked={checked} setChecked={setChecked} /> Remember
                me
              </div>
            </div>
            <div className={classes.forgetPass}>Forget password?</div>
          </div>

          <div className={classes.footer}>
            <GenericButton
              style={{ width: '100%' }}
              label="Login"
              onClick={() => onSubmit(values)}
              disabled={isError || isDisabled}
              type="filled"
              id="loginButtonId"
            />
            {error ? (
              <span style={{ color: 'red', marginTop: 5 }}>{error}</span>
            ) : null}
            <div
              className={classNames(classes.createAccountBlock, classes.bold)}
            >
              Not registered yet?{' '}
              <span
                onClick={onClickCreateAccount}
                className={classes.forgetPass}
              >
                Create an Account
              </span>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}

export default ModalLogin
