import React, { useState, useEffect, useContext } from 'react'

import {
	Box,
	Divider,
	Typography,
	Paper,
	CircularProgress,
	circularProgressClasses,
} from "@mui/material";
import { Tabs, Tab } from '@material-ui/core'

import AppMainView from '../component/AppMainView'
import EditorContainer from '../../../components/EditorContainer/container/EditorContainer'
import APIClient from '../../../modules/API/Client'
import { STATE_TABS } from '../../../constants'
import AppMainContext, {
	AppMainContextProvider,
} from '../../../modules/contexts/appMainContext'
import AccordionTabs from '../../../components/AccordionTabs/component/AccordionTabs'
import NavigBar from '../../../components/NavigBar/component/NavigBar'
import Switcher from '../../../components/Switcher/Switcher'
import ModalSummary from '../../../components/ModalSummary/ModalSummary'
import ErrorsTab from '../../../components/AccordionTabs/component/ErrorTab'
import ButtonsAboveTab from '../../../components/ButtonsAboveTab/component/ButtonsAboveTab'
import injectStyles from 'react-jss'
import { styles } from '../../../components/AccordionTabs/styles'
import {
	debounce,
	handleChangeUsefulIds,
	handleChangeWorkOnNext,
	useWindowDimensions,
} from '../../../utils'
import { authorization } from '../../../modules/auth'
import PopupsContext from '../../../modules/contexts/popupsContext'
import SmallDevice from '../../../components/SmallDevice/SmallDevice'
import TransitionAlerts from '../../../components/Alert/Alert'


export const initialActiveErrorData = {
	errorId: '',
	origin: '',
	hideOthers: false,
}

const levels = { Essay: 4, Paragraph: 3, Sentence: 2, Phrase: 1 }

const processResult = ({ data }) => {
	if (!data) return []
	const processedErrors = data.errors
		.reduce((acc, error) => {
			if (Array.isArray(error.types) && error.types.length > 0) {
				error.types.forEach(type => {
					acc.push({
						...error,
						type,
					})
				})
			} else {
				acc.push(error)
			}
			return acc
		}, [])
		.sort((a, b) => (levels[a.level] > levels[b.level] ? -1 : 1))

	return {
		...data,
		errors: processedErrors,
	}
}

let text = "";

const AppMain = ({ classes, dataSingleEssay, setDataSingleEssay }) => {
	const { width } = useWindowDimensions()
	const { showSignIn } = useContext(PopupsContext)
	const { setUserData, resetUserData } = useContext(AppMainContext)

	const [isOpenAlert, setIsOpenAlert] = useState(false)
	const [progress, setProgress] = useState(0)
	const [idInterval, setIdInterval] = useState(null)
	const [loading, setLoading] = useState(false)
	const [isLoaded, setIsLoaded] = useState(!!dataSingleEssay)
	const [isDataLoaded, setIsDataLoaded] = useState(false)
	const [rawInput, setRawInput] = useState(() => text)
	const [tabs, setTabs] = useState([])
	const [isEditMode, setIsEditMode] = useState(false)
	const [isOpenModalSummary, setIsOpenModalSummary] = useState(false)
	const [mode, setMode] = useState(STATE_TABS.UPLOAD_MODE)
	const [isAllCategory, setAllCategory] = useState(true)
	const [hoveredTab, setHoveredTab] = useState(null)
	const [workOnNext, setWorkOnNext] = useState(null)
	const [filteredErrors, setFilteredErrors] = useState([])
	const [usefulHires, setUsefulHires] = useState([])
	const [hoveredCategoryId, setHoveredCategoryId] = useState(null)
	const [summary, setSummary] = useState(null)
	const [isPaid, setIsPaid] = useState(
		dataSingleEssay ? dataSingleEssay.subscription : false,
	)
	const [score, setScore] = useState({})
	const [errorIfUserNotRegister, setErrorIfUserNotRegister] = useState({})
	const [activeErrorData, setActiveErrorData] = useState(initialActiveErrorData)
	const [activeCategory, setActiveCategory] = useState(null)
	const [percentile, setPercentile] = useState(0)
	const [isEssay, setIsEssay] = useState(true)
	const [summaryImgLink, setSummaryImgLink] = useState(false)
	const [
		randomProgressStopIfDataNoLoaded,
		setRandomProgressStopIfDataNoLoaded,
	] = useState(null)
	const [receivedResult, setReceivedResult] = useState({
		text: [],
		errors: [],
		score: {},
	})
	const [hashedName, setHashedName] = useState(null)
	const [currentTab, setCurrentTab] = useState(1)

	useEffect(() => {
		if (isOpenAlert) {
			setTimeout(() => {
				setIsOpenAlert(false)
			}, 3500)
		}
	}, [isOpenAlert])

	useEffect(() => {
		if (isEditMode) {
			setCurrentTab(1)
		}
	}, [isEditMode])

	//Calculate Score
	useEffect(() => {
		const errorsAndDividedScore = receivedResult.errors.reduce(
			(acc, error) => {
				usefulHires.forEach(hireId => {
					if (error.id === hireId) {
						acc[error.type] += error.score
						acc.overall += error.score
					}
				})
				return acc
			},
			{ structure: 0, flow: 0, syntax: 0, grammar: 0, overall: 0 },
		)
		const staleScore = { ...receivedResult.score }
		const newScore = Object.keys(staleScore).reduce((acc, type) => {
			if (type !== 'overall') {
				acc[type] += errorsAndDividedScore[type]
			} else {
				acc[type] += Math.round(errorsAndDividedScore[type] / 4)
			}
			return acc
		}, staleScore)
		setScore(newScore)
	}, [usefulHires.length])

	//Check auth
	useEffect(() => {
		const token = authorization.getAPIKey()
		const userId = authorization.getUserId()

		if (token && userId) {
			setUser(userId)
		}
	}, [])

	const setUser = async userId => {
		const user = await APIClient.getUser({ user_id: userId })
		if (user.Success) {
			const { mail, name, surname, subscription } = user.data
			setUserData({
				id: userId,
				name: name,
				surname: surname,
				email: mail,
			})
			authorization.login({
				apiKey: user.token,
				userId: userId,
				isPaid: subscription,
			})
			setIsPaid(subscription)
		} else {
			authorization.logout()
			resetUserData()
		}
	}

	//Calculate random stop
	useEffect(() => {
		setRandomProgressStopIfDataNoLoaded(
			Math.floor(75 + Math.random() * (95 + 1 - 75)),
		)
	}, [])

	//Sort errors
	useEffect(() => {
		const tabs = Object.values(
			receivedResult.errors.reduce(
				(acc, item) => {
					const { type } = item
					acc[type] = {
						...acc[type],
						id: type,
						count: (acc[type].count += 1),
					}
					return acc
				},
				{
					structure: {
						id: 'structure',
						label: 'Structure',
						count: 0,
					},
					flow: {
						id: 'flow',
						label: 'Flow',
						count: 0,
					},
					syntax: {
						id: 'syntax',
						label: 'Syntax',
						count: 0,
					},
					grammar: {
						is: 'grammar',
						label: 'Grammar',
						count: 0,
					},
				},
			),
		)
		setTabs(tabs)
	}, [receivedResult.errors])

	//Set active category
	useEffect(() => {
		if (workOnNext) {
			setActiveCategory(workOnNext)
		}
	}, [workOnNext])

	//Set active category
	useEffect(() => {
		if (isAllCategory) {
			setActiveCategory(null)
			setWorkOnNext(null)
		}
	}, [isAllCategory])

	//Set active category
	useEffect(() => {
		if (activeCategory) {
			setAllCategory(false)
		} else {
			setAllCategory(true)
		}
	}, [activeCategory])

	const startLoading = () => {
		setProgress(0)
		setLoading(true)
		clearInterval(idInterval)
		const interval = setInterval(() => {
			setProgress(prev => {
				return prev + 1
			})
		}, 280)
		setIdInterval(interval)
	}
	//Set progress
	useEffect(() => {
		if ((progress === 100 && authorization.isLogged()) || dataSingleEssay) {
			clearInterval(idInterval)
			setMode(STATE_TABS.SUCCESSFUL_MODE)
			setIsOpenModalSummary(true)

			setIsLoaded(true)
			setLoading(false)
		}

		if (!isDataLoaded && authorization.isLogged()) {
			if (progress === randomProgressStopIfDataNoLoaded) {
				clearInterval(idInterval)
			}
		}
	}, [progress, isDataLoaded])

	//Set progress
	useEffect(() => {
		if (isDataLoaded && authorization.isLogged()) {
			clearInterval(idInterval)
			const newIdInterval = setInterval(() => {
				setProgress(prev => prev + 1)
			}, 20)

			setIdInterval(newIdInterval)
		}
	}, [isDataLoaded])

	//Set edit mode
	useEffect(() => {
		if (isEditMode) {
			setTimeout(() => {
				onEdit()
			}, 1000)
		}
	}, [isEditMode])

	//Set main screen errors
	useEffect(() => {
		const filteredErrors = receivedResult.errors.reduce((acc, item) => {
			if (item.type === activeCategory) {
				acc.push({ ...item })
			}
			return acc
		}, [])

		setFilteredErrors(filteredErrors)
	}, [activeCategory, receivedResult.errors.length])

	const handleSubmit = async (checkboxes) => {
		setIsEditMode(false)
		setMode(STATE_TABS.UPLOAD_MODE)
		if (authorization.isLogged()) {
			await submitData(checkboxes)
		} else {
			const idInterval = setInterval(() => {
				setProgress(prev => prev + 1)
			}, 25)
			setIdInterval(idInterval)
		}
	}

	//Set progress
	useEffect(() => {
		if (!authorization.isLogged() && progress === 100) {
			clearInterval(idInterval)
			setProgress(0)
			showSignIn()
		}
	}, [progress])

	useEffect(() => {
		if (dataSingleEssay) {
			setIsPaid(dataSingleEssay.subscription)
			setSummary(dataSingleEssay.data.summary)
			setScore(dataSingleEssay.data.score)
			setHashedName(dataSingleEssay.data.hashedName)
			setIsEssay(dataSingleEssay.data.is_essay)
			setSummaryImgLink(dataSingleEssay.data.summary_img_link)

			if (!isPaid) {
				const error = dataSingleEssay.data.errors.find(
					error => error.singleShow,
				)
				setErrorIfUserNotRegister(error)
			}
			const textResult = dataSingleEssay.data.text.reduce((acc, item) => {
				acc += item.para.text + '\n'

				return acc
			}, '')

			text = textResult
			setRawInput(textResult)

			const processedResult = processResult({ data: dataSingleEssay.data })
			setIsDataLoaded(true)
			setReceivedResult({
				...processedResult,
				score: dataSingleEssay.data.score,
			})
		} else if (dataSingleEssay === undefined) {
			text = ''
			setRawInput('')
		}
	}, [dataSingleEssay])

	const submitData = async (checkboxes) => {
		startLoading()

		setIsDataLoaded(false)

		setActiveErrorData(initialActiveErrorData)
		const result = await APIClient.sendTextForChecking({
			checkboxes,
			input: rawInput,
			user_id: authorization.getUserId(),
		})
		setIsPaid(result.subscription)
		setSummary(result.data.summary)
		setScore(result.data.score)
		setHashedName(result.data.hashedName)
		setPercentile(result.data.percentile)
		setIsEssay(result.data.is_essay)
		setSummaryImgLink(result.data.summary_img_link)

		const textResult = result.data.text.reduce((acc, item) => {
			acc += item.para.text + '\n'

			return acc
		}, '')

		setRawInput(textResult)
		if (!isPaid) {
			const error = result.data.errors.find(error => error.singleShow) || {
				type: 'developer',
				id: 'lox',
			}
			setErrorIfUserNotRegister(error)
		}
		// if (result.data.is_essay) {
		const processedResult = processResult({ data: result.data })

		setReceivedResult({ ...processedResult, score: result.data.score })
		// }
		setIsDataLoaded(true)
	}

	const onEdit = () => {
		setReceivedResult({
			...receivedResult,
			text: [],
		})
		setDataSingleEssay(null)
	}
	const onSetRawInput = e => {
		setRawInput(e.target.value)
		text = e.target.value
	}

	const onUpdateRawInput = async (id, replacement) => {

		const data = await APIClient.replaceWord({
			token_ind: id,
			replacement: replacement,
			hashed_name: hashedName
		})

		const { raw_text } = data

		if (raw_text) {
			text = raw_text
			setRawInput(raw_text)
		}
	}

	const showAlert = () => debounce(setIsOpenAlert(true), 3500)

	const setActiveErrorDataHandler = newActiveErrorData => {
		if (
			!isPaid &&
			(newActiveErrorData.errorType !== errorIfUserNotRegister.type ||
				newActiveErrorData.errorId !== errorIfUserNotRegister.id)
		) {
			showAlert()
			return
		}
		if (newActiveErrorData.errorType) {
			setActiveCategory(newActiveErrorData.errorType)
		}
		if (newActiveErrorData.errorId === activeErrorData.errorId) {
			return setActiveErrorData(initialActiveErrorData)
		}
		setActiveErrorData(newActiveErrorData)
	}

	const setActiveCategoryHandler = value => {
		setActiveErrorData(initialActiveErrorData)
		setActiveCategory(value)
	}

	const handleChangeErrorData = panel => (event, isExpanded) => {
		setActiveErrorData(
			isExpanded ? { ...panel, errorId: panel.id, origin: 'card' } : {},
		)
	}

	const headerHoveredTab =
		isAllCategory && hoveredTab ? hoveredTab : activeCategory

	const handleLogOut = () => {
		authorization.logout()
		resetUserData()
		setActiveErrorData(initialActiveErrorData)
		setActiveCategory(null)
		setAllCategory(true)
		window.location.reload()
	}

	const onSubmitFeedback = async errorInfo => {
		await APIClient.sendFeedback({
			hashedName,
			errorInfo,
		})
	}

	const onHoverTab = value => {
		setHoveredTab(value)
		if (!isPaid && value && value !== errorIfUserNotRegister.type) {
			showAlert()
		}
	}

	const resolveText = (text, isPlagiarism = true) => {
		return text.reduce((result, paragraph) => {
			const tokens = paragraph.sentencesTokens.map(sentenceTokens => sentenceTokens.tokens).flat(2)
			return [...result, ...tokens.map(token => {
				if (!!token.plagiarism && isPlagiarism) {
					return <span style={ { backgroundColor: '#F4D6D6', padding: "3px 0px" } }>{ token.text }</span>
				}
				return <span>{ token.text }</span>
			}), <><br/><br/></>]
		}, [])
	}
	const renderTabContent = () => {
		switch (currentTab) {
			case 0:
				return (
					<>
						<section>
							<Paper sx={ {
								fontFamily: "Roboto",
								background: "white",
								borderRadius: '8px',
								resize: "none",
								height: "calc(100vh - 240px)",
								border: "none",
								outline: "none",
								fontSize: "18px",
								color: "rgba(0, 0, 0, 0.7)",
								padding: '20px',
								boxShadow:
									"0px 2px 4px rgba(117, 131, 142, 0.04), 0px 3px 11px rgba(52, 60, 68, 0.08)",
								alignSelf: 'stretch',
								overflowY: 'scroll'
							} }>
								{ isLoaded && resolveText(receivedResult.text, false) }
							</Paper>
						</section>
						<Box sx={ {
							minHeight: '290px',
							alignSelf: 'start',
							position: 'relative',
							flexBasis: '52%',
							maxWidth: '600px',
							flexShrink: 1,
							paddingLeft: '30px'
						} }>

							<Box sx={ {
								borderRadius: '8px',
								display: 'flex',
								flexDirection: 'column',
								boxShadow: ' 0px 3px 11px 0px #343C4414;',
								backgroundColor: '#FFFFFF',
							} }>
								<Box sx={ { backgroundColor: '#FFFFFF', borderRadius: '8px', } }>
									<Typography
										sx={ { fontWeight: '600px', fontSize: '20px', padding: '20px 0px 20px 24px' } }>
										Feedback Summary
									</Typography>
									<Divider/>
								</Box>
								<Typography sx={ {
									padding: '10px 24px 24px 24px',
									fontSize: '18px',
									minHeight: '220px',
									maxHeight: 'calc(100vh - 310px)',
									overflowY: 'scroll',
								} }>
									{ receivedResult?.detailed_summary ? receivedResult?.detailed_summary.reduce((result, paragraph) => {
										return [...result, <><span>{ paragraph }</span><br/><br/></>]
									}, []) : 'N/A'
									}
								</Typography>
							</Box>
						</Box>

					</>
				);
			case 1:
				return (
					<>
						<ModalSummary
							isEssay={ isEssay }
							summaryImgLink={ summaryImgLink }
							isOpenModal={ isOpenModalSummary }
							onCloseModal={ () => setIsOpenModalSummary(false) }
							summary={ summary }
							overall={ score.overall }
							percentile={ percentile }
							hashedName={ hashedName }
						/>
						<EditorContainer
							loading={ loading }
							receivedResult={ isLoaded ? receivedResult.text : [] }
							activeErrorData={ activeErrorData }
							setActiveErrorData={ setActiveErrorDataHandler }
							onSubmit={ handleSubmit }
							rawInput={ rawInput }
							setRawInput={ onSetRawInput }
							updateRawInput={ onUpdateRawInput }
							activeCategory={ activeCategory }
							isLoaded={ isLoaded }
							isAllCategory={ isAllCategory }
							hoveredTab={ hoveredTab }
							usefulHires={ usefulHires }
							hoveredCategoryId={ hoveredCategoryId }
							onHoverCategory={ setHoveredCategoryId }
						/>

						<AccordionTabs
							style={ isEditMode ? { visibility: 'hidden' } : {} }
							loading={ loading }
							stateTab={ mode }
							isDataLoaded={ isDataLoaded }
							progress={ progress }
							tabs={ tabs }
							score={ score }
							onTabClick={ setActiveCategoryHandler }
							onHoverTab={ onHoverTab }
							workOnNext={ workOnNext }
							activeTab={ activeCategory }
							isPaid={ isPaid }
							errorIfUserNotRegister={ errorIfUserNotRegister }
							errors={
								filteredErrors ? (
									<ErrorsTab
										handleChangeUsefulIds={ id =>
											handleChangeUsefulIds(id, setUsefulHires)
										}
										hoveredCategoryId={ hoveredCategoryId }
										setChecked={ setIsEditMode }
										onHoverCategory={ setHoveredCategoryId }
										filteredErrors={ filteredErrors }
										handleChangeErrorData={ handleChangeErrorData }
										usefulHires={ usefulHires }
										activeErrorData={ activeErrorData }
										classes={ classes }
										setActiveErrorData={ setActiveErrorData }
										isPaid={ isPaid }
										errorIfUserNotRegister={ errorIfUserNotRegister }
										onSubmitFeedback={ onSubmitFeedback }
									/>
								) : (
									[]
								)
							}
							buttons={
								<ButtonsAboveTab
									setAllCategory={ setAllCategory }
									isAllCategory={ isAllCategory }
									handleChangeWorkOnNext={ () =>
										handleChangeWorkOnNext(tabs, setWorkOnNext)
									}
									workOnNext={ workOnNext }
									stateTab={ mode }
									isPaid={ isPaid }
									setIsOpenModalSummary={ setIsOpenModalSummary }
								/>
							}
						/>
					</>
				);
			case 2:
				return (
					<>
						<section>
							<Paper sx={ {
								fontFamily: "Roboto",
								background: "white",
								borderRadius: '8px',
								resize: "none",
								height: "calc(100vh - 240px)",
								border: "none",
								outline: "none",
								fontSize: "18px",
								color: "rgba(0, 0, 0, 0.7)",
								padding: '20px',
								boxShadow:
									"0px 2px 4px rgba(117, 131, 142, 0.04), 0px 3px 11px rgba(52, 60, 68, 0.08)",
								alignSelf: 'stretch',
								overflowY: 'scroll'
							} }>
								{ isLoaded && resolveText(receivedResult.text) }
							</Paper>
						</section>
						<Box sx={ {
							minHeight: '290px',
							alignSelf: 'start',
							position: 'relative',
							flexBasis: '52%',
							maxWidth: '600px',
							flexShrink: 1,
							paddingLeft: '30px'
						} }>
							<Box sx={ {
								borderRadius: '8px', display: 'flex',
								backgroundColor: '#FFFFFF',
								flexDirection: 'column', boxShadow: ' 0px 3px 11px 0px #343C4414;',
							} }>
								<Box sx={ { backgroundColor: '#FFFFFF', borderRadius: '8px' } }>
									<Box sx={ { display: 'flex', alignItems: 'center', paddingLeft: '26px' } }>
										<Box sx={ { position: 'relative' } }>
											<CircularProgress variant="determinate" value={ 100 }
															  sx={ { color: 'rgb(238, 238, 238)' } }/>
											<CircularProgress variant="determinate"
															  value={ receivedResult?.plagiarism?.score } sx={ {
												position: 'absolute',
												left: 0,
												color: '#34C759',
												[`&.${ circularProgressClasses.circle }`]: { backgroundColor: 'coral' }
											} }/>
										</Box>
										<Typography
											sx={ {
												fontWeight: '600px',
												fontSize: '20px',
												padding: '20px 0px 20px 24px'
											} }>
											{ receivedResult?.plagiarism?.score } % unique
										</Typography>
									</Box>
									<Divider/>
								</Box>
								<Typography
									sx={ {
										padding: '10px 24px 24px 24px',
										fontSize: '18px',
										overflowY: 'scroll',
										minHeight: '220px',
										maxHeight: 'calc(100vh - 310px)',
									} }>
									{ receivedResult?.plagiarism?.description }
									
									<br />

									{receivedResult?.plagiarism?.link && (<>
										To review the details and understand the extent of the plagiarism, please visit the following <a href={receivedResult.plagiarism.link}>link</a>
									</>)}
									
								</Typography>
							</Box>
						</Box>

					</>
				);

			default:
				return null;
		}
	};

	const renderHeader = () => {
		const isVisible = !isEditMode && isDataLoaded
		const isVisibleStyles = { display: 'none' }
		return (
			<Box sx={ isVisible ? {} : isVisibleStyles }>
				<Box sx={ {
					display: 'flex',
					justifyContent: 'space-between',
					'& > *': {
						'&:first-child': {
							flexBasis: '800px',
							flexShrink: '1',
							width: '100%',
							paddingRight: '50px',
							minWidth: 400,
						},
					},
				} }>
					<Box sx={ {
						width: '100%',
						minWidth: '400px',
						flexShrink: 1,
						paddingRight: '50px',
						margin: '24px 0px 20px 0px ',

					} }>

						<Tabs
							className={ classes.tabs }
							  value={ currentTab }
							  onChange={ (event, newValue) => setCurrentTab(newValue) }
							  aria-label="My tabs">
							<Tab
								label="Detailed summary" id="tab-0"/>
							<Tab
								label="Guided turnaround" id="tab-1"/>
							<Tab
								label="Plagiarism check" id="tab-2"/>
						</Tabs>
					</Box>
					{ !isEditMode && isDataLoaded && <Box sx={ {
						flexBasis: '52%',
						flexShrink: 1,
						pl: '30px',
						maxWidth: '600px'
					} }></Box> }
				</Box>
				<Switcher checked={ isEditMode } setChecked={ setIsEditMode }/>
			</Box>
		)
	}

	return (
		<>
			<NavigBar
				progress={ progress }
				currentTab={ activeCategory }
				stateTab={ mode }
				dataLoaded={ isDataLoaded }
				hoveredTab={ headerHoveredTab }
				handleLogOut={ handleLogOut }
				overall={ score.overall }
				width={ width }
				percentile={ percentile }
				isEssay={ isEssay }
				tabs={ tabs }
				setDataSingleEssay={ setDataSingleEssay }
			/>
			<TransitionAlerts isOpen={ isOpenAlert }/>
			{ width <= 756 ? (
				<SmallDevice/>
			) : (
				<>
					<AppMainView
						editMode={ isEditMode || !isDataLoaded }
						header={ renderHeader() }>
						<AppMainContextProvider
							value={ { hashedName: receivedResult.hashedName } }
						>
							{ renderTabContent() }
						</AppMainContextProvider>
					</AppMainView>

				</>
			) }
		</>
	)
}

export default injectStyles(styles)(AppMain)
