import React, { Fragment } from "react";
import classNames from "classnames";
import injectStyles from "react-jss";
import styles from "../styles";

const EditorResultView = ({ editButton, children, classes }) => {
  return (
    <Fragment>
      <div className={classNames(classes.wrapper, "editorResultViewWrapper")}>
        <p
          className={classNames(classes.childrenWrapper, "scrollWrapperEditor")}
        >
          {children}
        </p>
      </div>
    </Fragment>
  );
};

export default injectStyles(styles)(EditorResultView);
