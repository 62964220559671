export default {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '13px',
    color: '#333333',
  },
  score: {
    fontWeight: 600,
  },
  text: {
    textAlign: 'center',
  },
  '@media (max-width: 1000px)': {
    wrapper: {
      fontSize: '11px !important',
    },
  },
  '@media (max-width: 900px)': {
    wrapper: {
      display: 'none',
    },
  },
}
