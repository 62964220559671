export default {
  wrapper: {
    marginTop: 30,
    padding: '0 50px 0 100px',
    display: 'flex',
    justifyContent: 'space-between',
    '& > section': {
      '&:first-child': {
        width: '100%',
        maxWidth: 684,
        // paddingRight: 50,
        minWidth: 400,
      },
      '&:last-child': {
        width: '52%',
      },
    },
    maxWidth: '1504px',
    margin: '0 auto',
  },
  '@media (max-width: 1000px)': {
    wrapper: {
      padding: '0 20px 0 25px !important',
    },
  },
  '@media (max-width: 600px)': {
    wrapper: {
      marginTop: '20px',
      '& > section': {
        width: '100% !important',
      },
    },
  },
}
