export default {
  wrapper: {
    marginTop: 0,
    padding: '0 50px 0 100px',

  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > section': {
      '&:first-child': {
        flexBasis: '800px',
        flexShrink: '1',
        width: '100%',
        paddingRight: 50,
        minWidth: 400,
      },
    },
  },
  '@media (max-width: 1000px)': {
    wrapper: {
      padding: '0 20px 0 25px !important',
    },
  },
  '@media (max-width: 600px)': {
    wrapper: {
      marginTop: '20px',
      '& > section': {
        width: '100% !important',
      },
    },
  },

}
