import React, { Fragment } from "react";
import classNames from "classnames";

import { options } from "./options";

const UploadThermometer = ({ classes, progress }) => {
  const percentInTab = 100 / options.length;
  return options.map((item, indexBlock) => {
    const percentInCurrentTab = (indexBlock + 1) * percentInTab;
    const titleLength = item.label.split("").length;

    const percentInOneLetter = percentInTab / titleLength;
    return (
      <Fragment key={item.id}>
        <div
          className={classNames(classes.tab, {
            [classes.finish]: progress >= percentInCurrentTab
          })}
        >
          {item.label.split("").map((letter, indexLetter) => {
            const isFinishedLetter =
              indexLetter * percentInOneLetter +
              percentInCurrentTab -
              percentInTab;
            return (
              <span
                className={classNames({
                  [classes.finish]: progress > isFinishedLetter
                })}
              >
                {letter}
              </span>
            );
          })}
        </div>
        {indexBlock < 3 && (
          <div
            className={classNames(classes.horizontalLine, {
              [classes.finish]: progress >= percentInCurrentTab
            })}
          />
        )}
      </Fragment>
    );
  });
};

export default UploadThermometer;
