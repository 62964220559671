import React, {useState, useEffect} from 'react'
import {ERROR_TYPES} from '../../../constants'
import HighlightedText from '../blocks/HighlightedText'
import EditorResultView from '../component/EditorResultView'
import SelectAlternativesMenu from "../../SelectAlternativesMenu/SelectAlternativesMenu";
import { Chip } from "@mui/material";

const getErrorId = (errorTypes, errorIds, activeCategory) => {
    if (errorIds.length === 1 || !activeCategory) {
        return errorIds[0]
    }

    const TABS_ENUM = errorTypes.reduce((acc, category, index) => {
        acc[category] = index
        return acc
    }, {})

    const index = TABS_ENUM[activeCategory]

    const result = errorIds[index]

    return result
}

const EditorResult = ({
                          activeCategory,
                          loading,
                          receivedResult,
                          activeErrorData,
                          setActiveErrorData,
                          editButton,
                          isAllCategory,
                          hoveredTab,
                          usefulHires,
                          hoveredCategoryId,
                          onHoverCategory,
                          updateRawInput,
                      }) => {
    const [processedContent, setProcessedContent] = useState([])
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuContent, setMenuContent] = useState(null);



    const onOpenPopover = (e, data, ref, id) => {
        setMenuContent(data.map((replacement, index) => (
            <Chip sx={{ fontWeight: index === 0 ? 500 : 400, backgroundColor:'#F2F5F9',border:'1px solid #E7E8F1', '&:hover': { backgroundColor: '#2489F5', color: 'white', fontWeight: 500 } }} label={replacement} key={replacement} onClick={() => {
                const innerText = ref.current.innerHTML
                if (innerText.endsWith(' ')) {
                    ref.current.innerHTML = replacement + ' ';
                } else {
                    ref.current.innerHTML = replacement;
                }
            setAnchorEl(null)
            updateRawInput(id, replacement)
            }
            }/>
        )))
        setAnchorEl(e.currentTarget)
    }

    useEffect(() => {
        if (loading) setProcessedContent([])
        if (!receivedResult || !receivedResult.length) return
        const processedContent = renderResult({
            activeCategory,
            receivedResult,
            setActiveErrorData,
            activeErrorData,
            isAllCategory,
            hoveredTab,
            usefulHires,
            hoveredCategoryId,
            onHoverCategory,
            onOpenPopover,
        })

        setProcessedContent(processedContent)

        return () => {
            setProcessedContent([])
        }
    }, [
        receivedResult,
        loading,
        activeErrorData,
        setActiveErrorData,
        activeCategory,
    ])

    return (
        <EditorResultView editButton={editButton}>
            {processedContent}
            {menuContent && <SelectAlternativesMenu
                setAnchorEl={setAnchorEl}
                anchorEl={anchorEl}
            >
                {menuContent}
            </SelectAlternativesMenu>}
        </EditorResultView>
    )
}

export default EditorResult

const generateHighlightedItem = ({
                                     key,
                                     errorId,
                                     activeErrorData,
                                     onClick,
                                     content,
                                     level,
                                     color,
                                     activeCategory,
                                     isUnuseful,
                                     hoveredCategoryId,
                                     disabled,
                                     generalColor,
                                     replacements,
                                     token_ind,
                                     onOpenPopover,
                                 }) => {

    return (
        <HighlightedText
            key={key}
            onClick={onClick}
            isActive={errorId.includes(activeErrorData.errorId)}
            color={color}
            level={level}
            activeCategory={activeCategory}
            isUnuseful={isUnuseful}
            isHoveredActiveError={errorId.includes(hoveredCategoryId)}
            disabled={disabled}
            generalColor={generalColor}
            replacements={replacements}
            token_ind={token_ind}
            onOpenPopover={onOpenPopover}
            errorId={errorId}
        >
            {content}
        </HighlightedText>
    )
}

const renderTokens = ({
                          items,
                          onClick,
                          activeErrorData,
                          sentenceErrorId,
                          paragraphErrorId,
                          activeCategory,
                          isAllCategory,
                          hoveredTab,
                          usefulHires,
                          hoveredCategoryId,
                          onHoverCategory,
                          disabled,
                          onOpenPopover,
                      }) => {
    return items.reduce((acc, token, index) => {
        const {text, errorId, errorTypes, replacements, ind} = token
        const currentType = errorTypes.includes(activeCategory)
            ? activeCategory
            : errorTypes.includes(hoveredTab)
                ? hoveredTab
                : errorTypes[0]

        const filterResults = hoveredTab
            ? errorTypes.includes(hoveredTab)
            : errorTypes[0]

        const checkCategory = isAllCategory
            ? filterResults
            : errorId &&
            errorTypes &&
            errorTypes.includes(activeCategory) &&
            ![sentenceErrorId, paragraphErrorId].includes(
                activeErrorData.errorId,
            ) &&
            (!activeErrorData.hideOthers || errorId === activeErrorData.errorId)

        if (checkCategory) {
            const {color, generalColor} = ERROR_TYPES[
                isAllCategory ? currentType : activeCategory
                ]

            const onClickHandler = e => {
                e.stopPropagation()
                e.preventDefault()
                onClick({
                    ...token,
                    errorId: getErrorId(errorTypes, errorId, activeCategory),
                    origin: 'editor',
                    errorType: activeCategory ? activeCategory : errorTypes[0],
                })
            }

            const isUnuseful = usefulHires.filter(element =>
                errorId.includes(element),
            ).length

            const highlightedItem = generateHighlightedItem({
                key: `${index}-${errorId}-${activeCategory}`,
                errorId,
                activeErrorData,
                onClick: onClickHandler,
                content: text,
                level: 'word',
                color,
                activeCategory,
                isUnuseful,
                hoveredCategoryId,
                onHoverCategory,
                disabled,
                generalColor,
                replacements,
                token_ind: ind,
                onOpenPopover
            })
            acc.push(highlightedItem)
        } else {
            acc.push(
                <span key={`${index}-commonText-1`} className="commonText">
          {text}
        </span>,
            )
        }
        return acc
    }, [])
}

const renderWrapper = ({
                           activeCategory,
                           items,
                           activeErrorData,
                           setActiveErrorData,
                           paragraphErrorId,
                           isAllCategory,
                           hoveredTab,
                           usefulHires,
                           hoveredCategoryId,
                           onHoverCategory,
                           disabled,
                           onOpenPopover,
                       }) => {
    return items.reduce((acc, sentence, sentenceIndex) => {
        const {sent, tokens} = sentence
        const {
            errorId: sentenceErrorId,
            errorTypes: sentenceErrorTypes,
            hidden,
        } = sent

        const isUnuseful = usefulHires.filter(element =>
            sentenceErrorId.includes(element),
        ).length

        const disabledTokenItems = renderTokens({
            items: tokens,
            onClick: setActiveErrorData,
            activeErrorData,
            paragraphErrorId,
            sentenceErrorId,
            activeCategory,
            isAllCategory,
            hoveredTab,
            usefulHires,
            hoveredCategoryId,
            onHoverCategory,
            disabled: !isAllCategory && !isUnuseful,
        })

        const currentType = sentenceErrorTypes.includes(activeCategory)
            ? activeCategory
            : sentenceErrorTypes.includes(hoveredTab)
                ? hoveredTab
                : sentenceErrorTypes[0]

        const filterResults = hoveredTab
            ? sentenceErrorTypes.includes(hoveredTab)
            : sentenceErrorTypes[0]

        const checkCategory = isAllCategory
            ? filterResults
            : sentenceErrorId &&
            sentenceErrorTypes &&
            sentenceErrorTypes.includes(activeCategory) &&
            activeErrorData.errorId !== paragraphErrorId &&
            (!hidden || sentenceErrorId === activeErrorData.errorId)

        if (checkCategory) {
            const {color, generalColor} = ERROR_TYPES[
                isAllCategory ? currentType : activeCategory
                ]

            const onClick = e => {
                e.stopPropagation()
                e.preventDefault()
                setActiveErrorData({
                    ...sent,
                    errorId: getErrorId(
                        sentenceErrorTypes,
                        sentenceErrorId,
                        activeCategory,
                    ),
                    origin: 'editor',
                    errorType: activeCategory ? activeCategory : sentenceErrorTypes[0],
                    hideOthers: false,
                })
            }

            const highlightedItem = generateHighlightedItem({
                key: `${sentenceErrorId}-${activeCategory}-${sentenceIndex}`,
                errorId: sentenceErrorId,
                activeErrorData,
                onClick,
                content: disabledTokenItems,
                level: 'sentence',
                color,
                activeCategory,
                isUnuseful,
                hoveredCategoryId,
                onHoverCategory,
                disabled,
                generalColor,
            })
            acc.push(highlightedItem)
        } else {
            const tokenItems = renderTokens({
                items: tokens,
                onClick: setActiveErrorData,
                activeErrorData,
                paragraphErrorId,
                sentenceErrorId,
                activeCategory,
                isAllCategory,
                hoveredTab,
                usefulHires,
                hoveredCategoryId,
                onHoverCategory,
                disabled,
                onOpenPopover
            })
            acc.push(
                <span key={`${sentenceIndex}-commonText-2`} className="commonText">
          {tokenItems}
        </span>,
            )
        }
        return acc
    }, [])
}

const renderResult = ({
                          activeCategory,
                          receivedResult,
                          activeErrorData,
                          setActiveErrorData,
                          isAllCategory,
                          hoveredTab,
                          usefulHires,
                          hoveredCategoryId,
                          onHoverCategory,
                          onOpenPopover,
                      }) => {
    return receivedResult.reduce((acc, paragraphData, paragraphIndex) => {
        const {para, sentencesTokens} = paragraphData
        const {
            errorId: paragraphErrorId,
            errorTypes: paragraphErrorTypes,
            hidden,
        } = para

        const filterResults = hoveredTab
            ? paragraphErrorTypes.includes(hoveredTab)
            : paragraphErrorTypes[0]

        const checkCategory = isAllCategory
            ? filterResults
            : paragraphErrorId &&
            paragraphErrorTypes &&
            paragraphErrorTypes.includes(activeCategory) &&
            (!hidden || paragraphErrorId === activeErrorData.errorId) &&
            (!activeErrorData.hideOthers ||
                paragraphErrorId === activeErrorData.errorId)

        if (checkCategory) {
            const onClick = e => {
                e.stopPropagation()
                e.preventDefault()
                setActiveErrorData({
                    ...para,
                    errorId: getErrorId(
                        paragraphErrorTypes,
                        paragraphErrorId,
                        activeCategory,
                    ),
                    origin: 'editor',
                    errorType: activeCategory ? activeCategory : paragraphErrorTypes[0],
                })
            }

            const currentType = paragraphErrorTypes.includes(activeCategory)
                ? activeCategory
                : paragraphErrorTypes.includes(hoveredTab)
                    ? hoveredTab
                    : paragraphErrorTypes[0]

            const {color, generalColor} = ERROR_TYPES[
                isAllCategory ? currentType : activeCategory
                ]

            const isUnuseful = usefulHires.filter(element =>
                paragraphErrorId.includes(element),
            ).length

            const disabledSentenceItems = renderWrapper({
                items: sentencesTokens,
                activeErrorData,
                setActiveErrorData,
                paragraphErrorId,
                activeCategory,
                isAllCategory,
                hoveredTab,
                usefulHires,
                hoveredCategoryId,
                onHoverCategory,
                disabled: !isAllCategory && !isUnuseful,
            })

            const highlightedItem = generateHighlightedItem({
                key: `${paragraphIndex}-${paragraphErrorId}-${activeCategory}`,
                errorId: paragraphErrorId,
                activeErrorData,
                onClick,
                content: disabledSentenceItems,
                level: 'paragraph',
                activeCategory,
                color,
                isUnuseful,
                hoveredCategoryId,
                onHoverCategory,
                generalColor,
            })
            acc.push(highlightedItem)
        } else {
            const sentenceItems = renderWrapper({
                items: sentencesTokens,
                activeErrorData,
                setActiveErrorData,
                paragraphErrorId,
                activeCategory,
                isAllCategory,
                hoveredTab,
                usefulHires,
                hoveredCategoryId,
                onHoverCategory,
                onOpenPopover,
            })
            acc.push(
                <span key={`${paragraphIndex}-commonText-3`} className="commonText">
          {sentenceItems}
        </span>,
            )
        }

        acc.push(
            <br key={`${paragraphIndex}-br-1`}/>,
            <br key={`${paragraphIndex}-br-2`}/>,
        )
        return acc
    }, [])
}
