import React from "react";

const CopyIcon = props => (
  <svg
    {...props}
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.66699 3.33366V13.3337C4.66699 13.7757 4.84259 14.1996 5.15515 14.5122C5.46771 14.8247 5.89163 15.0003 6.33366 15.0003H13.0003C13.4424 15.0003 13.8663 14.8247 14.1788 14.5122C14.4914 14.1996 14.667 13.7757 14.667 13.3337V6.03533C14.667 5.81329 14.6226 5.5935 14.5364 5.38886C14.4503 5.18422 14.3241 4.99886 14.1653 4.84366L11.4028 2.14199C11.0915 1.83754 10.6733 1.66705 10.2378 1.66699H6.33366C5.89163 1.66699 5.46771 1.84259 5.15515 2.15515C4.84259 2.46771 4.66699 2.89163 4.66699 3.33366V3.33366Z"
      stroke="#80869D"
      strokeWidth="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.3335 15.0006V16.6673C11.3335 17.1093 11.1579 17.5333 10.8453 17.8458C10.5328 18.1584 10.1089 18.334 9.66683 18.334H3.00016C2.55814 18.334 2.13421 18.1584 1.82165 17.8458C1.50909 17.5333 1.3335 17.1093 1.3335 16.6673V7.50065C1.3335 7.05862 1.50909 6.6347 1.82165 6.32214C2.13421 6.00958 2.55814 5.83398 3.00016 5.83398H4.66683"
      stroke="#80869D"
      strokeWidth="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default CopyIcon;
