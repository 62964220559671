import React from "react";
import injectStyles from "react-jss";
import styles from "../styles";

const EditorContainerView = ({ header, children, classes }) => {
  return (
    <section className={classes.wrapper}>
      {header}
      {children}
    </section>
  );
};

export default injectStyles(styles)(EditorContainerView);
