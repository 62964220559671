import React, { useState } from 'react'
import injectStyles from 'react-jss'

import styles from './styles'
import UnBlockIcon from '../../assets/icons/unBlock'
import VisaIcon from '../../assets/icons/visa'
import MasterCardIcon from '../../assets/icons/masterCard'
import PayPalIcon from '../../assets/icons/paypal'
import APIClient from '../../modules/API/Client'
import Input from '../../LendosComponents/InputModal/Input'
import { FormHelperText } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { PATHS } from '../../routing/paths'

const UnlockBlock = ({ classes }) => {
	const [promoCode, setPromoCode] = useState('')
	const [isRightPromo, setIsRightPromo] = useState(null)
	const navigate = useNavigate()

	// const [disabled, setDisabled] = useState(true)
	const handleClick = async () => {
		await APIClient.getSubscribe()
	}

	const handleChange = prop => event => {
		setPromoCode(event.target.value)
		setIsRightPromo(null)
		// setDisabled(!event.target.value.length)
	}
	const onSubmit = async () => {
		const { Success } = await APIClient.confirmPromo(promoCode)
		setIsRightPromo(Success)
		if (Success) {
			navigate(PATHS.profile)
		}
	}

	return (
		<div className={ classes.wrapper }>
			<div>
				Subscribe for just <span className={ classes.bold }>$9.99</span> per month
				{/*for <span className={classes.red}>$29.99</span>{' '}*/ }
				{/*<span className={classes.bold}>$9.99</span> per month*/ }
				{/*  or{" "}*/ }
				{/*<span className={classes.bold}>$4.99</span> by invite 5 friends*/ }
			</div>
			<div className={ classes.buttonBlock }>
				<button onClick={ handleClick } className={ classes.unlockButton }>
					<UnBlockIcon style={ { marginRight: 10 } }/>
					Unlock all features
				</button>
			</div>
			<div className={ classes.or }>or</div>
			<div className={ classes.promoBlock }>
				<div className={ classes.input }>
					<Input
						placeholder="Type in your promo code here"
						value={ promoCode }
						handleChange={ handleChange }
						type="promo"
						disableError={ true }
						callbackError={ () => {
						} }
					/>
					{ !isRightPromo && isRightPromo !== null ? (
						<FormHelperText style={ { color: 'red' } }>Incorrect</FormHelperText>
					) : null }
				</div>
				<button onClick={ onSubmit } className={ classes.sumbitButton }>
					Submit
				</button>
			</div>
			<div className={ classes.wrapperIcons }>
				<VisaIcon/> <MasterCardIcon/> <PayPalIcon/>
			</div>
		</div>
	)
}

export default injectStyles(styles)(UnlockBlock)
