export default {
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "18px",
    color: "#333333",
    marginRight: '100px',
  },
  score: {
    fontWeight: 600,
    marginLeft: 5
  },
  text: {
    whiteSpace: "nowrap"
  },
  "@media (max-width: 1000px)": {
    wrapper: {
      fontSize: "14px !important",
      marginRight: '50px',
    }
  },
};
