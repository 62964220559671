const styles = {
  mainWrapper: {
    // height: 6464,
    background:
      'linear-gradient(180deg, #2489F5 0%, #9940C7 22.92%, #FEBE18 44.27%, #34C759 68.75%, #FFFFFF 94.37%)',
  },
  wrapper: {
    height: '100%',
    overflowX: 'hidden',
  },
}

export { styles }
