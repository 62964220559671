import React, { Fragment, useContext } from 'react'
import LogoBetaIcon from '../../../assets/icons/logoBeta'
import injectStyles from 'react-jss'
import styles from '../styles'
import Thermometer from '../../Thermometer/component/Thermometer'
import OverallScoreSimple from '../../OverallScoreSimple/component/OverallScoreSimple'
import PopupsContext from '../../../modules/contexts/popupsContext'
import { PATHS } from '../../../routing/paths'
import { useNavigate, useLocation } from 'react-router-dom'
import Button from '../../../LendosComponents/Button'
import { authorization } from '../../../modules/auth'
import ProfileIcon from '../../../Images/profileIcon'
import UkraineIcon from '../../../assets/icons/ukraine'

const NavigBar = ({
					  classes,
					  stateTab,
					  progress,
					  currentTab,
					  hoveredTab,
					  handleLogOut,
					  overall,
					  width,
					  isEssay,
					  tabs,
					  setDataSingleEssay
				  }) => {
	const { showSignUp, showSignIn } = useContext(PopupsContext)

	const navigate = useNavigate()
	const location = useLocation()

	const path = location.pathname

	return (
		<div className={ classes.contentWrapper }>
			<div className={ classes.logo } onClick={ () => {
				navigate(PATHS.home)
				setDataSingleEssay(undefined)
			} }>
				{/* <LogoBetaIcon /> */ }
				<UkraineIcon/>
			</div>
			{ width <= 756 ? null : (
				<Fragment>
					<div className={ classes.rightWrap }>
						{ progress ? (
							<Fragment>
								{/*<div className={ classes.item }>*/}
								{/*	<Thermometer*/}
								{/*		progress={ progress }*/}
								{/*		currentTab={ currentTab }*/}
								{/*		hoveredTab={ hoveredTab }*/}
								{/*		stateTab={ stateTab }*/}
								{/*		tabs={ tabs }*/}
								{/*		isEssay={ isEssay }*/}
								{/*	/>*/}
								{/*</div>*/}
								{/*<Nav.Link href="/checking">Check My Essay</Nav.Link>*/ }
								<div className={ classes.item }>
									{ overall ? (
										<OverallScoreSimple
											style={ isEssay ? {  } : { opacity: 0 } }
											score={ overall }
										/>
									) : null }
								</div>
							</Fragment>
						) : null }
						{ path !== PATHS.demo ? (
							<div className={ classes.loginWrap }>
								{ !authorization.isLogged() ? (
									<>
										<span onClick={ () => showSignIn() } className={ classes.link }>Log In</span>
										<span onClick={ () => showSignUp() } className={ classes.link }>Sign up</span>
									</>
								) : (
									<span onClick={ handleLogOut } className={ classes.link }>Log out</span>
								) }
							</div>
						) : null }
						<div className={ classes.item }>
							{ authorization.isLogged() && path !== PATHS.demo ? (
								<ProfileIcon
									onClick={ () => navigate(PATHS.profile) }
									style={ { marginRight: 10, cursor: 'pointer' } }
								/>
							) : null }
						</div>
						<div className={ classes.item }>
							{ path === PATHS.demo ? (
								<Button
									onClick={ () => navigate(PATHS.checking) }
									color="blueOutlined"
									filled={ true }
									label="Try now"
								/>
							) : null }
						</div>
					</div>
				</Fragment>
			) }
		</div>
	)
}

export default injectStyles(styles)(NavigBar)
