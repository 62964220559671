export default {
  wrapper: {
    width: '100%',
  },
  selectDropdown: {
    marginBottom: '20px', // or however much space you want
  },
  textarea: {
    fontFamily: "Roboto",
    background: "white",
    borderRadius: 10,
    width: "100%",
    resize: "none",
    height: "calc(100vh - 240px)",
    border: "none",
    outline: "none",
    fontSize: "18px",
    color: "rgba(0, 0, 0, 0.7)",
    padding: 20,
    boxShadow:
      "0px 2px 4px rgba(117, 131, 142, 0.04), 0px 3px 11px rgba(52, 60, 68, 0.08)",
    "@media (max-width: 600px)": {
      height: "calc(34vh - 110px)"
    },
    "& + *": {
      marginTop: 5
    }
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    lineHeight: "24px",
    "& > button": {
      // minWidth: "205px",
      "@media (max-width: 600px)": {
        width: "100%",
        minWidth: "unset"
      },
      "& + *": {
        marginLeft: "24px",
        maxWidth: "300px",
        "@media (max-width: 600px)": {
          display: "flex"
        }
      }
    },
    "& a": {
      textDecoration: "none",
      color: "hsl(229, 100%, 52%)"
    },
    "& input": {
      cursor: "pointer",
      "& + *": {
        // marginLeft: "4px",
        "@media (max-width: 600px)": {
          fontSize: "12px",
          lineHeight: "16px"
        }
      }
    },
    '& .MuiFormGroup-root': {
      alignSelf: 'stretch',
      justifyContent: 'space-around'
    }
  },
  dialogPaper: {
    width: '50%',  // Set width to one-third of the screen width
    height: '100%'
    //maxWidth: '600px',  // Optional: Set a maximum width
  }
};
