import React from 'react';
import injectStyles from 'react-jss';
import styles from '../styles';
import { Box } from "@mui/material";

const AppMainView = ({ children, classes, header, editMode }) => (

  <main className={classes.wrapper} style={{marginTop: editMode ? '30px': '0' }}>
	  {header}
	  <Box className={classes.content}>
		  {children}
	  </Box>

  </main>
);

export default injectStyles(styles)(AppMainView);
