import React, { useEffect, useRef, memo } from 'react'
import classNames from 'classnames'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import EditIcon from '../../../assets/icons/edit'
import TrashIcon from '../../../assets/icons/trash'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import LighterIcon from '../../../assets/icons/ligher'
import GoodIcon from '../../../assets/icons/good'
import BadIcon from '../../../assets/icons/bad'
import FeedbackForm from '../../FeedbackForm/container/FeedbackForm'
import BlockIcon from '../../../assets/icons/block'

const ErrorCard = ({
  handleChangeUsefulIds,
  hoveredCategoryId,
  setChecked,
  onHoverCategory,
  handleChangeErrorData,
  usefulHires,
  activeErrorData,
  indexTab,
  classes,
  disableEdit,
  setActiveErrorData,
  hire,
  index,
  isPaid,
  errorIfUserNotRegister,
  onSubmitFeedback,
}) => {
  const ref = useRef(null)

  const rationale = hire.additionalInformation[0]
  const goodExample = hire.additionalInformation[1]
  const badExample = hire.additionalInformation[2]
  const isUnuseful = usefulHires.includes(hire.id)
  const isBordered = activeErrorData.errorId === hire.id

  const isHardError = 'bad_essay' === hire.id

  useEffect(() => {
    if (activeErrorData.errorId === hire.id && ref.current && !isHardError) {
      try {
        setTimeout(() => {
          const El = document.getElementById('scroll')
          El.scrollTo({ top: ref.current.offsetTop, behavior: 'smooth' })
        }, 500)
      } catch (error) {
        console.error(error)
      }
    }
  }, [activeErrorData.errorId === hire.id])

  return (
    <div className={classes.relativeWrap} ref={ref}>
      {!isPaid && errorIfUserNotRegister.id !== hire.id ? (
        <div className={classes.blockedError}>
          <BlockIcon />
        </div>
      ) : null}
      <Accordion
        square={true}
        elevation={2}
        expanded={activeErrorData.errorId === hire.id}
        onChange={handleChangeErrorData(hire)}
        disabled={!isPaid && errorIfUserNotRegister.id !== hire.id}
      >
        <AccordionSummary
          onMouseEnter={() =>
            isHardError ? () => { } : onHoverCategory(hire.id)
          }
          onMouseLeave={() => (isHardError ? () => { } : onHoverCategory(null))}
          className={classNames(classes.accordionSummary, {
            [classes[`hire-arrow-${indexTab + 1}`]]: !isUnuseful,
            [classes[`hire-arrow-unuseful`]]: isUnuseful,
            [classes.topActiveError]: isBordered,
            [classes.hoveredActiveError]:
              hoveredCategoryId === hire.id &&
              activeErrorData.errorId !== hire.id,
          })}
          style={
            isUnuseful
              ? {
                background: '#E2E4E9',
              }
              : { background: isHardError ? '#ffcbcb' : 'initial' }
          }
          // expandIcon={<ExpandMoreIcon />}
          aria-controls={index}
          id={index}
        >
          <div className={classes.headingWrapper}>
            <div
              className={classNames(classes.hireTitle, {
                [classes.expandedTitle]: activeErrorData.errorId === hire.id,
                [classes.usefulHireTitle]: isUnuseful,
              })}
            >
              {hire.title}
            </div>
            <div className={classes.iconWrapper}>
              <EditIcon
                className={classes.editIcon}
                onClick={event => {
                  event.preventDefault()
                  event.stopPropagation()
                  if (!disableEdit) {
                    setChecked(true)
                  }
                }}
              />
              <TrashIcon
                className={classes.trashIcon}
                onClick={event => {
                  event.preventDefault()
                  event.stopPropagation()
                  handleChangeUsefulIds(hire.id)
                  setActiveErrorData({ hire })
                }}
              />
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails
          className={classNames(classes.accordionDetails, {
            [classes.usefulHireDetails]: isUnuseful,
            [classes.bottomActiveError]: isBordered,
          })}
        >
          <div className={classes.hireSubTitle}>{hire.fullErrorExplan}</div>
          {rationale ? (
            <div className={classes.hireDescriptionWrapper}>
              <div className={classes.hireDescriptionTitle}>Our rationale:</div>
              <div className={classes.hireDescriptionContent}>
                <div className={classes.hireDescriptionIcon}>
                  <LighterIcon />
                </div>
                <div className={classes.hireDescriptionText}>
                  <div>
                    {rationale.description[0]}
                  </div>
                  <ul>
                    {rationale.description.slice(1).map((item, index) => (
                      <li key={index}>
                        {item.description} <br />
                        —  <strong>FROM:</strong> {item.from} <br />
                        —  <strong>TO:</strong> {item.to} <br />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ) : null}
          {goodExample || badExample ? (
            <div className={classes.hireDescriptionWrapper}>
              <div className={classes.hireDescriptionTitle}>Examples:</div>
              {goodExample ? (
                <div className={classes.hireDescriptionContent}>
                  <div className={classes.hireDescriptionIcon}>
                    <GoodIcon />
                  </div>
                  <div className={classes.hireDescriptionText}>
                    {goodExample.description}
                  </div>
                </div>
              ) : null}
              {badExample ? (
                <div className={classes.hireDescriptionContent}>
                  <div className={classes.hireDescriptionIcon}>
                    <BadIcon />
                  </div>
                  <div className={classes.hireDescriptionText}>
                    {badExample.description}
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
          <FeedbackForm
            onSubmitFeedback={onSubmitFeedback}
            cardId={hire.cardId}
            title={hire.title}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default memo(ErrorCard)
