import React from "react";

const MasterCardIcon = props => (
  <svg
    {...props}
    width="36"
    height="23"
    viewBox="0 0 36 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.7492 2.36694H13.0645V19.7719H22.7492V2.36694Z"
      fill="#DADADA"
    />
    <path
      d="M13.6788 11.0721C13.6762 7.67531 15.233 4.46649 17.907 2.36698C13.1017 -1.41107 6.14127 -0.577096 2.36586 4.23085C-1.40955 9.0388 -0.578215 15.9966 4.22973 19.772C8.24342 22.9279 13.8959 22.9279 17.9096 19.772C15.2356 17.6778 13.6762 14.4689 13.6788 11.0721Z"
      fill="#DADADA"
    />
    <path
      d="M35.8179 11.0721C35.8179 17.1853 30.8617 22.1415 24.7485 22.1415C22.2677 22.1415 19.8585 21.3075 17.9072 19.7746C22.7125 15.9966 23.5492 9.0388 19.7711 4.2335C19.2257 3.53984 18.6009 2.91501 17.9072 2.36962C22.7125 -1.40843 29.6729 -0.574455 33.451 4.23085C34.9839 6.18209 35.8179 8.59136 35.8179 11.0721Z"
      fill="#C9C9C9"
    />
  </svg>
);

export default MasterCardIcon;
