import React from "react";

const LogOutIcon = props => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8089 7.49943C10.349 7.5168 9.96212 7.15804 9.94476 6.69813C9.90561 5.66131 9.85064 4.90457 9.79659 4.362C9.74336 3.82767 9.42079 3.50618 8.97113 3.45121C8.44092 3.3864 7.69297 3.33335 6.6666 3.33335C5.64019 3.33335 4.89223 3.3864 4.36203 3.45122C3.91261 3.50616 3.58987 3.8278 3.53663 4.36225C3.43631 5.36936 3.33327 7.10934 3.33327 10C3.33327 12.8907 3.43631 14.6307 3.53664 15.6378C3.58987 16.1722 3.91261 16.4939 4.36203 16.5488C4.89223 16.6136 5.64019 16.6667 6.6666 16.6667C7.69298 16.6667 8.44093 16.6136 8.97114 16.5488C9.42079 16.4939 9.74336 16.1724 9.79659 15.638C9.85064 15.0955 9.90562 14.3387 9.94476 13.3019C9.96212 12.842 10.349 12.4833 10.8089 12.5006C11.2688 12.518 11.6276 12.9049 11.6102 13.3648C11.5701 14.429 11.5131 15.2205 11.455 15.8033C11.333 17.0284 10.4788 18.0436 9.17337 18.2032C8.56569 18.2775 7.74925 18.3334 6.6666 18.3334C5.58392 18.3334 4.76747 18.2775 4.15979 18.2032C2.85422 18.0436 2.00019 17.0279 1.87818 15.803C1.77074 14.7245 1.6666 12.9263 1.6666 10C1.6666 7.07375 1.77074 5.27559 1.87818 4.19704C2.00019 2.97215 2.85422 1.95647 4.15979 1.79687C4.76747 1.72258 5.58391 1.66669 6.6666 1.66669C7.74925 1.66669 8.56569 1.72258 9.17336 1.79686C10.4788 1.95644 11.333 2.97164 11.455 4.19678C11.5131 4.77955 11.5701 5.57106 11.6102 6.63526C11.6276 7.09517 11.2688 7.48207 10.8089 7.49943Z"
      fill="#333333"
      fillOpacity="0.25"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.994 7.67259C13.6686 7.34715 13.6686 6.81952 13.994 6.49408C14.3194 6.16864 14.8471 6.16864 15.1725 6.49408L18.0892 9.41074C18.4146 9.73618 18.4146 10.2638 18.0892 10.5893L15.1725 13.5059C14.8471 13.8314 14.3194 13.8314 13.994 13.5059C13.6686 13.1805 13.6686 12.6528 13.994 12.3274L15.4881 10.8333L7.49992 10.8333C7.03968 10.8333 6.66659 10.4602 6.66659 10C6.66659 9.53976 7.03968 9.16667 7.49992 9.16667L15.4881 9.16667L13.994 7.67259Z"
      fill="#333333"
      fillOpacity="0.25"
    />
  </svg>
);

export default LogOutIcon;
