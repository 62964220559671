export default {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '18px',
    color: '#B9BED1',
    fontWeight: 600,
  },
  horizontalLine: {
    width: 30,
    marginLeft: 5,
    marginRight: 5,
    border: '1px solid',
  },
  finish: {
    color: '#2489F5',
  },
  selected: {
    color: '#333333',
  },
  arrow: {
    left: '50%',
    width: 0,
    top: -12,
    height: 0,
    display: 'block',
    position: 'absolute',
    transform: 'rotate(45deg)',
    borderBottom: '11px solid #333333',
    borderLeft: '11px solid transparent',
  },
  tab: {
    whiteSpace: 'nowrap',
    position: 'relative',
    display: 'flex',
  },
  popover: {
    maxWidth: 300,
    width: '100%',
    color: '#FFFFFF',
    background: '#333333',
    borderRadius: 5,
    fontSize: 14,
    padding: '2px 5px',
    zIndex: 1,
    textAlign: 'center',
  },
  '@media (max-width: 1000px)': {
    wrapper: {
      fontSize: '14px !important',
    },
  },
}
