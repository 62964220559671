export const styles = {
	mainWrapper: {},
	wrapper: {
		display: 'flex',
		fontSize: '18px',
		color: '#333333',
		flexDirection: 'column',
		maxWidth: '600px',
		flexBasis: '52%',
		flexShrink: '1',
		paddingLeft: 30,
	},
	root: {
		paddingRight: 14,
		alignSelf: 'flex-end',
		'& .MuiPaper-elevation1': {
			boxShadow: 'none',
			'&:hover': {
				'& #circle': {
					opacity: 1,
				},
				'& #title': {
					fontWeight: 600,
				},
			},
		},
		'& .MuiPaper-root': {
			borderBottom: '1px solid #E7EBF1',
			background: '#F8F9FA',
			'&:last-child': {
				border: 'none',
			},
			'&:before': {
				background: 'transparent',
				top: -200000,
			},
			'& .MuiButtonBase-root': {
				padding: 0,
			},
		},
	},
	cursorDefault: {
		'& .MuiPaper-root': {
			'& .MuiButtonBase-root': {
				cursor: 'default',
			},
		},
	},
	headingWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		fontSize: 18,
		color: '#333333',
		position: 'relative',
		WebkitBoxDecorationBreak: 'clone',
		MsBoxDecorationBreak: 'clone',
		OBoxDecorationBreak: 'clone',
		boxDecorationBreak: 'clone',
	},
	'arrow-1': {
		position: 'relative',
		'&:before': {
			content: '""',
			left: -20,
			width: 0,
			top: 5,
			height: 0,
			display: 'block',
			position: 'absolute',
			transform: 'rotate(-45deg)',
			borderBottom: '11px solid #34C759',
			borderLeft: '11px solid transparent',
		},
	},
	'arrow-2': {
		position: 'relative',
		'&:before': {
			content: '""',
			left: -20,
			width: 0,
			top: 5,
			height: 0,
			display: 'block',
			position: 'absolute',
			transform: 'rotate(-45deg)',
			borderBottom: '11px solid #FF9500',
			borderLeft: '11px solid transparent',
		},
	},
	'arrow-3': {
		position: 'relative',
		'&:before': {
			content: '""',
			left: -20,
			width: 0,
			top: 5,
			height: 0,
			display: 'block',
			position: 'absolute',
			transform: 'rotate(-45deg)',
			borderBottom: '11px solid #AF52DE',
			borderLeft: '11px solid transparent',
		},
	},
	'arrow-4': {
		position: 'relative',
		'&:before': {
			content: '""',
			left: -20,
			width: 0,
			top: 5,
			height: 0,
			display: 'block',
			position: 'absolute',
			transform: 'rotate(-45deg)',
			borderBottom: '11px solid #5AC8FA',
			borderLeft: '11px solid transparent',
		},
	},
	disableHeaderMargin: {
		justifyContent: 'flex-start',
	},
	circle: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: 20,
		height: 20,
		fontSize: 12,
		color: '#FFFFFF',
		background: '#EB5757',
		borderRadius: 20,
		marginLeft: 10,
		opacity: 0.7,
		marginTop: 2,
	},
	accordionSummary: {
		'& .MuiAccordionSummary-content': {
			flexDirection: 'column',
		},
	},
	progressWrapper: {
		'& .MuiLinearProgress-root': {
			borderRadius: '5px',
		},
		'& .MuiLinearProgress-bar': {
			background: '#2489F5',
		},
		'& .MuiLinearProgress-colorPrimary': {
			background: '#E7EBF1',
		},
	},
	progressWrapperSuccessful: {
		'& .MuiLinearProgress-bar': {
			background: '#CAD2DB',
		},
	},
	detailsWrapper: {
		paddingLeft: 0,
		paddingRight: 0,
		display: 'flex',
		flexDirection: 'column',
	},
	bookIcon: {
		marginRight: 10,
	},
	blockIcon: {
		marginLeft: 10,
	},
	blogWrapper: {
		fontSize: 18,
		color: '#2489F5',
		fontWeight: 500,
		textDecoration: 'none !important',
		marginBottom: 20,
		display: 'flex',
		alignItems: 'center',
	},
	subTitle: {
		textTransform: 'uppercase',
		fontSize: 18,
		color: '#CAD2DB',
	},
	wrapperHires: {
		'& .MuiPaper-elevation2': {
			boxShadow:
				'0px 0px 4px rgba(117, 131, 142, 0.04), 0px 3px 5px rgba(52, 60, 68, 0.08)',
		},
		'& .MuiPaper-root': {
			margin: '10px 0',

			'& .MuiButtonBase-root': {
				background: '#FFFFFF',
			},
		},
		'& .MuiAccordion-root': {
			'&:before': {
				background: 'transparent',
			},
		},
	},
	hireTitle: {
		marginLeft: 10,
		color: '#333333',
		fontSize: 14,
	},
	trashIcon: {
		margin: '0 10px',
		cursor: 'pointer',
	},
	editIcon: {
		cursor: 'pointer',
	},
	'hire-arrow-1': {
		position: 'relative',
		'&:after': {
			content: '""',
			right: 0,
			width: 0,
			top: 0,
			height: 0,
			display: 'block',
			position: 'absolute',
			transform: 'rotate(270deg)',
			borderBottom: '11px solid #34C759',
			borderLeft: '11px solid transparent',
		},
	},
	'hire-arrow-2': {
		position: 'relative',
		'&:after': {
			content: '""',
			right: 0,
			width: 0,
			top: 0,
			height: 0,
			display: 'block',
			position: 'absolute',
			transform: 'rotate(270deg)',
			borderBottom: '11px solid #FF9500',
			borderLeft: '11px solid transparent',
		},
	},
	'hire-arrow-3': {
		position: 'relative',
		'&:after': {
			content: '""',
			right: 0,
			width: 0,
			top: 0,
			height: 0,
			display: 'block',
			position: 'absolute',
			transform: 'rotate(270deg)',
			borderBottom: '11px solid #AF52DE',
			borderLeft: '11px solid transparent',
		},
	},
	'hire-arrow-4': {
		position: 'relative',
		'&:after': {
			content: '""',
			right: 0,
			width: 0,
			top: 0,
			height: 0,
			display: 'block',
			position: 'absolute',
			transform: 'rotate(270deg)',
			borderBottom: '11px solid #5AC8FA',
			borderLeft: '11px solid transparent',
		},
	},
	'hire-arrow-unuseful': {
		position: 'relative',
		'&:after': {
			content: '""',
			right: 0,
			width: 0,
			top: 0,
			height: 0,
			display: 'block',
			position: 'absolute',
			transform: 'rotate(270deg)',
			borderBottom: '11px solid #CAD2DB',
			borderLeft: '11px solid transparent',
		},
	},
	expandedTitle: {
		fontWeight: 700,
	},
	accordionDetails: {
		padding: 10,
		fontSize: 14,
		display: 'flex',
		flexDirection: 'column',
		background: '#FFFFFF',
	},
	hireDescriptionWrapper: {
		// marginTop: 20
	},
	hireDescriptionTitle: {
		fontWeight: 700,
	},
	hireDescriptionContent: {
		display: 'flex',
		margin: '10px 0 10px 10px',
	},
	hireDescriptionIcon: {},
	hireDescriptionText: {
		marginLeft: 10,
	},
	hireSubTitle: {
		marginBottom: 20,
		fontSize: 16,
	},
	activeCircle: { opacity: 1 },
	activeItem: {
		fontWeight: 700,
	},
	iconWrapper: {
		display: 'flex',
		marginLeft: 10,
		'& svg:hover': {
			'& path': {
				fill: '#333333',
			},
		},
		'& svg:active': {
			'& path': {
				fill: '#333333',
			},
		},
	},
	scroll: {
		height: 'calc(100vh - 311px)',
		overflowY: 'scroll',
		overflowX: 'clip',
	},
	scrollWrapperIsLogin: {
		paddingLeft: 15,
	},
	scrollWrapperIsNotLogin: {
		paddingLeft: 43,
	},
	usefulHireTitle: {
		color: '#80869D',
	},
	usefulHireDetails: {
		background: '#f5f5f5',
	},
	topActiveError: {
		borderLeft: '2px solid #2489F5',
		borderTop: '2px solid #2489F5',
		borderRight: '2px solid #2489F5',
	},
	bottomActiveError: {
		borderLeft: '2px solid #2489F5',
		borderBottom: '2px solid #2489F5',
		borderRight: '2px solid #2489F5',
	},
	hoveredActiveError: {
		border: '2px solid #2489F5',
	},
	relativeWrap: {
		'& .MuiAccordion-root.Mui-disabled': {
			backgroundColor: 'inherit',
		},
		position: 'relative',
	},
	blockedTab: {
		position: 'absolute',
		top: 5,
		left: -45,
	},
	blockedError: {
		position: 'absolute',
		top: '21%',
		left: -37,
	},
	tabs: {
		borderBottom: '1px solid #e8e8e8',
		'@media (max-width: 1300px)': {
			'& .MuiTab-root': {
				fontSize: '12px',
				padding: '6px 10px'
			}
		},
		'@media (max-width: 900px)': {
			'& .MuiTab-root': {
				padding: '4px 12px',
				minWidth: 0,
			}
		},

	}
}
