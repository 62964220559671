import React, { useContext } from 'react'
import Facebook from 'react-facebook-login'
import FbFilledIcon from '../../Images/fbFilled'
import { hashPassword } from '../../utils'
import APIClient from '../../modules/API/Client'
import { authorization } from '../../modules/auth'
import PopupsContext from '../../modules/contexts/popupsContext'
import AppMainContext from '../../modules/contexts/appMainContext'

const FacebookLogin = ({ handleClickFacebookLogin, setError }) => {
  const { hideAll } = useContext(PopupsContext)
  const { setUserData, resetUserData } = useContext(AppMainContext)

  const responseFacebook = async response => {
    try {
      const loginUser = await APIClient.registerLoginFb({
        mail: response.email.trim(),
        user_id: response.userID,
      })
      if (loginUser.Error) {
        setError(loginUser.Error)
      }

      if (loginUser.Success) {
        const { user_id, subscription, mail, name, surname } = loginUser.data
        setUserData({
          id: user_id,
          name: name,
          surname: surname,
          email: mail,
        })
        authorization.login({
          apiKey: loginUser.token,
          userId: user_id,
          isPaid: subscription,
          isSocial: true,
        })
        hideAll()
      }
    } catch (error) {
      console.log({ error })
      authorization.logout()
      resetUserData()
    }
  }

  const location = window.location.hostname
  const appId = location === 'localhost' ? '733462587630701' : '196505122664246'

  return (
    <div id="facebookLoginId">
      <Facebook
        appId={appId}
        fields="email"
        autoLoad={false}
        scope="email"
        callback={responseFacebook}
        onClick={handleClickFacebookLogin}
        textButton="Log in with Facebook"
        size="small"
        icon={<FbFilledIcon style={{ marginRight: 10 }} />}
      />
    </div>
  )
}

export default FacebookLogin
