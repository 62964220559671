import React from "react";
import injectStyles from "react-jss";

import styles from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const CircleProgress = ({ progress, classes, ...props }) => {
  return (
    <div className={classes.wrapper} {...props}>
      <CircularProgress size={20} />
    </div>
  );
};

export default injectStyles(styles)(CircleProgress);
