import React from "react";
import injectStyles from "react-jss";
import classNames from "classnames";
import styles from "../styles";

const ButtonTab = ({
  classes,
  title,
  onClick,
  isActive,
  disabled,
  className
}) => {
  return (
    <div
      onClick={!disabled ? onClick : () => {}}
      className={classNames(classes.wrapper, className, {
        [classes.isActive]: isActive,
        [classes.disabled]: disabled
      })}
    >
      <div>{title}</div>
    </div>
  );
};

export default injectStyles(styles)(ButtonTab);
