import React from "react";

const UnBlockIcon = props => (
  <svg
    {...props}
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_57:2739)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.61117 13.7288C3.77137 11.5124 5.51376 9.79156 7.7315 9.65059C9.05778 9.56629 10.6795 9.5 12.5 9.5C14.3205 9.5 15.9422 9.56629 17.2685 9.65059C19.4862 9.79156 21.2286 11.5124 21.3888 13.7288C21.4535 14.6234 21.5 15.585 21.5 16.5C21.5 17.415 21.4535 18.3766 21.3888 19.2712C21.2286 21.4876 19.4862 23.2084 17.2685 23.3494C15.9422 23.4337 14.3205 23.5 12.5 23.5C10.6795 23.5 9.05778 23.4337 7.73151 23.3494C5.51376 23.2084 3.77137 21.4876 3.61118 19.2712C3.54652 18.3766 3.5 17.415 3.5 16.5C3.5 15.585 3.54652 14.6234 3.61117 13.7288ZM14.5 15.5C14.5 16.2403 14.0978 16.8866 13.5 17.2324V18.5C13.5 19.0523 13.0523 19.5 12.5 19.5C11.9477 19.5 11.5 19.0523 11.5 18.5V17.2324C10.9022 16.8866 10.5 16.2403 10.5 15.5C10.5 14.3954 11.3954 13.5 12.5 13.5C13.6046 13.5 14.5 14.3954 14.5 15.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 5.5C7.5 2.73858 9.73858 0.5 12.5 0.5C15.2614 0.5 17.5 2.73858 17.5 5.5V9.5C17.5 10.0523 17.0523 10.5 16.5 10.5C15.9477 10.5 15.5 10.0523 15.5 9.5V5.5C15.5 3.84315 14.1569 2.5 12.5 2.5C10.8431 2.5 9.5 3.84315 9.5 5.5V7C9.5 7.55229 9.05228 7.5 8.5 7.5C7.94772 7.5 7.5 7.55229 7.5 7V5.5Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_57:2739">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.5 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default UnBlockIcon;
