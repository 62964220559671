import React from "react";

const PayPalIcon = props => (
  <svg
    {...props}
    width="22"
    height="26"
    viewBox="0 0 22 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.28222 6.4314H16.1644C19.8606 6.4314 21.2503 8.30207 21.0351 11.0549C20.6817 15.5935 17.9368 18.1019 14.2991 18.1019H12.4629C11.9634 18.1019 11.6285 18.4314 11.493 19.3269L10.7038 24.5297C10.6533 24.8672 10.4753 25.0665 10.2069 25.0904H5.89161C5.48506 25.0904 5.34157 24.7795 5.44786 24.1072L8.08382 7.41987C8.18745 6.75025 8.55415 6.4314 9.28222 6.4314Z"
      fill="#C5C3C3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.97725 0.689209H11.8674C13.8072 0.689209 16.1083 0.752976 17.6495 2.11081C18.6779 3.01693 19.2173 4.46245 19.0924 6.01427C18.6699 11.2756 15.5238 14.2224 11.3041 14.2224H7.90551C7.32624 14.2224 6.9436 14.6051 6.78151 15.644L5.83288 21.6759C5.77176 22.0665 5.6017 22.2977 5.29081 22.3269H1.04723C0.5769 22.3269 0.409496 21.9655 0.531728 21.187L3.58753 1.83711C3.71242 1.05855 4.13492 0.689209 4.97725 0.689209Z"
      fill="#DADADA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.87891 15.0355L8.08263 7.4199C8.18891 6.75028 8.55295 6.43408 9.28103 6.43408H16.1632C17.3032 6.43408 18.2226 6.61211 18.9453 6.93894C18.2545 11.621 15.2252 14.2224 11.2607 14.2224H7.86208C7.41301 14.2224 7.0782 14.4509 6.87891 15.0355Z"
      fill="#DADADA"
    />
  </svg>
);

export default PayPalIcon;
