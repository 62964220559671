import React, { Fragment, memo } from 'react'
import classNames from 'classnames'
import injectStyles from 'react-jss'

import SuccessfulTabs from './SuccessfulTabs'
import UploadTabs from './UploadTabs'
import { styles } from '../styles'
import { STATE_TABS } from '../../../constants'
import UnlockBlock from '../../UnlockBlock/UnlockBlock'
import { authorization } from '../../../modules/auth'

const AccordionTabs = ({
  classes,
  isDataLoaded,
  progress,
  tabs,
  activeTab,
  onTabClick,
  onHoverTab,
  workOnNext,
  buttons,
  stateTab,
  errors,
  isPaid,
  errorIfUserNotRegister,
  style = {},
  score,
}) => {
  const handleChange = panel => (event, isExpanded) => {
    onTabClick(isExpanded ? panel : false)
  }

  return (
    <Fragment>
      {progress ? (
        <div className={classes.wrapper} style={style}>
          {buttons}
          <div
            id="scroll"
            style={!isPaid ? { height: 'calc(100vh - 515px)' } : {}}
            className={classNames(classes.scroll, {
              [classes.scrollWrapperIsNotLogin]: !isPaid,
              [classes.scrollWrapperIsLogin]: isPaid,
            })}
          >
            <div
              className={classNames(classes.root, {
                [classes.cursorDefault]: stateTab === STATE_TABS.UPLOAD_MODE,
              })}
            >
              {stateTab === STATE_TABS.UPLOAD_MODE ? (
                <UploadTabs progress={progress} classes={classes} />
              ) : isDataLoaded ? (
                <SuccessfulTabs
                  tabs={tabs}
                  activeTab={activeTab}
                  workOnNext={workOnNext}
                  handleChange={handleChange}
                  onHoverTab={onHoverTab}
                  stateTab={STATE_TABS.SUCCESSFUL_MODE}
                  classes={classes}
                  errors={errors}
                  isPaid={isPaid}
                  errorIfUserNotRegister={errorIfUserNotRegister}
                  score={score}
                />
              ) : null}
            </div>
          </div>
          {!isPaid &&
          authorization.isLogged() &&
          stateTab === STATE_TABS.SUCCESSFUL_MODE ? (
            <UnlockBlock />
          ) : null}
        </div>
      ) : null}
    </Fragment>
  )
}

export default memo(injectStyles(styles)(AccordionTabs))
