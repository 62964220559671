export default {
  wrapper: {},
  bigButton: {
    minHeight: 28,
    marginBottom: 5,
    border: "1px solid gainsboro",
    color: "#333333",
    background: "#F2F5F9",
    "&:hover": {
      background: "#CAD2DB"
    },
    "&:active": {
      background: "#80869D",
      borderColor: "#80869D",
      outline: "none"
    }
  },
  wrapperButton: {
    display: "flex",
    marginBottom: 10,
    minHeight: 28,
    justifyContent: "space-between"
  }
};
