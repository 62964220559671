import React, { useEffect, useState, Fragment } from 'react'
import injectStyles from 'react-jss'
import { styles } from './styles'
import PlusIcon from '../../Images/plus'
import StickyHeadTable from '../../LendosComponents/Table'
import APIClient from '../../modules/API/Client'
import Loader from '../../components/Loader/Loader'
import { downloadEssay } from '../../utils'

const sliceText = str => `${str.substring(0, 113)}...`

const columns = [
  { id: 'Date', label: 'Date' },
  { id: 'Time', label: 'Time' },
  {
    id: 'score',
    label: 'Overall Score',
    align: 'right',
  },
]

const MyEssayTab = ({ classes, onBack, onClickSingleEssay, onClickNewEssay, loading }) => {
  const [data, setData] = useState({ items: [], texts: [], email: 'Loading' })
  const [isLoading, setIsLoading] = useState(loading)
  useEffect(() => {
    getData()
  }, [])
  const getData = async () => {
    setIsLoading(true)
    const res = await APIClient.getMyEssay()
    setData(res)
    setIsLoading(false)
  }

  return (
    <div className={classes.content}>
      {isLoading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className={classes.wrapperCard}>
            <div className={classes.card}>
              <div onClick={() => {
                onBack()
                onClickNewEssay()
              }} className={classes.emptyCard}>
                <PlusIcon />
                <div className={classes.cardTitle}>New essay</div>
              </div>
            </div>
            {data.texts.map(item => (
              <div
                key={item.id}
                onClick={() => {
                  onBack()
                  onClickSingleEssay(item.hashedName)
                }}
                className={classes.card}
              >
                {sliceText(item.text)}
              </div>
            ))}
          </div>
          <StickyHeadTable
            columns={columns}
            rows={data.items}
            onClick={downloadEssay}
          />
        </Fragment>
      )}
    </div>
  )
}

export default injectStyles(styles)(MyEssayTab)
