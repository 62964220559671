export default {
  basic: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    outline: "none !important",
    transition: "all .15s ease",
    transitionProperty: "color, background-color, border-color",
    borderRadius: "5px",
    backgroundColor: "#E6EEF5",
    color: "#80869D",
    border: "1px solid transparent",
    height: 40,
    width: 120,
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#333333",
      borderColor: "#333333",
      "& svg": {
        "& path": {
          stroke: "#FFFFFF"
        }
      }
    },
    "&:active": {
      color: "#FFFFFF",
      backgroundColor: "#575757",
      borderColor: "#575757",
      outline: "none",
      "& svg": {
        "& path": {
          stroke: "#FFFFFF"
        }
      }
    }
  },
  icon: {
    marginRight: 5
  }
};
