import React from 'react'
import { useNavigate } from 'react-router-dom'

import injectStyles from 'react-jss'

import styles from './styles'
import { PATHS } from '../../routing/paths'
import Button from '../../LendosComponents/Button'

const SmallDevice = ({ classes, children }) => {
  const navigate = useNavigate()

  return (
    <div className={classes.wrapper}>
      {children}
      <div className={classes.text}>
        We are sorry but CHCKR works on desktop only, as we believe that a large
        screen is most conducive to good writing
      </div>
      <div className={classes.button}>
        <Button
          onClick={() => navigate(PATHS.home)}
          color="blue"
          filled={true}
          label="Back on site"
        />
      </div>
    </div>
  )
}

export default injectStyles(styles)(SmallDevice)
