import React, { cloneElement } from 'react'
import classNames from 'classnames'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { STATE_TABS } from '../../../constants'
import BlockIcon from '../../../assets/icons/block'

const TabCard = ({
  activeTab,
  workOnNext,
  handleChange,
  onHoverTab,
  stateTab,
  classes,
  errors,
  tab,
  indexTab,
  isPaid,
  errorIfUserNotRegister,
  score,
}) => {
  return (
    <div className={classes.relativeWrap}>
      {!isPaid && tab.id !== errorIfUserNotRegister.type ? (
        <div className={classes.blockedTab}>
          <BlockIcon />
        </div>
      ) : null}
      <Accordion
        key={tab.id}
        expanded={activeTab === tab.id}
        onChange={tab.count ? handleChange(tab.id) : () => {}}
        disabled={
          (!isPaid && tab.id !== errorIfUserNotRegister.type) ||
          (workOnNext && workOnNext !== tab.id) ||
          tab.count === 0
        }
        onMouseEnter={() => onHoverTab(tab.id)}
        onMouseLeave={() => onHoverTab(null)}
      >
        <AccordionSummary
          // expandIcon={<ExpandMoreIcon />}
          aria-controls={tab.id}
          id={tab.id}
          className={classes.accordionSummary}
        >
          <div
            className={classNames(
              classes.headingWrapper,
              classes[`arrow-${indexTab + 1}`],
              {
                [classes.disableHeaderMargin]:
                  stateTab === STATE_TABS.SUCCESSFUL_MODE,
              },
            )}
          >
            <Typography
              className={classNames(classes.heading, {
                [classes.activeItem]: activeTab === tab.id,
              })}
              id={workOnNext || tab.count === 0 ? 'eltit' : 'title'}
            >
              {tab.label}
            </Typography>
            <div
              className={classNames(classes.circle, {
                [classes.activeCircle]: activeTab === tab.id,
              })}
              id={workOnNext || tab.count === 0 ? 'elcric' : 'circle'}
            >
              {tab.count}
            </div>
          </div>
          <div
            className={classNames(classes.progressWrapper, {
              [classes.progressWrapperSuccessful]:
                stateTab === STATE_TABS.SUCCESSFUL_MODE,
            })}
          >
            <LinearProgress
              variant="determinate"
              value={
                stateTab === STATE_TABS.SUCCESSFUL_MODE
                  ? score[tab.id]
                  : tab.progress
              }
            />
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.detailsWrapper}>
          {/*<a href="" target="_blank" className={classes.blogWrapper}>*/}
          {/*  <BookIcon className={classes.bookIcon} />*/}
          {/*  Check out a lesson on Structure*/}
          {/*  {!isPaid && indexTab !== 0 ? (*/}
          {/*    <BlockIcon className={classes.blockIcon} />*/}
          {/*  ) : null}*/}
          {/*</a>*/}
          <div className={classes.subTitle}>Suggestions on how to improve</div>
          <div className={classes.wrapperHires}>
            {cloneElement(errors, { indexTab })}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
export default TabCard
