import React, {useEffect, useRef} from "react";
import injectStyles from "react-jss";
import classnames from "classnames";
import styles from "../styles";

import ArrowColored from "../../../assets/icons/arrowColored";

const HighlightedText = ({
                             isActive,
                             onClick,
                             color,
                             children,
                             classes,
                             level,
                             activeCategory,
                             isUnuseful,
                             isHoveredActiveError,
                             disabled,
                             generalColor,
                             replacements,
                             token_ind,
                             onOpenPopover,
                             errorId,
                         }) => {
    const ref = useRef(null);
    useEffect(() => {
        if (((activeCategory && isHoveredActiveError) || isActive) && ref.current && level !== 'word') {
            try {
                ref.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "center"
                });
            } catch (error) {
                console.error(error);
            }
        }
    });


    const getPadding = level => {
        switch (level) {
            case "paragraph": {
                return {padding: "17px 0px"};
            }
            case "sentence": {
                return {padding: "3px 0px"};
            }
            case "word": {
                return {padding: "2px 0"};
            }
            case "active": {
                return {padding: "20px 0px"};
            }
            default: {
                return {padding: 0};
            }
        }
    };

    const getStyle = () => {

        if (activeCategory || isHoveredActiveError) {
            if (isUnuseful) {
                return {
                    borderBottom: `2px solid #CAD2DB`,
                    paddingBottom: 0,
                    background: isActive || isHoveredActiveError ? "#eff1f4" : "initial"
                };
            } else {
                return {
                    borderBottom: `2px solid ${generalColor}`,
                    paddingBottom: 0,
                    background: isActive || isHoveredActiveError ? color : "initial"
                };
            }
        } else {
            return {background: color, ...getPadding(level)};
        }
    };

    const resolveSpanChildren = (children) => {

        const isReplacementsExits = !!replacements && replacements.length
        if (level === "word" && isReplacementsExits) {

            const isSpaceExist = children.endsWith(' ')
            return (
                <>
                    {isSpaceExist ? children : children + ' ' }<ArrowColored/>{isSpaceExist ? ' ' : ''}
                </>
            )
        }
        return children
    }
    const style =
        disabled && !(isActive || isHoveredActiveError) ? {} : getStyle();

    return (
        <span
            data-error-id={isActive && errorId}
            onMouseEnter={(e) => {
                if (activeCategory && ref.current.dataset.errorId.includes('3')) {
                    if (level === "word" && !!onOpenPopover && replacements.length) {
                        onOpenPopover(e, replacements, ref, token_ind)

                    }
                    // onHoverCategory(errorId);
                }
            }}
            onMouseLeave={() => {
                if (activeCategory) {
                    // onHoverCategory(null);
                }
            }}
            ref={ref}
            className={classnames(classes.highlightedText, classes[level])}
            style={style}
            onClick={!disabled ? (e) => {
                onClick(e)
            } : () => {
            }}
        >
      {resolveSpanChildren(children)}
    </span>
    );
};

export default injectStyles(styles)(HighlightedText);
