import React, { Fragment } from 'react'
import classNames from 'classnames'

import { options } from './options'

const SuccessfulThermometer = ({
  classes,
  handleHover,
  hoveredTab,
  tabs,
  isEssay,
}) => {
  const neededArrowTab = isEssay ? tabs.find(item => item.count) : false
  return options.map((item, indexBlock) => {
    return (
      <Fragment key={item.id}>
        <div
          className={classNames(classes.tab, {
            [classes.selected]: hoveredTab === item.id,
          })}
        >
          <div
            onMouseEnter={event => handleHover(event)}
            onMouseLeave={() => handleHover(null)}
            className={classNames(classes.tab, {
              [classes.arrow]: neededArrowTab
                ? neededArrowTab.id === item.id
                : false,
            })}
          />
          {item.label}
        </div>
        {indexBlock < 3 && (
          <div className={classNames(classes.horizontalLine)} />
        )}
      </Fragment>
    )
  })
}

export default SuccessfulThermometer
