import React, { useState } from "react";

import FeedbackRequest from "../steps/FeedbackRequest";
import PositiveFeedback from "../steps/PositiveFeedback";
import NegativeFeedback from "../steps/NegativeFeedback";
import ThanksScreen from "../steps/ThanksScreen";

const FeedbackForm = ({ onSubmitFeedback, cardId, title }) => {
  const [step, setStep] = useState(0);
  const onSubmit = async ({ type, reason }) => {
    try {
      onSubmitFeedback({
        type,
        comment: reason,
        helpful: step === 1,
        card_id: cardId,
        title
      });
    } catch (error) {
      console.error({ error });
    } finally {
      setStep(3);
    }
  };
  if (step === 0) return <FeedbackRequest setStep={setStep} />;
  if (step === 1) return <PositiveFeedback onSubmit={onSubmit} />;
  if (step === 2) return <NegativeFeedback onSubmit={onSubmit} />;
  if (step === 3) return <ThanksScreen />;
};

export default FeedbackForm;
