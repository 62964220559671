import React from "react";

const DisLikeIcon = props => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.86299 13.5582C5.93907 13.3577 6.01741 13.1512 6.04521 13.076L6.07302 13.0003H3C1.1304 13.0003 0 11.6828 0 10.0003C0 4.05672 1.24486 0.000293845 4 0.000293845C4 0.000293845 10.9769 0.000518914 11.0011 6.50086e-06C11.6592 -0.0117955 12.4946 0.0807414 13.3369 0.382114C13.705 0.513807 14.0436 0.677662 14.3471 0.873996C14.7072 0.346533 15.3131 0.000293732 16 0.000293732H18C19.1046 0.000293732 20 0.895724 20 2.00029V11.0003C20 12.1049 19.1046 13.0003 18 13.0003H16C15.8016 13.0003 15.61 12.9714 15.4291 12.9176C14.7322 13.9324 12.9694 15.9468 11.553 17.5653C10.785 18.4429 10.1189 19.2041 9.77973 19.6264L9.4795 20.0003H8.9923C8.3852 20.0004 7.85772 19.9543 7.25172 19.7778C5.90813 19.3866 5 18.4723 5 17.0003C5 16.4802 5.0921 15.9257 5.26178 15.3102C5.37404 14.9029 5.51278 14.4917 5.70292 13.9814C5.73011 13.9084 5.79566 13.7356 5.86299 13.5582ZM14 11.0003V4.00029C14 3.09806 13.548 2.58182 12.6631 2.26521C12.0894 2.05993 11.4746 1.99184 11.0344 1.9997L5.46615 2.00028L4 2.00029C2.98487 2.00029 2 5.20955 2 10.0003C2 10.6404 2.30878 11.0003 3 11.0003H7C8.19454 11.0003 8.44446 11.9239 8.19535 12.924C8.13675 13.1592 8.05108 13.4181 7.92104 13.7697C7.88985 13.8541 7.80559 14.0762 7.72697 14.2834L7.72685 14.2837L7.72673 14.284L7.72673 14.284C7.66215 14.4542 7.60142 14.6143 7.57708 14.6796C7.40559 15.1399 7.28385 15.5007 7.18985 15.8417C7.06407 16.298 7 16.6837 7 17.0003C7 17.4513 7.23782 17.6907 7.81087 17.8576C8.05163 17.9277 8.28861 17.9663 8.54004 17.9852C9.06622 17.3562 9.81976 16.4965 10.5953 15.6116C12.0981 13.8972 13.6835 12.0883 13.8583 11.6803C13.9556 11.4532 14 11.2405 14 11.0003ZM18 11.0003V2.00029H16V11.0003H18Z"
      fill="#CAD2DB"
    />
  </svg>
);

export default DisLikeIcon;
