import React from "react";

const Bg6Icon = props => (
  <svg
    {...props}
    width="508"
    height="525"
    viewBox="0 0 508 525"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M146.446 61.8666C186.807 43.0019 219.169 7.02675 263.459 2.13169C309.656 -2.97412 357.763 8.85869 396.767 34.2007C435.046 59.0718 458.389 100.336 478.04 141.552C496.687 180.661 510.389 222.727 507.249 265.925C504.21 307.741 488.386 348.978 460.678 380.405C434.962 409.574 390.714 411.013 359.225 433.821C322.631 460.328 306.837 525.577 261.649 524.57C216.138 523.556 207.104 450.541 166.694 429.53C122.983 406.801 57.7202 437.32 24.2607 401.138C-7.48675 366.808 15.2181 310.416 13.3442 263.703C11.3802 214.744 -14.0045 160.095 12.9516 119.218C40.0823 78.0772 101.811 82.729 146.446 61.8666Z"
      fill="white"
      fillOpacity="0.13"
    />
  </svg>
);

export default Bg6Icon;
