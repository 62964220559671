import React from "react";

const SharedIcon = props => (
  <svg
    {...props}
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.43758 8.37483C2.98424 8.3732 3.51022 8.16567 3.91071 7.79358L7.82321 10.0292C7.69212 10.5415 7.74991 11.0838 7.986 11.5569C8.22208 12.03 8.62068 12.4023 9.10881 12.6056C9.59694 12.8088 10.1419 12.8295 10.6441 12.6638C11.1462 12.498 11.5718 12.157 11.843 11.7031C12.1143 11.2492 12.213 10.7128 12.121 10.1921C12.0291 9.67141 11.7527 9.20123 11.3424 8.86765C10.9322 8.53408 10.4155 8.35943 9.88696 8.37566C9.35845 8.3919 8.85347 8.59795 8.46446 8.95608L4.55196 6.72045C4.59321 6.5642 4.61571 6.40233 4.61946 6.24045L8.46321 4.04358C8.83332 4.38031 9.30798 4.57952 9.80755 4.60777C10.3071 4.63603 10.8012 4.49162 11.2069 4.19877C11.6127 3.90592 11.9053 3.48245 12.0358 2.9994C12.1664 2.51635 12.1268 2.00311 11.9237 1.54578C11.7207 1.08846 11.3666 0.714871 10.9207 0.487698C10.4749 0.260524 9.9645 0.193586 9.47517 0.298114C8.98584 0.402641 8.54734 0.672274 8.23324 1.06178C7.91914 1.45128 7.74855 1.93696 7.75008 2.43733C7.75258 2.61733 7.77696 2.7967 7.82321 2.97045L4.27071 4.99983C4.06469 4.68111 3.77931 4.42148 3.44259 4.24643C3.10588 4.07139 2.72942 3.98695 2.35019 4.00142C1.97097 4.01589 1.60204 4.12876 1.27964 4.32896C0.957239 4.52915 0.692467 4.80978 0.511337 5.14326C0.330206 5.47674 0.238949 5.85161 0.24653 6.23103C0.254112 6.61045 0.360272 6.98138 0.55458 7.30736C0.748888 7.63334 1.02466 7.90316 1.3548 8.09032C1.68494 8.27747 2.05809 8.37552 2.43758 8.37483Z"
      fill="#2489F5"
    />
  </svg>
);

export default SharedIcon;
