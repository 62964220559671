import React from "react";
import injectStyles from "react-jss";

import styles from "../styles";

const ThanksScreen = ({ classes }) => {
  return (
    <div className={classes.feedbackForm}>
      <span className={classes.feedbackFormTitle}>
        Thank you! Your feedback helps us improve CHCKR for everyone!
      </span>
    </div>
  );
};

export default injectStyles(styles)(ThanksScreen);
