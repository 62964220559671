import React from 'react'
import injectStyles from 'react-jss'
import { styles } from './styles'
import MainScreenIcon from '../../Images/mainScreenIcon'
import Bg1Icon from '../../Images/bg-1'
import Button from '../Button'
import { useNavigate } from 'react-router-dom'
import { PATHS } from '../../routing/paths'

const BlockWithTitle = ({ classes }) => {
  const navigate = useNavigate()
  return (
    <div className={classes.wrapper}>
      <div className={classes.textBlock}>
        <div className={classes.title}>
          AI application essay coach providing instant essay score, feedback,
          and recommendations on how to improve
        </div>
        <div className={classes.subTitle}>
          Checks essay structure, flow, syntax, and grammar in seconds.
          Generates tailored feedback, recommendations, and illustrative
          examples. Built to level the playing field and empower every applicant
          to tell a structured, insightful, and memorable personal story to
          admissions
        </div>
        <div className={classes.buttonDesk}>
          <Button
            onClick={() => navigate(PATHS.checking)}
            color="purple"
            label="Try now"
          />
        </div>
      </div>
      <div className={classes.iconWrapper}>
        <Bg1Icon className={classes.bgIcon} />
        <MainScreenIcon className={classes.icon} />
      </div>
      <div className={classes.buttonMob}>
        <Button
          onClick={() => navigate(PATHS.checking)}
          color="purple"
          label="Try now"
        />
      </div>
    </div>
  )
}

export default injectStyles(styles)(BlockWithTitle)
