const options = [
  {
    id: "structure",
    label: "1. Structure"
  },
  {
    id: "flow",
    label: "2. Flow"
  },
  {
    id: "syntax",
    label: "3. Syntax"
  },
  {
    id: "grammar",
    label: "4. Grammar"
  }
];

export { options };
