import React from "react";

const Bg7Icon = props => (
  <svg
    {...props}
    width="431"
    height="461"
    viewBox="0 0 431 461"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.0457 213.05C32.4073 175.431 20.8199 135.965 37.0417 101.737C53.9619 66.0354 83.9891 36.5106 119.728 19.5998C154.803 3.00326 195.074 4.52775 233.661 8.70174C270.278 12.6625 306.826 21.5195 336.678 43.0671C365.575 63.9253 388.349 93.7749 398.723 127.845C408.351 159.466 389.647 192.121 392.098 225.081C394.946 263.384 435.106 303.926 414.213 336.17C393.171 368.644 336.315 342.599 303.081 362.459C267.133 383.943 260.091 444.777 218.984 452.84C179.98 460.49 149.312 418.9 114.68 399.412C78.382 378.986 27.5163 372.966 9.97107 335.223C-7.68791 297.236 23.2223 254.652 28.0457 213.05Z"
      fill="white"
      fillOpacity="0.15"
    />
  </svg>
);

export default Bg7Icon;
