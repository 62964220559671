import React, { useContext, useState } from 'react'

import injectStyles from 'react-jss'
import { styles } from './styles'
import Button from '../../LendosComponents/Button'
import CustomSwitch from '../../LendosComponents/Switch'
import Input from '../../LendosComponents/InputModal/Input'
import AppMainContext from '../../modules/contexts/appMainContext'
import APIClient from '../../modules/API/Client'
import { hashPassword } from '../../utils'
import { authorization } from '../../modules/auth'

const ProfileTab = ({ classes }) => {
  const { getUserData } = useContext(AppMainContext)

  const isSocialAuthorization = authorization.getAuthorizationMethodIsSocial()

  const user = getUserData()

  const [isEditMode, setIsEditMode] = useState(false)
  const [isError, setIsError] = useState(false)
  const [values, setValues] = useState({
    firstName: user.name,
    lastName: user.surname,
    email: user.email,
    password: '',
  })

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const onSubmit = async values => {
    const hashedPassword = values.password
      ? hashPassword(values.password.trim())
      : null

    const val = {
      mail: values.email,
      new_pass: hashedPassword,
      name: values.firstName,
      surname: values.lastName,
    }

    Object.keys(val).forEach(key => {
      if (!val[key]) {
        delete val[key]
      }
    })

    const result = await APIClient.setUserData(val)
    if (result.Success) {
      setIsEditMode(false)
    } else {
      setIsEditMode(true)
    }
  }
  const callbackError = error => {
    setIsError(error)
  }

  return (
    <div className={classes.content}>
      <div className={classes.subWrapper}>
        <div>
          <Input
            placeholder="Name"
            label="First Name"
            isRequired={false}
            required={false}
            value={values.firstName}
            handleChange={handleChange}
            type="firstName"
            callbackError={callbackError}
            disabled={!isEditMode}
          />
          <Input
            placeholder="Surname"
            label="Last Name"
            isRequired={false}
            required={false}
            value={values.lastName}
            handleChange={handleChange}
            type="lastName"
            callbackError={callbackError}
            disabled={!isEditMode}
          />
          {!isSocialAuthorization ? (
            <Input
              placeholder="mail@website.com"
              label="Email"
              isRequired={false}
              required={false}
              value={values.email}
              handleChange={handleChange}
              type="email"
              callbackError={callbackError}
              disabled={!isEditMode}
            />
          ) : null}
          <Input
            placeholder="pass"
            label="Password"
            isRequired={false}
            required={false}
            value={values.password}
            handleChange={handleChange}
            type="password"
            callbackError={callbackError}
            disabled={!isEditMode}
          />

          {/*<Input*/}
          {/*  handleChange={handleChange}*/}
          {/*  type="firstName"*/}
          {/*  value={values.firstName}*/}
          {/*  label="First Name"*/}
          {/*  disabled={!isEditMode}*/}
          {/*  required={false}*/}
          {/*/>*/}
          {/*<Input*/}
          {/*  handleChange={handleChange}*/}
          {/*  type="lastName"*/}
          {/*  value={values.lastName}*/}
          {/*  label="Last Name"*/}
          {/*  disabled={!isEditMode}*/}
          {/*  required={false}*/}
          {/*/>*/}
          {/*<Input*/}
          {/*  handleChange={handleChange}*/}
          {/*  type="email"*/}
          {/*  value={values.email}*/}
          {/*  label="Email"*/}
          {/*  disabled={!isEditMode}*/}
          {/*  required={false}*/}
          {/*/>*/}
          {/*<Input*/}
          {/*  handleChange={handleChange}*/}
          {/*  type="password"*/}
          {/*  value={values.password}*/}
          {/*  label="Password"*/}
          {/*  disabled={!isEditMode}*/}
          {/*  required={false}*/}
          {/*/>*/}
          <div className={classes.buttonWrap}>
            {isEditMode ? (
              <Button
                color="blue"
                label="Save"
                onClick={() => {
                  onSubmit(values)
                }}
                disabled={Boolean(isError) || !isEditMode}
              />
            ) : null}
          </div>
        </div>
        <div className={classes.checkBoxWrap}>
          <div className={classes.checkBoxLabel}>Edit mode</div>
          <CustomSwitch checked={isEditMode} setChecked={setIsEditMode} />
        </div>
      </div>
    </div>
  )
}

export default injectStyles(styles)(ProfileTab)
