import React from "react";
import LikeIcon from "../../../assets/icons/like";

import injectStyles from "react-jss";

import styles from "../styles";
import DisLikeIcon from "../../../assets/icons/disLike";

const FeedbackRequest = ({ setStep, classes }) => {
  const onPositive = () => setStep(1);
  const onNegative = () => setStep(2);
  return (
    <div className={classes.feedbackForm}>
      <span className={classes.feedbackFormTitle}>
        Was this recomendation helpful?
      </span>
      <div className={classes.itemsWrapper}>
        <div className={classes.item} onClick={onPositive}>
          <LikeIcon />
          <span>Yes</span>
        </div>
        <div className={classes.item} onClick={onNegative}>
          <DisLikeIcon />
          <span>No</span>
        </div>
      </div>
    </div>
  );
};

export default injectStyles(styles)(FeedbackRequest);
