export default {
  wrapper: {
    color: "#2489F5",
    fontSize: 14,
    whiteSpace: "nowrap",
    cursor: "pointer"
  },
  icon: {
    marginLeft: 5
  }
};
