import bcrypt from 'bcryptjs'
import { useState, useEffect } from 'react'

import APIClient from '../modules/API/Client'

const handleChangeWorkOnNext = (tabsArr, setWorkOnNext) => {
  const tabs = tabsArr.reduce((acc, tab) => {
    if (tab.count !== 0) {
      acc.push(tab.id)
    }

    return acc
  }, [])

  setWorkOnNext(prev => {
    if (!prev) {
      return tabs[0]
    }
    const tabIndex = tabs.findIndex(tab => tab === prev) + 1

    return tabs[tabIndex]
  })
}

const handleChangeUsefulIds = (id, setUsefulHires) => {
  setUsefulHires(prev => {
    if (prev.includes(id)) {
      return prev.filter(item => item !== id)
    } else {
      return [...prev, id]
    }
  })
}
const salt = '$2a$10$320CMa51nynGVyKOU31qKu'

const hashPassword = password => {
  return bcrypt.hashSync(password, salt)
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

const download = url => {
  const link = document.createElement('a')
  link.href = url
  link.click()
}

const downloadEssay = async hashedName => {
  const res = await APIClient.downloadEssay(hashedName)
  if (res.Success) {
    download(res.link)
  }
}

function debounce(f, ms) {
  let isCooldown = false

  return function() {
    if (isCooldown) return

    f.apply(this, arguments)

    isCooldown = true

    setTimeout(() => (isCooldown = false), ms)
  }
}

const dateFormat = value => {
  const [date, time] = value.split('T')
  return `${time} ${date.replace(/-/g, '/')}`
}

export {
  handleChangeWorkOnNext,
  handleChangeUsefulIds,
  hashPassword,
  useWindowDimensions,
  downloadEssay,
  debounce,
  dateFormat,
}
