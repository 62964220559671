import injectStyles from "react-jss"
import styles from "../styles";
import React, { useState, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@material-ui/core';
import {FormControlLabel, FormGroup} from "@material-ui/core"
import {Stack} from '@mui/material'


import CreatableSelect from 'react-select/creatable';
import GenericButton from '../../GenericButton/GenericButton'
import EditorFormView from '../component/EditorFormView'
import customStyles from '../component/EditorFormView'
import { setIsTermsReviewed } from '../../../modules/localStorage/localStorage'
import CopyButton from '../../CopyButton/CopyButton'
// import Shared from '../../Shared/Shared'
import CircleProgress from '../../CircleProgress/CircleProgress'
import TextCount from '../../TextCount/TextCount'
import CheckBox from "../../CheckBox/CheckBox";

/*const options = [
  { value: 'option1', label: '#1. Some students have a background, identity, interest, or talent that is so meaningful they believe their application would be incomplete without it. If this sounds like you, then please share your story.' },
  { value: 'option2', label: '#2. The lessons we take from obstacles we encounter can be fundamental to later success. Recount a time when you faced a challenge, setback, or failure. How did it affect you, and what did you learn from the experience?' },
  { value: 'option3', label: '#3. Reflect on a time when you questioned or challenged a belief or idea. What prompted your thinking? What was the outcome?' },
  { value: 'option4', label: '#4. Reflect on something that someone has done for you that has made you happy or thankful in a surprising way. How has this gratitude affected or motivated you?' },
  { value: 'option5', label: '#5. Discuss an accomplishment, event, or realization that sparked a period of personal growth and a new understanding of yourself or others.' },
  { value: 'option6', label: '#6. Describe a topic, idea, or concept you find so engaging that it makes you lose all track of time. Why does it captivate you? What or who do you turn to when you want to learn more?' }
];*/
const options = [
  { value: 'option1', label: "1) Discuss your background, identity, interest or talent that's meaningful" },
  { value: 'option2', label: '2) Recount obstacles you encountered, impact on you, and your learnings' },
  { value: 'option3', label: '3) Reflect on a time when you questioned or challenged a belief or idea' },
  { value: 'option4', label: '4) Reflect on something that someone has done for you that made you happy' },
  { value: 'option5', label: '5) Discuss an accomplishment, event, or realization that sparked personal growth' },
  { value: 'option6', label: '6) Describe a topic, idea, or concept you find so engaging that you lose track of time' }
];


const EditorForm = ({ value, onChange, onSubmit, loading, isLoaded, classes}) => {
  // const [accepted, setAccepted] = useState(false)
  const [wordsInfo, setWordsInfo] = useState(null)
  const [structure, setStructure] = useState(true)
  const [flow, setFlow] = useState(true)
  const [syntax, setSyntax] = useState(true)
  const [grammar, setGrammar] = useState(true)
  const [plagiarism, setPlagiarism] = useState(false)

  const [selectedOption, setSelectedOption] = React.useState(null);
  const [selectedSchool, setSelectedSchool] = React.useState(null);
  const [selectedUniversity, setSelectedUniversity] = React.useState(null);

  const [modalOpen, setModalOpen] = useState(false);


  // Maks addition for options storage
  const onOptionChange = (option) => {
    setSelectedOption(option);
    localStorage.setItem('selectedOption', JSON.stringify(option));
}

  const onSchoolChange = (school) => {
    setSelectedSchool(school);
    localStorage.setItem('selectedSchool', JSON.stringify(school));
  }

  const onUniversityChange = (option) => {
    setSelectedUniversity(option);
    localStorage.setItem('selectedUniversity', JSON.stringify(option));
  }

  useEffect(() => {
      const savedOption = localStorage.getItem('selectedOption');
      if (savedOption) {
          setSelectedOption(JSON.parse(savedOption));
      }
      const savedSchool = localStorage.getItem('selectedSchool');
      if (savedSchool) {
          setSelectedSchool(JSON.parse(savedSchool));
      }
      const savedUniversity = localStorage.getItem('selectedUniversity');
      if (savedUniversity) {
          setSelectedUniversity(JSON.parse(savedUniversity));
      }
  }, []);
  //
  
  useEffect(() => {
    // setAccepted()
  }, [])

  useEffect(() => {
    if (value) {
      setWordsInfo({
        words: value.split(' ').length,
        characters: value.length,
      })
    }
  }, [value])

  // const toggle = () => {
  //   setAccepted(!accepted)
  // }

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleFormSubmit = () => {
    handleCloseModal();
    onSubmit({
      CommonAppTopic: selectedOption ? selectedOption.value : null,
      CommonAppSchool: selectedSchool ? selectedSchool.value : null,
      CommonAppUniversity: selectedUniversity ? selectedUniversity.value : null,
      flow,
      structure,
      syntax,
      grammar,
      plagiarism,
    });
  };


  const onSubmitHandler = () => {
    handleOpenModal();
  };
  // const isTermsReviewedStatus = isTermsReviewed();

  return (
    <EditorFormView onTextChange={onChange} value={value}>
        {wordsInfo && wordsInfo.words < 100 ? (
            <span style={{ color: 'red' }}>
          Minimum word count is 100
        </span>
        ) : null}
        {!loading ? (
            <FormGroup row>
                <FormControlLabel control={<CheckBox checked={structure} setChecked={setStructure}/>} label="Structure" />
                <FormControlLabel control={<CheckBox checked={flow} setChecked={setFlow}/>} label="Flow" />
                <FormControlLabel control={<CheckBox checked={syntax} setChecked={setSyntax}/>} label="Syntax" />
                <FormControlLabel control={<CheckBox checked={grammar} setChecked={setGrammar}/>} label="Grammar" />
                <FormControlLabel control={<CheckBox checked={plagiarism} setChecked={setPlagiarism}/>} label="Plagiarism" />
            </FormGroup>
        ) : null}
      <Stack direction={'row'} gap={3}  alignSelf={'stretch'}>
          <GenericButton
              label="Review my essay"
              onClick={onSubmitHandler}
              // disabled={(!accepted && !isTermsReviewedStatus) || value.length < 1}
              disabled={loading || !wordsInfo || (wordsInfo && wordsInfo.words < 100) || (!structure && !flow  && !syntax && !grammar && !plagiarism)}
              type="filled"
          />
          {loading ? <CircleProgress /> : null}
          {!loading && isLoaded ? (
              <>
                  <CopyButton onClick={() => navigator.clipboard.writeText(value)} />
                  {wordsInfo ? (
                      <TextCount
                          words={wordsInfo.words}
                          characters={wordsInfo.characters}
                      />
                  ) : null}
              </>
          ) : null}
      </Stack>

      <Dialog open={modalOpen} onClose={handleCloseModal} classes={{ paper: classes.dialogPaper }}>
        <DialogTitle>Help us tailor our feedback even more</DialogTitle>
        <DialogContent>
        <p>Indicate essay topic:</p>
      <div className={classes.selectDropdown}>
        <CreatableSelect
          value={selectedOption}
          onChange={onOptionChange}
          options={options}
          isMulti={false}
          styles={customStyles}
          classNamePrefix="topicSelect"
          isClearable
          placeholder="Select or type..."
        />
      </div>

      <p>Indicate your current school (optional):</p>
      <div className={classes.selectDropdown}>
        <CreatableSelect
          value={selectedSchool}
          onChange={onSchoolChange}
          options={[]}
          isMulti={false}
          styles={customStyles}
          classNamePrefix="schoolSelect"
          isClearable
          placeholder="Select or type..."
        />
      </div>

      <p>Indicate your target school (optional): </p>
      <div className={classes.selectDropdown}>
        <CreatableSelect
          value={selectedUniversity}
          onChange={onUniversityChange}
          options={[]}
          isMulti={false}
          styles={customStyles}
          classNamePrefix="universitySelect"
          isClearable
          placeholder="Select or type..."
        />
      </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <GenericButton label="Review my essay" onClick={handleFormSubmit} type="filled">Submit Essay</GenericButton>
        </DialogActions>
      </Dialog>

    </EditorFormView>
  )
}

export default injectStyles(styles)(EditorForm);