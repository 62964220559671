export default {
  feedbackForm: {
    width: "100%",
    padding: "25px 32px 16px 32px",
    marginTop: "12px",
    display: "flex",
    flexDirection: "column"
  },
  feedbackFormOptions: {
    margin: "17px 0 0 0"
  },
  feedbackFormTitle: {
    fontFamily: "'Roboto',sans-serif",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    color: "#CAD2DB",
    display: "flex",
    justifyContent: "flex-start",
    "& + *": {
      marginTop: 17
    }
  },
  feedbackFormItem: {
    display: "flex",
    alignItems: "center",
    flexShrink: "0",
    marginBottom: "13px"
  },
  radio: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "20px",
    height: "20px",
    margin: "0",
    border: "2px solid #3b3f4b",
    borderRadius: "50%",
    opacity: "1",
    cursor: "pointer"
  },
  radioCircle: {
    display: "flex",
    width: "10px",
    height: "10px",
    backgroundColor: "#3b3f4b",
    borderRadius: "50%",
    opacity: "0"
  },
  radioCircleActive: {
    opacity: "1"
  },
  feedbackFormText: {
    display: "flex",
    justifyContent: "flex-start",
    marginLeft: "13px",
    fontFamily: "'Roboto',sans-serif",
    fontSize: "16px",
    color: "#48494B"
  },
  feedbackFormDescription: {
    width: "100%",
    height: "70px",
    background: "rgba(255, 255, 255, 0.8)",
    border: "1px solid #BABABA",
    borderRadius: "8px",
    padding: "10px 12px 10px 12px",
    marginTop: "8px",
    fontFamily: "'Roboto',sans-serif",
    fontSize: "16px",
    opacity: "0.4",
    resize: "none",
    "& + *": {
      marginTop: 16
    }
  },
  itemsWrapper: {
    display: "flex",
    alignItems: "center"
  },
  item: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#CAD2DB",
    cursor: "pointer",
    "& + *": {
      marginLeft: 32
    },
    "& > * + *": {
      marginLeft: 8
    },
    "&:hover": {
      color: "#333333",
      "& path": {
        fill: "#333333"
      }
    }
  }
};
