import React, { useState, useEffect } from "react";
import injectStyles from "react-jss";
import classNames from "classnames";

import styles from "../styles";
import GenericButton from "../../GenericButton/GenericButton";

const options = {
  1: { value: 1, label: "This information is confusing" },
  2: { value: 2, label: `The solution doesn't work` },
  3: { value: 3, label: "Other" }
};

const NegativeFeedback = ({ onSubmit, classes }) => {
  const [chosenOption, setChosenOption] = useState(null);
  const [usersInput, setUsersInput] = useState("");
  const [optionItems, setOptionItems] = useState([]);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    const optionItems = Object.values(options).map(({ value, label }) => {
      return (
        <div key={value} className={classes.feedbackFormItem}>
          <div onClick={() => setChosenOption(value)} className={classes.radio}>
            <div
              className={classNames(classes.radioCircle, {
                [classes.radioCircleActive]: value === chosenOption
              })}
            />
          </div>
          <span className={classes.feedbackFormText}>{label}</span>
        </div>
      );
    });
    setOptionItems(optionItems);

    if (chosenOption) {
      setDisabled(false);
    }
  }, [chosenOption]);

  const handleSubmit = () => {
    const reason =
      chosenOption === 3 ? usersInput : options[chosenOption].label;
    onSubmit({
      type: "Negative",
      reason,
      isUsersInput: chosenOption === 3,
      reasonId: chosenOption
    });
  };
  return (
    <div className={classes.feedbackForm}>
      <span className={classes.feedbackFormTitle}>
        What went wrong? How could it be better?
      </span>
      <div className={classes.feedbackFormOptions}>{optionItems}</div>
      {chosenOption === 3 && (
        <textarea
          value={usersInput}
          onChange={e => setUsersInput(e.target.value)}
          placeholder="(Optional)"
          className={classes.feedbackFormDescription}
        />
      )}
      <GenericButton
        disabled={disabled}
        style={{
          alignSelf: "flex-end"
        }}
        type="filled"
        label="SUBMIT"
        onClick={handleSubmit}
      />
    </div>
  );
};

export default injectStyles(styles)(NegativeFeedback);
