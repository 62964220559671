import React from "react";

import {Navigate} from "react-router-dom";

import { authorization } from "../modules/auth";

export function PrivateRoute({ children, ...rest }) {
  if (!rest.isRestricted && authorization.isLogged()) {
    return children;
  }

   return  <Navigate to="/" />
}
