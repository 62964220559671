import React from "react";

const FbFilledIcon = props => (
  <svg
    {...props}
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12.5" cy="12.5" r="12.5" fill="url(#paint0_linear_52:747)" />
    <path
      d="M16.9575 16.2571L17.5513 12.7023H13.8366V10.3965C13.8366 9.4237 14.3545 8.47493 16.0184 8.47493H17.7083V5.44853C17.7083 5.44853 16.1753 5.20834 14.7103 5.20834C11.6496 5.20834 9.65089 6.91127 9.65089 9.99293V12.7023H6.25V16.2571H9.65089V24.8511C10.3337 24.9496 11.0322 25 11.7437 25C12.4553 25 13.1538 24.9496 13.8366 24.8511V16.2571H16.9575Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_52:747"
        x1="12.5"
        y1="0"
        x2="12.5"
        y2="24.9259"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18ACFE" />
        <stop offset="1" stopColor="#0163E0" />
      </linearGradient>
    </defs>
  </svg>
);

export default FbFilledIcon;
