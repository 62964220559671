import React from "react";

const ProfileIcon = props => (
  <svg
    {...props}
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="17.5" cy="17.5" r="17.5" fill="#F2F5F9" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.6817 17.7707C23.168 17.1751 22.2857 17.2058 21.5958 17.5834C20.5282 18.1678 19.3029 18.5 18 18.5C16.6971 18.5 15.4718 18.1678 14.4042 17.5834C13.7143 17.2058 12.832 17.1751 12.3183 17.7707C11.1852 19.0845 10.5 20.7956 10.5 22.6667V23.5C10.5 24.4205 11.2462 25.1667 12.1667 25.1667H23.8333C24.7538 25.1667 25.5 24.4205 25.5 23.5V22.6667C25.5 20.7956 24.8148 19.0845 23.6817 17.7707Z"
      fill="#B1CAE6"
    />
    <path
      d="M22.1668 11C22.1668 13.3012 20.3013 15.1666 18.0002 15.1666C15.699 15.1666 13.8335 13.3012 13.8335 11C13.8335 8.69879 15.699 6.83331 18.0002 6.83331C20.3013 6.83331 22.1668 8.69879 22.1668 11Z"
      fill="#B1CAE6"
    />
  </svg>
);

export default ProfileIcon;
