import React from "react";

const VisaIcon = props => (
  <svg
    {...props}
    width="47"
    height="15"
    viewBox="0 0 47 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.777 0.505371H40.8573C39.9746 0.505371 39.2956 0.776974 38.8882 1.65969L33.3203 14.3571H37.2586C37.2586 14.3571 37.9376 12.6596 38.0734 12.2522C38.4808 12.2522 42.3511 12.2522 42.8943 12.2522C43.0301 12.7275 43.3696 14.2892 43.3696 14.2892H46.9005L43.777 0.505371ZM39.1598 9.40038C39.4993 8.58557 40.6536 5.53003 40.6536 5.53003C40.6536 5.59793 40.9931 4.71522 41.1289 4.23992L41.4005 5.46213C41.4005 5.46213 42.1474 8.78927 42.2832 9.46828H39.1598V9.40038Z"
      fill="#DADADA"
    />
    <path
      d="M33.5912 9.8069C33.5912 12.6587 31.0109 14.56 27.0048 14.56C25.3073 14.56 23.6776 14.2205 22.7949 13.813L23.3381 10.6896L23.8134 10.8933C25.0356 11.4365 25.8505 11.6402 27.3443 11.6402C28.4307 11.6402 29.585 11.2328 29.585 10.2822C29.585 9.67109 29.1097 9.26369 27.6159 8.58468C26.19 7.90567 24.2887 6.81926 24.2887 4.85014C24.2887 2.1341 26.9369 0.300781 30.6714 0.300781C32.0973 0.300781 33.3195 0.572385 34.0665 0.911889L33.5233 3.89953L33.2516 3.62792C32.5726 3.35632 31.6899 3.08471 30.3998 3.08471C28.9739 3.15262 28.2949 3.76372 28.2949 4.30693C28.2949 4.91804 29.1097 5.39334 30.3998 6.00445C32.5726 7.02296 33.5912 8.17728 33.5912 9.8069Z"
      fill="#DADADA"
    />
    <path
      d="M0.455078 0.6405L0.522979 0.368896H6.36245C7.17726 0.368896 7.78837 0.6405 7.99207 1.52321L9.28219 7.63428C7.99207 4.37504 5.00443 1.72691 0.455078 0.6405Z"
      fill="#C5C5C5"
    />
    <path
      d="M17.499 0.504913L11.5916 14.2888H7.58547L4.19043 2.74564C6.63486 4.30736 8.67188 6.75179 9.41879 8.44931L9.8262 9.87523L13.4928 0.437012H17.499V0.504913Z"
      fill="#DADADA"
    />
    <path
      d="M19.0611 0.437012H22.7956L20.4191 14.2888H16.6846L19.0611 0.437012Z"
      fill="#DADADA"
    />
  </svg>
);

export default VisaIcon;
