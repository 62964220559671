import React from "react";

const Bg3Icon = props => (
  <svg
    {...props}
    width="392"
    height="396"
    viewBox="0 0 392 396"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M203.246 3.75611C245.896 15.1636 262.385 64.8355 292.316 97.2542C314.813 121.62 341.226 140.571 356.658 169.914C374.841 204.489 401.935 243.152 388.297 279.754C374.65 316.379 327.897 325.801 292.425 342.296C263.855 355.582 233.99 358.88 203.246 365.806C161.066 375.309 117.97 409.292 79.1092 390.353C40.5733 371.573 36.5457 318.829 23.285 278.102C10.0205 237.363 -6.06444 196.506 2.31333 154.492C11.3466 109.191 34.1651 65.8369 71.1433 38.0966C108.438 10.1188 158.191 -8.29487 203.246 3.75611Z"
      fill="white"
      fillOpacity="0.17"
    />
  </svg>
);

export default Bg3Icon;
