const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon1: {},
  bg1: {
    position: 'absolute',
    top: -100,
    left: -30,
  },
  bg2: {
    position: 'absolute',
    top: -20,
    left: 130,
  },
  bg3: {
    position: 'absolute',
    top: -80,
    left: -23,
  },
  bg4: {
    position: 'absolute',
    top: -50,
    left: -11,
  },
  bg5: {
    position: 'absolute',
    top: -80,
    left: -23,
  },
  bg6: {
    position: 'absolute',
    top: -144,
    left: 83,
  },
  img1: {
    maxWidth: 500,
    borderRadius: 5,
    margin: '36px 0 15px 0',
  },
  img3: {
    maxWidth: 400,
    borderRadius: 5,
    margin: '36px 0 15px 0',
  },
}

export { styles }
