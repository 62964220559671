export default {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 18,
    paddingTop: 20,
  },
  red: {
    color: '#EB5757',
    fontWeight: 700,
    textDecoration: 'line-through',
  },
  bold: {
    fontWeight: 700,
  },
  buttonBlock: {
    margin: '10px 0',
  },
  promoBlock: {
    display: 'flex',
  },
  input: {
    flexBasis: '50%',
    minWidth: '250px',
    marginRight: 10,
  },
  sumbitButton: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'all .15s ease',
    transitionProperty: 'color, background-color, border-color',
    borderRadius: '5px',
    whiteSpace: 'nowrap',
    backgroundColor: '#2489F5',
    color: '#FFFFFF',
    border: '1px solid transparent',
    outline: 'none !important',
    padding: 6,
    height: 43,
    flexBasis: '50%',
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: '#2079D9',
      borderColor: '#2079D9',
    },
    '&:active': {
      backgroundColor: '#1A62B0',
      borderColor: '#1A62B0',
      outline: 'none',
    },
  },
  unlockButton: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'all .15s ease',
    transitionProperty: 'color, background-color, border-color',
    borderRadius: '5px',
    whiteSpace: 'nowrap',
    backgroundColor: '#2489F5',
    color: '#FFFFFF',
    border: '1px solid transparent',
    outline: 'none !important',
    padding: 6,
    width: '100%',
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: '#2079D9',
      borderColor: '#2079D9',
    },
    '&:active': {
      backgroundColor: '#1A62B0',
      borderColor: '#1A62B0',
      outline: 'none',
    },
  },
  subtitle: {
    color: '#ABB4BD',
  },
  wrapperIcons: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 145,
  },
  or: {
    width: '100%',
    textAlign: 'center',
    marginTop: '-10px',
  },
}
