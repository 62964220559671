import React from "react";

const Bg5Icon = props => (
  <svg
    {...props}
    width="482"
    height="478"
    viewBox="0 0 482 478"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.0328459 242.159C-0.303884 195.124 57.5937 171.497 86.7544 134.641C110.3 104.883 124.258 68.6693 154.94 46.3725C188.617 21.9 227.485 4.95681 269.001 2.20335C314.605 -0.821203 369.562 -3.68562 400.428 30.0911C431.775 64.3933 403.915 121.384 418.314 165.595C431.97 207.523 479.528 234.707 481.421 278.767C483.453 326.082 460.274 372.301 428.707 407.544C396.085 443.964 352.157 478.858 303.324 477.601C255.241 476.364 225.304 426.337 184.23 401.253C152.115 381.642 116.755 370.877 88.2695 346.273C53.1401 315.931 0.365475 288.62 0.0328459 242.159Z"
      fill="white"
      fillOpacity="0.13"
    />
  </svg>
);

export default Bg5Icon;
